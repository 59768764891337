<div fxLayout="column" fxFlexFill>
<mat-toolbar>
  <span *ngIf="!sharedService.mobile && !sharedService.tablet" fxFlex class="p-l-16 m-y-24" style="color: #005595">
    Oregon Psilocybin Services (OPS) - Training Program, Licensing, and Compliance (TLC) System
  </span>

  <span *ngIf="sharedService.tablet || sharedService.mobile" fxFlex class="p-l-16 m-y-24" style="color: #005595">
    Oregon Psilocybin Services (OPS) - (TLC)
  </span>
</mat-toolbar>
<mat-card>
  <mat-card-title>Required 303 Applicant and Licensee REAL & SOGI Data form</mat-card-title>
  <br>
  <mat-card-title>Under ORS 475A, Permitted Workers and Training Programs do not need to submit this form. Please read and scroll to the bottom to access the form.</mat-card-title>
  <mat-card-content>
    <div fxLayout="column" style="font-size: large" fxLayoutGap="12px">
      <h4>Background</h4>
      <span>
        Senate Bill 303 (303) was passed by Oregon Legislature in 2023 and is now codified in
        <a href="https://www.oregonlegislature.gov/bills_laws/ors/ors475A.html#:~:text=475A.372%20Requirement%20of%20psilocybin%20service%20center%20operator%20to%20collect%2c%20maintain%2c%20aggregate%20and%20submit%20to%20Oregon%20Health%20Authority%20specified%20information" target="_blank" rel="noreferrer noopener" style="word-break: break-all;
            white-space: normal;">ORS 475A.372</a> and
        <a href="https://www.oregonlegislature.gov/bills_laws/ors/ors475A.html#:~:text=475A.374%20Oregon%20Health%20Authority%20collection%2c%20publication%20of%20certain%20data%3b%20rules." target="_blank" rel="noreferrer noopener" style="word-break: break-all;
            white-space: normal;">ORS 475A.374</a>.
             SB 303 requires Oregon Psilocybin Services to collect and report the race, ethnicity, preferred spoken and written languages (REAL), sexual orientation and gender identity (SOGI) of applicants and licensees.
      </span>

      <h4>Data Collected</h4>
      <span>All applicants and licensees are required to complete these questions. The questions for race, ethnicity, and language,
        <a href="https://www.oregon.gov/oha/EI/Pages/Demographics.aspx" target="_blank" rel="noreferrer noopener" style="word-break: break-all;
            white-space: normal;">(REAL)</a> and sexual orientation, gender identify and expression
        <a href="https://www.oregon.gov/oha/EI/Pages/Demographics.aspx" target="_blank" rel="noreferrer noopener" style="word-break: break-all;
        white-space: normal;">(SOGI)</a> were developed through a public engagement process led by the Oregon Health Authority (OHA) Equity and Inclusion Division and are now codified in Oregon law.
      </span>
      <span>
        By sharing REAL & SOGI information, you are contributing to a combined (aggregated) data set that may be used to assess the diversity of our applicants and licensees. This information may also
        be used to assess equity for various licensee populations.
      </span>

      <h4>Data Confidentiality and Use</h4>
      <span>The answers provided will be compiled and securely stored in accordance with <a href="https://www.oregonlegislature.gov/bills_laws/ors/ors475A.html#:~:text=475A.374%20Oregon%20Health%20Authority%20collection%2c%20publication%20of%20certain%20data%3b%20rules." target="_blank" rel="noreferrer noopener" style="word-break: break-all;
            white-space: normal;">ORS 475A.374</a>. REAL & SOGI data will not be used as a basis for any licensing decision.</span>

      <h4>Filling in the Form</h4>
      <span>Please complete this form for any application for a license or any license that has been issued to you under ORS 475A. All applicants and licensees are required to submit a separate form for each
        application or license even if the information is the same. You do not need to submit this form for a worker permit or training program. Under ORS 475A, this form should only be used by facilitator, service center, manufacturer, laboratory licensees and applicants.
      </span>
      <span>If you want to update your REAL & SOGI data, please complete and submit this form again.</span>

      <h4>Enter Your License Id to Continue. The form will populate below after the license id is entered.</h4>
      <span>Your License Id is generated when your application for a license is started in TLC.</span>
      <span>To find your License Id:</span>
      <span>1. Log into TLC</span>
      <span>2. Click on the second menu item on the left, "My Licenses and Permits"</span>
      <span>3. Scroll to find the license in question</span>
      <span>4. See the "License Id"</span>
      <br/>
      <span>If you don't have a TLC account because you are an individual applicant on a license:</span>
      <span>1. Connect with the person who is completing the license application in TLC</span>
      <span>2. Request them to share the License Id with you</span>

      <span *ngIf="licenseForm.get('licenseId').valid"><strong style="color:green">License Id confirmed!</strong></span>
      <span *ngIf="licenseForm.get('licenseId').valid"><strong style="color:green">Scroll down to fill in the form. It has populated below.</strong></span>
      <form [formGroup]="licenseForm">
        <mat-form-field fxFlex="50" fxFlex.lt-lg="75">
            <mat-label style="color:red">Enter License Id here to continue. EX: 1a2b3c4d</mat-label>
          <input matInput type="text" formControlName="licenseId" placeholder="licenseId Id">
          <mat-error *ngIf="licenseForm.get('licenseId').invalid">
            The id provided could not be found
          </mat-error>
        </mat-form-field>
      </form>
    </div>
  </mat-card-content>
</mat-card>

<mat-card style="font-size: large" fxLayoutGap="12px" *ngIf="submitted">
  <span>Thank you for submitting your REAL & SOGI data. If you need to fill out the form for another license, please enter the license id above.</span>
</mat-card>
<mat-card *ngIf="licenseForm.get('licenseId').valid && !submitted">
  <div fxLayout="column" fxLayoutGap="12px">
    <div fxLayout="row">
      <div fxLayout="column" fxFlex="15">
        <mat-card>
          <ul navAccordion class="matero-sidemenu" style="width:auto" fxFlex>
            <li navAccordionItem class="matero-sidemenu-item" *ngFor="let question of questions; let index = index" [ngClass]="{'active': currentQuestion === index}">
                <a matRipple (click)="changeQuestion(index)">
                  <span [style.color]="isValid(question) ? '' : 'red'">{{question}}</span>
                </a>
              </li>
            </ul>
        </mat-card>
      </div>
      <div fxLayout="column" fxFlex="85">
        <mat-card fxFlex="100" style="border-radius: 0;">
          <div *ngIf="currentQuestion == 0">
            <div fxLayout="column" style="font-size:large" fxLayoutGap="14px">
              <form [formGroup]="applicantInformationForm">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                  <span style="color:red" *ngIf="applicantInformationForm.get('legalFirstName').value ==''">Legal First Name*:</span>
                  <span style="color:inherit" *ngIf="applicantInformationForm.get('legalFirstName').value !=''">Legal First Name*:</span>
                  <mat-form-field>
                    <input matInput formControlName="legalFirstName">
                    <mat-error *ngIf="applicantInformationForm.get('legalFirstName').invalid">
                      Please enter your first name
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                  <span style="color:red" *ngIf="applicantInformationForm.get('legalLastName').value ==''">Legal Last Name*:</span>
                  <span style="color:inherit" *ngIf="applicantInformationForm.get('legalLastName').value !=''">Legal Last Name*:</span>
                  <mat-form-field>
                    <input matInput formControlName="legalLastName">
                    <mat-error *ngIf="applicantInformationForm.get('legalLastName').invalid">
                      Please enter your last name
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                  <span fxFlex="none">Legal Middle Name:</span>
                  <mat-form-field fxLayout="row">
                    <input matInput  formControlName="legalMiddleName">
                  </mat-form-field>
                </div>
                <div fxLayout="column" fxLayoutGap="10px">
                  <span fxFlex="none" [style.color]="applicantInformationForm.get('applicantStatus').invalid ? 'red' : 'inherit'">
                    Please check one:
                  </span>
                  <div fxLayout="column">
                  <mat-radio-group formControlName="applicantStatus" fxFlex fxLayout="column">
                    <mat-radio-button value="applicant" [style.color]="applicantInformationForm.get('applicantStatus').invalid ? 'red' : 'inherit'">I am an applicant for a license under ORS 475A - The license is NOT approved</mat-radio-button>
                    <mat-radio-button value="licensee" [style.color]="applicantInformationForm.get('applicantStatus').invalid ? 'red' : 'inherit'">I am a licensee under ORS 475A - The license is approved</mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="applicantInformationForm.get('applicantStatus').invalid && applicantInformationForm.get('applicantStatus').touched">
                    You must select an option
                  </mat-error>
                  <br />
                  <span>Click the numbers to the left to enter your REAL & SOGI Information.</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div *ngIf="currentQuestion == 1" fxLayout="column">
            <span><b>1.	Which of the following describes your racial or ethnic identity? Please check all that apply.</b></span>
            <form [formGroup]="racialIdentityForm">
              <br/>
              <div fxLayout="row">
                <div fxLayout="column" style="margin-left:1%" fxLayoutGap="5px">
                  <span><strong>Hispanic and Latino/a/x</strong></span>
                  <mat-checkbox formControlName="centralAmerican">Central American</mat-checkbox>
                  <mat-checkbox formControlName="mexican">Mexican</mat-checkbox>
                  <mat-checkbox formControlName="southAmerican">South American</mat-checkbox>
                  <mat-checkbox formControlName="otherHispanic">Other Hispanic or Latino/a/x</mat-checkbox>
                  <br>
                  <span><strong>Native Hawaiian and Pacific Islander</strong></span>
                  <mat-checkbox formControlName="chamoru">CHamoru (Chamorro)</mat-checkbox>
                  <mat-checkbox formControlName="marshallese">Marshallese</mat-checkbox>
                  <mat-checkbox formControlName="micronesian">Communities of the Micronesian Region</mat-checkbox>
                  <mat-checkbox formControlName="nativeHawaiian">Native Hawaiian</mat-checkbox>
                  <mat-checkbox formControlName="samoan">Samoan</mat-checkbox>
                  <mat-checkbox formControlName="otherPacificIslander">Other Pacific Islander</mat-checkbox>
                  <br>
                  <span><strong>White</strong></span>
                  <mat-checkbox formControlName="easternEuropean">Eastern European</mat-checkbox>
                  <mat-checkbox formControlName="slavic">Slavic</mat-checkbox>
                  <mat-checkbox formControlName="westernEuropean">Western European</mat-checkbox>
                  <mat-checkbox formControlName="otherWhite">Other White</mat-checkbox>
                  <br>
                  <span><strong>American Indian and Alaska Native</strong></span>
                  <mat-checkbox formControlName="americanIndian">American Indian</mat-checkbox>
                  <mat-checkbox formControlName="alaskaNative">Alaska Native</mat-checkbox>
                  <mat-checkbox formControlName="canadianInnuitMetisFirstNation">Canadian Innuit, Metis, or First Nation</mat-checkbox>
                  <mat-checkbox formControlName="indigenousMexicanCentralAmericanSouthAmerican">Indigenous Mexican, Central American or South American</mat-checkbox>
                  <br>
                  <span><strong>Black and African American</strong></span>
                  <mat-checkbox formControlName="africanAmerican">African American</mat-checkbox>
                  <mat-checkbox formControlName="afroCaribbean">Afro-Caribbean</mat-checkbox>
                  <mat-checkbox formControlName="ethiopian">Ethiopian</mat-checkbox>
                  <mat-checkbox formControlName="somali">Somali</mat-checkbox>
                  <mat-checkbox formControlName="otherAfrican">Other African (Black)</mat-checkbox>
                  <mat-checkbox formControlName="otherBlack">Other Black</mat-checkbox>
                  <br>
                  <span><strong>Middle Eastern / North African</strong></span>
                  <mat-checkbox formControlName="middleEastern">Middle Eastern</mat-checkbox>
                  <mat-checkbox formControlName="northAfrican">North African</mat-checkbox>
                  <br>
                  <span><strong>Asian</strong></span>
                  <mat-checkbox formControlName="asianIndian">Asian Indian</mat-checkbox>
                  <mat-checkbox formControlName="cambodian">Cambodian</mat-checkbox>
                  <mat-checkbox formControlName="chinese">Chinese</mat-checkbox>
                  <mat-checkbox formControlName="myanmar">Communities of Myanmar</mat-checkbox>
                  <mat-checkbox formControlName="filipino">Filipino/a</mat-checkbox>
                  <mat-checkbox formControlName="hmong">Hmong</mat-checkbox>
                  <mat-checkbox formControlName="japanese">Japanese</mat-checkbox>
                  <mat-checkbox formControlName="korean">Korean</mat-checkbox>
                  <mat-checkbox formControlName="laotian">Laotian</mat-checkbox>
                  <mat-checkbox formControlName="southAsian">South Asian</mat-checkbox>
                  <mat-checkbox formControlName="vietnamese">Vietnamese</mat-checkbox>
                  <mat-checkbox formControlName="otherAsian">Other Asian</mat-checkbox>
                  <br>
                </div>
              </div>
              <div fxLayout="row">
                <div fxLayout="column" fxLayoutGap="5px">
                  <mat-checkbox formControlName="otherTotal">Other (not listed)</mat-checkbox>
                  <mat-checkbox formControlName="dontKnowTotal">Don't Know</mat-checkbox>
                  <mat-checkbox formControlName="refusedTotal">Don't want to answer</mat-checkbox>
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="currentQuestion == 2">
            <span><b>2.	If you checked more than one category above, is there one you think of as your primary racial or ethnic identity?</b></span>
            <form [formGroup]="primaryRacialIdentityForm">
              <br/>
              <div fxLayout="row">
                <div fxLayout="column" style="margin-left:1%" fxLayoutGap="5px">
                  <span><strong>Hispanic and Latino/a/x</strong></span>
                  <mat-checkbox formControlName="centralAmerican">Central American</mat-checkbox>
                  <mat-checkbox formControlName="mexican">Mexican</mat-checkbox>
                  <mat-checkbox formControlName="southAmerican">South American</mat-checkbox>
                  <mat-checkbox formControlName="otherHispanic">Other Hispanic or Latino/a/x</mat-checkbox>
                  <br>
                  <span><strong>Native Hawaiian and Pacific Islander</strong></span>
                  <mat-checkbox formControlName="chamoru">CHamoru (Chamorro)</mat-checkbox>
                  <mat-checkbox formControlName="marshallese">Marshallese</mat-checkbox>
                  <mat-checkbox formControlName="micronesian">Communities of the Micronesian Region</mat-checkbox>
                  <mat-checkbox formControlName="nativeHawaiian">Native Hawaiian</mat-checkbox>
                  <mat-checkbox formControlName="samoan">Samoan</mat-checkbox>
                  <mat-checkbox formControlName="otherPacificIslander">Other Pacific Islander</mat-checkbox>
                  <br>
                  <span><strong>White</strong></span>
                  <mat-checkbox formControlName="easternEuropean">Eastern European</mat-checkbox>
                  <mat-checkbox formControlName="slavic">Slavic</mat-checkbox>
                  <mat-checkbox formControlName="westernEuropean">Western European</mat-checkbox>
                  <mat-checkbox formControlName="otherWhite">Other White</mat-checkbox>
                  <br>
                  <span><strong>American Indian and Alaska Native</strong></span>
                  <mat-checkbox formControlName="americanIndian">American Indian</mat-checkbox>
                  <mat-checkbox formControlName="alaskaNative">Alaska Native</mat-checkbox>
                  <mat-checkbox formControlName="canadianInnuitMetisFirstNation">Canadian Innuit, Metis, or First Nation</mat-checkbox>
                  <mat-checkbox formControlName="indigenousMexicanCentralAmericanSouthAmerican">Indigenous Mexican, Central American or South American</mat-checkbox>
                  <br>
                  <span><strong>Black and African American</strong></span>
                  <mat-checkbox formControlName="africanAmerican">African American</mat-checkbox>
                  <mat-checkbox formControlName="afroCaribbean">Afro-Caribbean</mat-checkbox>
                  <mat-checkbox formControlName="ethiopian">Ethiopian</mat-checkbox>
                  <mat-checkbox formControlName="somali">Somali</mat-checkbox>
                  <mat-checkbox formControlName="otherAfrican">Other African (Black)</mat-checkbox>
                  <mat-checkbox formControlName="otherBlack">Other Black</mat-checkbox>
                  <br>
                  <span><strong>Middle Eastern / North African</strong></span>
                  <mat-checkbox formControlName="middleEastern">Middle Eastern</mat-checkbox>
                  <mat-checkbox formControlName="northAfrican">North African</mat-checkbox>
                  <br>
                  <span><strong>Asian</strong></span>
                  <mat-checkbox formControlName="asianIndian">Asian Indian</mat-checkbox>
                  <mat-checkbox formControlName="cambodian">Cambodian</mat-checkbox>
                  <mat-checkbox formControlName="chinese">Chinese</mat-checkbox>
                  <mat-checkbox formControlName="myanmar">Communities of Myanmar</mat-checkbox>
                  <mat-checkbox formControlName="filipino">Filipino/a</mat-checkbox>
                  <mat-checkbox formControlName="hmong">Hmong</mat-checkbox>
                  <mat-checkbox formControlName="japanese">Japanese</mat-checkbox>
                  <mat-checkbox formControlName="korean">Korean</mat-checkbox>
                  <mat-checkbox formControlName="laotian">Laotian</mat-checkbox>
                  <mat-checkbox formControlName="southAsian">South Asian</mat-checkbox>
                  <mat-checkbox formControlName="vietnamese">Vietnamese</mat-checkbox>
                  <mat-checkbox formControlName="otherAsian">Other Asian</mat-checkbox>
                  <br>
                </div>
              </div>
              <div fxLayout="row">
                <div fxLayout="column" fxLayoutGap="5px">
                  <mat-checkbox formControlName="multipleIdentities">I do not have just one primary racial or ethnic identity.</mat-checkbox>
                  <mat-checkbox formControlName="biracialMultiRacial">No. I identify as Biracial or Multiracial </mat-checkbox>
                  <mat-checkbox formControlName="na">N/A. I only checked one category above.</mat-checkbox>
                  <mat-checkbox formControlName="dontKnowTotal">Don't Know</mat-checkbox>
                  <mat-checkbox formControlName="refusedTotal">Don't want to answer</mat-checkbox>
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="currentQuestion == 3">
            <span><b>3.What is your preferred spoken language:</b></span>
            <form [formGroup]="spokenLanguageForm">
              <br/>
              <div fxLayout="row">
                <div fxLayout="column" style="margin-left:1%" fxLayoutGap="5px">
                  <mat-checkbox formControlName="arabic">Arabic</mat-checkbox>
                  <mat-checkbox formControlName="bosnian">Bosnian</mat-checkbox>
                  <mat-checkbox formControlName="burmese">Burmese</mat-checkbox>
                  <mat-checkbox formControlName="cambodian">Cambodian</mat-checkbox>
                  <mat-checkbox formControlName="simplifiedChinese">Chinese, Simplified</mat-checkbox>
                  <mat-checkbox formControlName="traditionalChinese">Chinese, Traditional</mat-checkbox>
                  <mat-checkbox formControlName="chuukese">Chuukese</mat-checkbox>
                  <mat-checkbox formControlName="english">English</mat-checkbox>
                  <mat-checkbox formControlName="farsi">Farsi</mat-checkbox>
                  <mat-checkbox formControlName="french">French</mat-checkbox>
                  <mat-checkbox formControlName="german">German</mat-checkbox>
                  <mat-checkbox formControlName="japanese">Japanese</mat-checkbox>
                  <mat-checkbox formControlName="korean">Korean</mat-checkbox>
                  <mat-checkbox formControlName="lao">Lao</mat-checkbox>
                  <mat-checkbox formControlName="marshallese">Marshallese</mat-checkbox>
                  <mat-checkbox formControlName="oromo">Oromo (Cushite)</mat-checkbox>
                  <mat-checkbox formControlName="pohnpeian">Pohnpeian</mat-checkbox>
                  <mat-checkbox formControlName="romanian">Romanian</mat-checkbox>
                  <mat-checkbox formControlName="russian">Russian</mat-checkbox>
                  <mat-checkbox formControlName="somali">Somali</mat-checkbox>
                  <mat-checkbox formControlName="spanish">Spanish</mat-checkbox>
                  <mat-checkbox formControlName="thai">Thai</mat-checkbox>
                  <mat-checkbox formControlName="ukrainian">Ukrainian</mat-checkbox>
                  <mat-checkbox formControlName="vietnamese">Vietnamese</mat-checkbox>
                  <mat-checkbox formControlName="other">Other</mat-checkbox>
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="currentQuestion == 4">
            <span><b>4.What is your preferred written language:</b></span>
            <form [formGroup]="writtenLanguageForm">
              <br/>
              <div fxLayout="row">
                <div fxLayout="column" style="margin-left:1%" fxLayoutGap="5px">
                  <mat-checkbox formControlName="arabic">Arabic</mat-checkbox>
                  <mat-checkbox formControlName="bosnian">Bosnian</mat-checkbox>
                  <mat-checkbox formControlName="burmese">Burmese</mat-checkbox>
                  <mat-checkbox formControlName="cambodian">Cambodian</mat-checkbox>
                  <mat-checkbox formControlName="simplifiedChinese">Chinese, Simplified</mat-checkbox>
                  <mat-checkbox formControlName="traditionalChinese">Chinese, Traditional</mat-checkbox>
                  <mat-checkbox formControlName="chuukese">Chuukese</mat-checkbox>
                  <mat-checkbox formControlName="english">English</mat-checkbox>
                  <mat-checkbox formControlName="farsi">Farsi</mat-checkbox>
                  <mat-checkbox formControlName="french">French</mat-checkbox>
                  <mat-checkbox formControlName="german">German</mat-checkbox>
                  <mat-checkbox formControlName="japanese">Japanese</mat-checkbox>
                  <mat-checkbox formControlName="korean">Korean</mat-checkbox>
                  <mat-checkbox formControlName="lao">Lao</mat-checkbox>
                  <mat-checkbox formControlName="marshallese">Marshallese</mat-checkbox>
                  <mat-checkbox formControlName="oromo">Oromo (Cushite)</mat-checkbox>
                  <mat-checkbox formControlName="pohnpeian">Pohnpeian</mat-checkbox>
                  <mat-checkbox formControlName="romanian">Romanian</mat-checkbox>
                  <mat-checkbox formControlName="russian">Russian</mat-checkbox>
                  <mat-checkbox formControlName="somali">Somali</mat-checkbox>
                  <mat-checkbox formControlName="spanish">Spanish</mat-checkbox>
                  <mat-checkbox formControlName="thai">Thai</mat-checkbox>
                  <mat-checkbox formControlName="ukrainian">Ukrainian</mat-checkbox>
                  <mat-checkbox formControlName="vietnamese">Vietnamese</mat-checkbox>
                  <mat-checkbox formControlName="other">Other</mat-checkbox>
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="currentQuestion == 5">
            <span><b>5. What is your gender? Select all that apply. </b></span>
            <form [formGroup]="genderIdentityForm">
              <br/>
              <div fxLayout="row">
                <div fxLayout="column" style="margin-left:1%" fxLayoutGap="5px">
                  <mat-checkbox formControlName="woman">Girl or Woman</mat-checkbox>
                  <mat-checkbox formControlName="man">Boy or Man</mat-checkbox>
                  <mat-checkbox formControlName="nonBinary">NonBinary</mat-checkbox>
                  <mat-checkbox formControlName="agender">Agender/No gender</mat-checkbox>
                  <mat-checkbox formControlName="bigender">Bigender</mat-checkbox>
                  <mat-checkbox formControlName="demiboy">Demiboy</mat-checkbox>
                  <mat-checkbox formControlName="demigirl">Demigirl</mat-checkbox>
                  <mat-checkbox formControlName="genderfluid">Genderfluid</mat-checkbox>
                  <mat-checkbox formControlName="genderqueer">Genderqueer</mat-checkbox>
                  <mat-checkbox formControlName="questioning">Questioning/Exploring</mat-checkbox>
                  <mat-checkbox formControlName="notListed">Not Listed</mat-checkbox>
                  <mat-checkbox formControlName="specificToEthnicity">I have a gender identity not listed here that is specific to my ethnicity</mat-checkbox>
                  <mat-checkbox formControlName="dontKnow">Don't know</mat-checkbox>
                  <mat-checkbox formControlName="dontUnderstand">Don't know what this question is asking</mat-checkbox>
                  <mat-checkbox formControlName="noAnswer">Don't want to answer</mat-checkbox>
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="currentQuestion == 6">
            <span><b>6. Are you transgender?</b></span>
            <form [formGroup]="transgenderForm">
              <br/>
              <div fxLayout="row">
                <div fxLayout="column" style="margin-left:1%" fxLayoutGap="5px">
                  <mat-checkbox formControlName="yesTransgender">Yes</mat-checkbox>
                  <mat-checkbox formControlName="noTransgender">No</mat-checkbox>
                  <mat-checkbox formControlName="questioningTransgender">Questioning/Exploring</mat-checkbox>
                  <mat-checkbox formControlName="dontKnowTransgender">Don't know</mat-checkbox>
                  <mat-checkbox formControlName="dontUnderstandTransgender">Don't know what this question is asking</mat-checkbox>
                  <mat-checkbox formControlName="noAnswerTransgender">Don't want to answer</mat-checkbox>
                </div>
              </div>
            </form>
          </div>

          <div *ngIf="currentQuestion == 7">
            <span><b>7. What is your sex</b></span>
            <form [formGroup]="genderForm">
              <br/>
              <div fxLayout="row">
                <div fxLayout="column" style="margin-left:1%" fxLayoutGap="5px">
                <mat-checkbox formControlName="female">Female</mat-checkbox>
                <mat-checkbox formControlName="male">Male</mat-checkbox>
                <mat-checkbox formControlName="intersex">Intersex</mat-checkbox>
                <mat-checkbox formControlName="dontKnow">Don't know</mat-checkbox>
                <mat-checkbox formControlName="noAnswer">Don't want to answer</mat-checkbox>
                <mat-checkbox formControlName="notListed">Not Listed</mat-checkbox>
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="currentQuestion == 8">
            <span><b>8. What is your sexual orientation or sexual identity? (check all that apply) </b></span>
            <form [formGroup]="sexualOrientationForm">
              <br/>
              <div fxLayout="row">
                <div fxLayout="column" style="margin-left:1%" fxLayoutGap="5px">
                  <mat-checkbox formControlName="sameGenderLoving">Same-gender Loving</mat-checkbox>
                  <mat-checkbox formControlName="lesbian">Lesbian</mat-checkbox>
                  <mat-checkbox formControlName="gay">Gay</mat-checkbox>
                  <mat-checkbox formControlName="bisexual">Bisexual</mat-checkbox>
                  <mat-checkbox formControlName="pansexual">Pansexual</mat-checkbox>
                  <mat-checkbox formControlName="straight">Straight or heterosexual</mat-checkbox>
                  <mat-checkbox formControlName="asexual">Asexual Spectrum</mat-checkbox>
                  <mat-checkbox formControlName="queer">Queer</mat-checkbox>
                  <mat-checkbox formControlName="questioning">Questioning/Exploring</mat-checkbox>
                  <mat-checkbox formControlName="notListed">Not Listed</mat-checkbox>
                  <mat-checkbox formControlName="dontKnow">Don't know</mat-checkbox>
                  <mat-checkbox formControlName="dontUnderstand">Don't know what this question is asking</mat-checkbox>
                  <mat-checkbox formControlName="noAnswer">Don't want to answer</mat-checkbox>
                </div>
              </div>
            </form>
          </div>


          <div *ngIf="currentQuestion == 9">
            <div fxLayout="column" style="font-size:large">
              <span>
                Please review your answers and make sure you have entered the correct information before submitting the data.
              </span>
              <br>
              <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="128px">
                <button [disabled]="!applicantDataValid()" mat-flat-button type="button" color="primary" (click)="submit()">Submit</button>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</mat-card>
<span fxFlex></span>
<app-footer *ngIf="!sharedService.mobile"></app-footer>
</div>
