import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors } from "@angular/forms";
import { ApplicantLicenseIdValidator } from "@shared/validators/applicant-data-id-validator";
import { IApplicantData } from "app/models/303/applicantData/applicantData";
import { IApplicantGender } from "app/models/303/applicantData/applicantGender";
import { IApplicantGenderIdentity } from "app/models/303/applicantData/applicantGenderIdentity";
import { IApplicantLanguage } from "app/models/303/applicantData/applicantLanguage";
import { IApplicantRacialIdentity } from "app/models/303/applicantData/applicantRacialIdentity";
import { IApplicantSexualOrientation } from "app/models/303/applicantData/applicantSexualOrientation";
import { ApplicantDataService } from "app/services/applicant-data/applicant-data.service";
import { SharedService } from "app/services/core/shared.service";

@Component({
  selector: 'app-applicant-data',
  templateUrl: './applicant-data.component.html',
  styleUrls: ['./applicant-data.component.scss']
})

export class ApplicantDataComponent implements OnInit {
  public submitted: boolean = false;

  public applicantRacialIdentity: IApplicantRacialIdentity = {
    id: 0,
    centralAmerican: false,
    mexican: false,
    southAmerican: false,
    otherHispanic: false,
    chamoru: false,
    marshallese: false,
    micronesian: false,
    nativeHawaiian: false,
    samoan: false,
    otherPacificIslander: false,
    easternEuropean: false,
    slavic: false,
    westernEuropean: false,
    otherWhite: false,
    americanIndian: false,
    alaskaNative: false,
    canadianInnuitMetisFirstNation: false,
    indigenousMexicanCentralAmericanSouthAmerican: false,
    africanAmerican: false,
    afroCaribbean: false,
    ethiopian: false,
    somali: false,
    otherAfrican: false,
    otherBlack: false,
    middleEastern: false,
    northAfrican: false,
    asianIndian: false,
    cambodian: false,
    chinese: false,
    myanmar: false,
    filipino: false,
    hmong: false,
    japanese: false,
    korean: false,
    laotian: false,
    southAsian: false,
    vietnamese: false,
    otherAsian: false,
    otherTotal: false,
    dontKnowTotal: false,
    refusedTotal: false,
    na: false,
    biracialMultiRacial: false,
    multipleIdentities: false
  };

  public primaryApplicantRacialIdentity: IApplicantRacialIdentity = {
    id: 0,
    centralAmerican: false,
    mexican: false,
    southAmerican: false,
    otherHispanic: false,
    chamoru: false,
    marshallese: false,
    micronesian: false,
    nativeHawaiian: false,
    samoan: false,
    otherPacificIslander: false,
    easternEuropean: false,
    slavic: false,
    westernEuropean: false,
    otherWhite: false,
    americanIndian: false,
    alaskaNative: false,
    canadianInnuitMetisFirstNation: false,
    indigenousMexicanCentralAmericanSouthAmerican: false,
    africanAmerican: false,
    afroCaribbean: false,
    ethiopian: false,
    somali: false,
    otherAfrican: false,
    otherBlack: false,
    middleEastern: false,
    northAfrican: false,
    asianIndian: false,
    cambodian: false,
    chinese: false,
    myanmar: false,
    filipino: false,
    hmong: false,
    japanese: false,
    korean: false,
    laotian: false,
    southAsian: false,
    vietnamese: false,
    otherAsian: false,
    otherTotal: false,
    dontKnowTotal: false,
    refusedTotal: false,
    na: false,
    biracialMultiRacial: false,
    multipleIdentities: false
  };

  public spokenLanguage : IApplicantLanguage = {
    id: 0,
    arabic: false,
    bosnian: false,
    burmese: false,
    cambodian: false,
    chuukese: false,
    english: false,
    farsi: false,
    french: false,
    german: false,
    japanese: false,
    korean: false,
    lao: false,
    marshallese: false,
    oromo: false,
    pohnpeian: false,
    romanian: false,
    russian: false,
    simplifiedChinese: false,
    somali: false,
    spanish: false,
    thai: false,
    traditionalChinese: false,
    ukrainian: false,
    vietnamese: false,
    other: false
  };

  public writtenLanguage : IApplicantLanguage = {
    id: 0,
    arabic: false,
    bosnian: false,
    burmese: false,
    cambodian: false,
    chuukese: false,
    english: false,
    farsi: false,
    french: false,
    german: false,
    japanese: false,
    korean: false,
    lao: false,
    marshallese: false,
    oromo: false,
    pohnpeian: false,
    romanian: false,
    russian: false,
    simplifiedChinese: false,
    somali: false,
    spanish: false,
    thai: false,
    traditionalChinese: false,
    ukrainian: false,
    vietnamese: false,
    other: false
  };

  public applicantGenderIdentity: IApplicantGenderIdentity = {
    id: 0,
    woman: false,
    man: false,
    nonBinary: false,
    agender: false,
    bigender: false,
    demiboy: false,
    demigirl: false,
    genderfluid: false,
    genderqueer: false,
    questioning: false,
    notListed: false,
    specificToEthnicity: false,
    dontKnow: false,
    dontUnderstand: false,
    noAnswer: false,
    yesTransgender: false,
    noTransgender: false,
    questioningTransgender: false,
    dontKnowTransgender: false,
    dontUnderstandTransgender: false,
    noAnswerTransgender: false
  };

  public applicantGender: IApplicantGender = {
    id: 0,
    female: false,
    male: false,
    intersex: false,
    dontKnow: false,
    notListed: false,
    noAnswer: false
  };

  public applicantSexualOrientation: IApplicantSexualOrientation = {
    id: 0,
    sameGenderLoving: false,
    lesbian: false,
    gay: false,
    bisexual: false,
    pansexual: false,
    straight: false,
    asexual: false,
    queer: false,
    questioning: false,
    notListed: false,
    dontKnow: false,
    dontUnderstand: false,
    noAnswer: false
  };

  public applicantData: IApplicantData = {
    id: 0,
    applicantRacialIdentity: this.applicantRacialIdentity,
    primaryApplicantRacialIdentity: this.primaryApplicantRacialIdentity,
    spokenLanguage: this.spokenLanguage,
    writtenLanguage: this.writtenLanguage,
    applicantGenderIdentity: this.applicantGenderIdentity,
    applicantGender: this.applicantGender,
    applicantSexualOrientation: this.applicantSexualOrientation,
    legalFirstName: "",
    legalMiddleName: "",
    legalLastName: "",
    applicantStatus: ""
  };

  public licenseForm = new FormGroup({
    licenseId: new FormControl('', {
      asyncValidators: [this.licenseIdValidator.validate.bind(this.licenseIdValidator)],
      updateOn: 'change'
    })
  });

  public applicantInformationForm = new FormGroup({
    legalFirstName: new FormControl('', Validators.required),
    legalLastName: new FormControl('', Validators.required),
    legalMiddleName: new FormControl(''),
    applicantStatus: new FormControl('', Validators.required)
  });

  public spokenLanguageForm = new FormGroup({
    arabic: new FormControl(false),
    bosnian: new FormControl(false),
    burmese: new FormControl(false),
    cambodian: new FormControl(false),
    chuukese: new FormControl(false),
    english: new FormControl(false),
    farsi: new FormControl(false),
    french: new FormControl(false),
    german: new FormControl(false),
    japanese: new FormControl(false),
    korean: new FormControl(false),
    lao: new FormControl(false),
    marshallese: new FormControl(false),
    oromo: new FormControl(false),
    pohnpeian: new FormControl(false),
    romanian: new FormControl(false),
    russian: new FormControl(false),
    simplifiedChinese: new FormControl(false),
    somali: new FormControl(false),
    spanish: new FormControl(false),
    thai: new FormControl(false),
    traditionalChinese: new FormControl(false),
    ukrainian: new FormControl(false),
    vietnamese: new FormControl(false),
    other: new FormControl(false)
  }, { validators: atLeastOneSelected });

  public writtenLanguageForm = new FormGroup({
    arabic: new FormControl(false),
    bosnian: new FormControl(false),
    burmese: new FormControl(false),
    cambodian: new FormControl(false),
    chuukese: new FormControl(false),
    english: new FormControl(false),
    farsi: new FormControl(false),
    french: new FormControl(false),
    german: new FormControl(false),
    japanese: new FormControl(false),
    korean: new FormControl(false),
    lao: new FormControl(false),
    marshallese: new FormControl(false),
    oromo: new FormControl(false),
    pohnpeian: new FormControl(false),
    romanian: new FormControl(false),
    russian: new FormControl(false),
    simplifiedChinese: new FormControl(false),
    somali: new FormControl(false),
    spanish: new FormControl(false),
    thai: new FormControl(false),
    traditionalChinese: new FormControl(false),
    ukrainian: new FormControl(false),
    vietnamese: new FormControl(false),
    other: new FormControl(false)
  }, { validators: atLeastOneSelected });

  public racialIdentityForm = new FormGroup({
    centralAmerican: new FormControl(false),
    mexican: new FormControl(false),
    southAmerican: new FormControl(false),
    otherHispanic: new FormControl(false),
    chamoru: new FormControl(false),
    marshallese: new FormControl(false),
    micronesian: new FormControl(false),
    nativeHawaiian: new FormControl(false),
    samoan: new FormControl(false),
    otherPacificIslander: new FormControl(false),
    easternEuropean: new FormControl(false),
    slavic: new FormControl(false),
    westernEuropean: new FormControl(false),
    otherWhite: new FormControl(false),
    americanIndian: new FormControl(false),
    alaskaNative: new FormControl(false),
    canadianInnuitMetisFirstNation: new FormControl(false),
    indigenousMexicanCentralAmericanSouthAmerican: new FormControl(false),
    africanAmerican: new FormControl(false),
    afroCaribbean: new FormControl(false),
    ethiopian: new FormControl(false),
    somali: new FormControl(false),
    otherAfrican: new FormControl(false),
    otherBlack: new FormControl(false),
    middleEastern: new FormControl(false),
    northAfrican: new FormControl(false),
    asianIndian: new FormControl(false),
    cambodian: new FormControl(false),
    chinese: new FormControl(false),
    myanmar: new FormControl(false),
    filipino: new FormControl(false),
    hmong: new FormControl(false),
    japanese: new FormControl(false),
    korean: new FormControl(false),
    laotian: new FormControl(false),
    southAsian: new FormControl(false),
    vietnamese: new FormControl(false),
    otherAsian: new FormControl(false),
    otherTotal: new FormControl(false),
    dontKnowTotal: new FormControl(false),
    refusedTotal: new FormControl(false)
  }, { validators: atLeastOneSelected });

  public primaryRacialIdentityForm = new FormGroup({
    centralAmerican: new FormControl(false),
    mexican: new FormControl(false),
    southAmerican: new FormControl(false),
    otherHispanic: new FormControl(false),
    chamoru: new FormControl(false),
    marshallese: new FormControl(false),
    micronesian: new FormControl(false),
    nativeHawaiian: new FormControl(false),
    samoan: new FormControl(false),
    otherPacificIslander: new FormControl(false),
    easternEuropean: new FormControl(false),
    slavic: new FormControl(false),
    westernEuropean: new FormControl(false),
    otherWhite: new FormControl(false),
    americanIndian: new FormControl(false),
    alaskaNative: new FormControl(false),
    canadianInnuitMetisFirstNation: new FormControl(false),
    indigenousMexicanCentralAmericanSouthAmerican: new FormControl(false),
    africanAmerican: new FormControl(false),
    afroCaribbean: new FormControl(false),
    ethiopian: new FormControl(false),
    somali: new FormControl(false),
    otherAfrican: new FormControl(false),
    otherBlack: new FormControl(false),
    middleEastern: new FormControl(false),
    northAfrican: new FormControl(false),
    asianIndian: new FormControl(false),
    cambodian: new FormControl(false),
    chinese: new FormControl(false),
    myanmar: new FormControl(false),
    filipino: new FormControl(false),
    hmong: new FormControl(false),
    japanese: new FormControl(false),
    korean: new FormControl(false),
    laotian: new FormControl(false),
    southAsian: new FormControl(false),
    vietnamese: new FormControl(false),
    otherAsian: new FormControl(false),
    multipleIdentities: new FormControl(false),
    biracialMultiRacial: new FormControl(false),
    na: new FormControl(false),
    dontKnowTotal: new FormControl(false),
    refusedTotal: new FormControl(false)
  }, { validators: atLeastOneSelected });

  public genderIdentityForm = new FormGroup({
    woman: new FormControl(false),
    man: new FormControl(false),
    nonBinary: new FormControl(false),
    agender: new FormControl(false),
    bigender: new FormControl(false),
    demiboy: new FormControl(false),
    demigirl: new FormControl(false),
    genderfluid: new FormControl(false),
    genderqueer: new FormControl(false),
    questioning: new FormControl(false),
    notListed: new FormControl(false),
    specificToEthnicity: new FormControl(false),
    dontKnow: new FormControl(false),
    dontUnderstand: new FormControl(false),
    noAnswer: new FormControl(false),
  }, { validators: atLeastOneSelected });

  public transgenderForm = new FormGroup({
    yesTransgender: new FormControl(false),
    noTransgender: new FormControl(false),
    questioningTransgender: new FormControl(false),
    dontKnowTransgender: new FormControl(false),
    dontUnderstandTransgender: new FormControl(false),
    noAnswerTransgender: new FormControl(false),
  }, { validators: atLeastOneSelected });

  public genderForm = new FormGroup({
    female: new FormControl(false),
    male: new FormControl(false),
    intersex: new FormControl(false),
    dontKnow: new FormControl(false),
    notListed: new FormControl(false),
    noAnswer: new FormControl(false)
  }, { validators: atLeastOneSelected });

  public sexualOrientationForm = new FormGroup({
    sameGenderLoving: new FormControl(false),
    lesbian: new FormControl(false),
    gay: new FormControl(false),
    bisexual: new FormControl(false),
    pansexual: new FormControl(false),
    straight: new FormControl(false),
    asexual: new FormControl(false),
    queer: new FormControl(false),
    questioning: new FormControl(false),
    notListed: new FormControl(false),
    dontKnow: new FormControl(false),
    dontUnderstand: new FormControl(false),
    noAnswer: new FormControl(false),
  }, { validators: atLeastOneSelected });

  public currentQuestion: number = 0;
  public questions = ['Introduction', '1.', '2.', '3.', '4.', '5.', '6.', '7.', '8.', 'Review & Submit'];

  constructor(public sharedService: SharedService,
    public applicantDataService: ApplicantDataService,
    private licenseIdValidator: ApplicantLicenseIdValidator) { }

  ngOnInit(): void {
    this.licenseForm.get('licenseId').valueChanges.subscribe(() => {
      this.submitted = false;
    });
  }


  changeQuestion(index: number) {
    if(this.currentQuestion == 1)
      this.applicantInformationForm.markAllAsTouched();
    this.currentQuestion = index;
  }

  isValid(question:string): boolean{
    switch (question) {
      case 'Introduction':
        return this.applicantInformationForm.valid;
      case '1.':
        return this.racialIdentityForm.valid;
      case '2.':
        return this.primaryRacialIdentityForm.valid;
      case '3.':
        return this.spokenLanguageForm.valid;
      case '4.':
        return this.writtenLanguageForm.valid;
      case '5.':
        return this.genderIdentityForm.valid;
      case '6.':
        return this.transgenderForm.valid;
      case '7.':
        return this.genderForm.valid;
      case '8.':
        return this.sexualOrientationForm.valid;
    }
    return true;
  }

  applicantDataValid(): boolean{
    return this.applicantInformationForm.valid &&
      this.racialIdentityForm.valid &&
      this.primaryRacialIdentityForm.valid &&
      this.spokenLanguageForm.valid &&
      this.writtenLanguageForm.valid &&
      this.genderIdentityForm.valid &&
      this.transgenderForm.valid &&
      this.genderForm.valid &&
      this.sexualOrientationForm.valid;
  }

  submit() : void {
    this.sharedService.openConfirm("Do you want to submit the \"Required 303 Applicant and Licensee Data\" form? Once you submit you will no longer be able to make any changes.");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          const languageFields = [
            'arabic', 'bosnian', 'burmese', 'cambodian', 'chuukese', 'english', 'farsi',
            'french', 'german', 'japanese', 'korean', 'lao', 'marshallese', 'oromo',
            'pohnpeian', 'romanian', 'russian', 'simplifiedChinese', 'somali',
            'spanish', 'thai', 'traditionalChinese', 'ukrainian', 'vietnamese', 'other'
          ];

          languageFields.forEach(field => {
            this.applicantData.spokenLanguage[field] = this.spokenLanguageForm.get(field).value;
            this.applicantData.writtenLanguage[field] = this.writtenLanguageForm.get(field).value;
          });

          const genderFields = [
            'female', 'male', 'intersex', 'dontKnow', 'notListed', 'noAnswer',
          ];

          genderFields.forEach(field => {
            this.applicantData.applicantGender[field] = this.genderForm.get(field).value;
          });

          const sexualOrientationFields = [
            'sameGenderLoving', 'lesbian', 'gay', 'bisexual', 'pansexual', 'straight',
            'asexual', 'queer', 'questioning', 'notListed', 'dontKnow', 'dontUnderstand',
            'noAnswer'
          ];

          sexualOrientationFields.forEach(field => {
            this.applicantData.applicantSexualOrientation[field] = this.sexualOrientationForm.get(field).value;
          });

          const transgenderFields = [
            'yesTransgender', 'noTransgender', 'questioningTransgender', 'dontKnowTransgender',
            'dontUnderstandTransgender', 'noAnswerTransgender'
          ];

          transgenderFields.forEach(field => {
            this.applicantData.applicantGenderIdentity[field] = this.transgenderForm.get(field).value;
          });

          const genderIdentityFields = [
            'woman', 'man', 'nonBinary', 'agender', 'bigender', 'demiboy', 'demigirl',
            'genderfluid', 'genderqueer', 'questioning', 'notListed', 'specificToEthnicity',
            'dontKnow', 'dontUnderstand', 'noAnswer'
          ];

          genderIdentityFields.forEach(field => {
            this.applicantData.applicantGenderIdentity[field] = this.genderIdentityForm.get(field).value;
          });

          const racialIdentityFields = [
            'centralAmerican', 'mexican', 'southAmerican', 'otherHispanic', 'chamoru',
            'marshallese', 'micronesian', 'nativeHawaiian', 'samoan', 'otherPacificIslander',
            'easternEuropean', 'slavic', 'westernEuropean', 'otherWhite', 'americanIndian',
            'alaskaNative', 'canadianInnuitMetisFirstNation', 'indigenousMexicanCentralAmericanSouthAmerican',
            'africanAmerican', 'afroCaribbean', 'ethiopian', 'somali', 'otherAfrican',
            'otherBlack', 'middleEastern', 'northAfrican', 'asianIndian', 'cambodian', 'chinese',
            'myanmar', 'filipino', 'hmong', 'japanese', 'korean', 'laotian', 'southAsian',
            'vietnamese', 'otherAsian', 'otherTotal', 'dontKnowTotal', 'refusedTotal'
          ];

          racialIdentityFields.forEach(field => {
            this.applicantData.applicantRacialIdentity[field] = this.racialIdentityForm.get(field).value;
          });

          const  primaryRacialIdentityFields = [
            'centralAmerican', 'mexican', 'southAmerican', 'otherHispanic', 'chamoru', 'marshallese',
            'micronesian', 'nativeHawaiian', 'samoan', 'otherPacificIslander', 'easternEuropean',
            'slavic', 'westernEuropean', 'otherWhite', 'americanIndian', 'alaskaNative',
            'canadianInnuitMetisFirstNation', 'indigenousMexicanCentralAmericanSouthAmerican',
            'africanAmerican', 'afroCaribbean', 'ethiopian', 'somali', 'otherAfrican',
            'otherBlack', 'middleEastern', 'northAfrican', 'asianIndian',  'cambodian',
            'chinese', 'myanmar', 'filipino', 'hmong', 'japanese', 'korean', 'laotian',
            'southAsian', 'vietnamese', 'otherAsian', 'multipleIdentities', 'biracialMultiRacial',
            'na', 'dontKnowTotal', 'refusedTotal'
          ];

          primaryRacialIdentityFields.forEach(field => {
            this.applicantData.primaryApplicantRacialIdentity[field] = this.primaryRacialIdentityForm.get(field).value;
          });

          this.applicantData.legalFirstName = this.applicantInformationForm.get('legalFirstName').value;
          this.applicantData.legalLastName = this.applicantInformationForm.get('legalLastName').value;
          this.applicantData.legalMiddleName = this.applicantInformationForm.get('legalMiddleName').value;
          this.applicantData.applicantStatus = this.applicantInformationForm.get('applicantStatus').value;

          this.applicantDataService.submitApplicantData(this.licenseForm.get('licenseId').value, this.applicantData).subscribe(
            response => {
              if(response) {
                this.sharedService.toastr.success('Applicant data saved successfully');
                this.submitted = true;
              }
              else
                this.sharedService.toastr.error('Error saving applicant data');
            },
            error => console.log('error', error)
          );
        }
      });
  }

}

export const atLeastOneSelected: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
  const controls = formGroup.controls;
  const isAtLeastOneSelected = Object.keys(controls).some(key => controls[key].value === true);
  return isAtLeastOneSelected ? null : { atLeastOneSelected: true };
};
