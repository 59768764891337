<mat-card class="sticky-header p-y-0" [style.background-color]="sharedService.blue">
  <mat-card-content>
    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="16px" fxLayoutGap.lt-lg="" fxLayoutAlign="start center" fxLayoutAlign.lt-lg="" style="color: #303030">
      <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <mat-icon class="medium-icon m-24">home</mat-icon>
        <span class="f-s-20">TLC Home</span>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<div [hidden]="hasApproved || !licensesLoaded">
  <div fxLayout="column">
    <mat-card [style.background-color]="sharedService.blue + '30'">
      <mat-card-title>
        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center">
          <span>You must have an approved license to view this page</span>
        </div>
      </mat-card-title>
    </mat-card>
  </div>
</div>
<div [hidden]="!hasApproved">
  <div fxLayout="column">
    <mat-card [style.background-color]="sharedService.blue + '30'">
      <mat-card-title>
        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center">
          <span>{{ adminSettings.headerText }}</span>
        </div>
      </mat-card-title>
      <mat-card-content>
        <div fxLayout="column">
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px" class="m-t-8">
            <mat-card fxFlex="50" fxFlex.lt-lg="100">
              <mat-card-content>
                <div class="f-s-16" fxLayout="column" fxLayoutGap="8px">
                  <span style="white-space: pre-wrap">{{ adminSettings.message }}</span>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card fxFlex="50" fxFlex.lt-lg="100">
              <mat-card-content>
                <div fxLayout="column" class="f-s-16" fxLayoutGap="8px">
                  <span fxLayoutAlign="center center" style="font-size: larger"><u>Documents</u></span>
                  <span style="font-size: large"><strong>Client Forms</strong></span>
                  <div fxLayout="column" *ngFor="let link of adminSettings.documentLinks">
                    <div  fxLayout="column" *ngIf="link.section === 'Client Forms'">
                      <span>{{ link.description }}</span>
                      <a class="text-indigo-A700" [href]="link.link" target="_blank">{{ link.link }}</a>
                    </div>
                  </div>
                  <span style="font-size: larger"><strong>Operational Forms</strong></span>
                  <div fxLayout="column" *ngFor="let link of adminSettings.documentLinks">
                    <div fxLayout="column" *ngIf="link.section === 'Operational Forms'">
                      <span>{{ link.description }}</span>
                      <a class="text-indigo-A700" [href]="link.link" target="_blank">{{ link.link }}</a>
                    </div>
                  </div>
                  <span style="font-size: larger"><strong>Other</strong></span>
                  <div fxLayout="column" *ngFor="let link of adminSettings.documentLinks">
                    <div fxLayout="column" *ngIf="link.section === ''">
                      <span>{{ link.description }}</span>
                      <a class="text-indigo-A700" [href]="link.link" target="_blank">{{ link.link }}</a>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>



          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px" class="m-t-8">
            <mat-card fxFlex="50" fxFlex.lt-lg="100">
              <mat-card-content>
                <div class="f-s-16" fxLayout="column" fxLayoutGap="8px">
                  <span fxLayoutAlign="center center" style="font-size: larger"><u>Helpful Links</u></span>
                  <div fxLayout="column" *ngFor="let link of adminSettings.links">
                    <span>{{ link.description }}</span>
                    <a class="text-indigo-A700" [href]="link.link" target="_blank">{{ link.link }}</a>
                  </div>

                  <a class="text-indigo-A700 f-s-18 m-t-16" routerLink="/messages">Provide Feedback</a>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card fxFlex="50" fxFlex.lt-lg="100">
              <mat-card-content>
                <div fxLayout="column" class="f-s-16" fxLayoutGap="8px">
                  <span fxLayoutAlign="center center" style="font-size: larger"><u>303</u></span>
                  <div fxLayout="row" fxLayoutGap="8px" class="m-t-8">
                    <div fxLayout="column" class="f-s-14" fxLayoutGap="18px" fxFlex="100">
                      <div fxLayout="row" class="f-s-14" fxLayoutGap="8px" fxFlex="100">
                        <span style="white-space: pre-wrap">{{ adminSettings.sb303Message }}</span>
                      </div>
                      <div class="thick-line"></div>
                      <br/>
                      <div fxLayout="column" *ngFor="let link of adminSettings.sb303Links">
                        <span>{{ link.description }}</span>
                        <a class="text-indigo-A700" [href]="link.link" target="_blank">{{ link.link }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>

        <div fxLayout="column">
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px" class="m-t-8">
            <mat-card fxFlex="50" fxFlex.lt-lg="100">
              <mat-card-content>
                <span fxLayoutAlign="center center" style="font-size: larger"><u>Change Requests</u></span>
                <div fxLayout="row" fxLayoutGap="8px" class="m-t-8">
                  <div fxLayout="column" class="f-s-14" fxLayoutGap="18px" fxFlex="100">
                    <div fxLayout="row" class="f-s-14" fxLayoutGap="8px" fxFlex="100">
                      <span style="white-space: pre-wrap">{{ adminSettings.changeRequestMessage }}</span>
                    </div>
                    <div class="thick-line"></div>
                    <br/>
                    <div fxLayout="column" *ngFor="let link of adminSettings.changeRequestLinks">
                      <span>{{ link.description }}</span>
                      <a class="text-indigo-A700" [href]="link.link" target="_blank">{{ link.link }}</a>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card fxFlex="50" fxFlex.lt-lg="100">
              <mat-card-content>
                <span fxLayoutAlign="center center" style="font-size: larger"><u>Renewals</u></span>
                <div fxLayout="row" fxLayoutGap="8px" class="m-t-8">
                  <div fxLayout="column" class="f-s-14" fxLayoutGap="18px" fxFlex="100">
                    <div fxLayout="row" class="f-s-14" fxLayoutGap="8px" fxFlex="100">
                      <span style="white-space: pre-wrap">{{ adminSettings.renewalMessage }}</span>
                    </div>
                    <div class="thick-line"></div>
                    <br/>
                    <div fxLayout="column" *ngFor="let link of adminSettings.renewalLinks">
                      <span>{{ link.description }}</span>
                      <a class="text-indigo-A700" [href]="link.link" target="_blank">{{ link.link }}</a>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div *ngIf="loading">
  <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div>
