import { trigger, transition, style, animate } from '@angular/animations';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { IDocument } from 'app/models/documents/document';
import { IDocumentType } from 'app/models/documents/documentType';
import { IWorkerLicense } from 'app/models/licenses/worker-license';
import { SharedService } from 'app/services/core/shared.service';
import { WorkerService } from 'app/services/licenses/worker.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PaymentService } from 'app/components/payments/service/payment.service';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-worker-permit',
  templateUrl: './worker-permit.component.html',
  styleUrls: ['./worker-permit.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})

export class WorkerPermitComponent implements OnInit, OnDestroy {
  public loading: boolean = false;
  public showStartApplication = false;
  public workerSteps: string[] = [
    "gettingStarted",
    "personalInformation",
    "documents",
    "feeInformation",
    "reviewAndSubmit"
  ];
  public url = environment.baseUrl;
  public stepsIndex: { [id: string]: number } = {
    "gettingStarted": 0,
    "personalInformation": 1,
    "documents": 2,
    "feeInformation": 3,
    "reviewAndSubmit": 4
  };
  public viewEdit: boolean = false;
  public showEditLoading: boolean = false;
  public showSubmitted: boolean = false;
  public showConfirmed: boolean = false;
  public showForm: boolean = true;
  public showResidentialHistory: boolean = false;
  public showStartButton: boolean = false;
  public today: Date = new Date(Date.now());
  public step: number = 0;
  public workerLicenses: IWorkerLicense[] = [];
  public workerLicense: IWorkerLicense = {
    id: 0,
    userId: '',
    licenseId: '',
    status: '',
    canRenew: false,
    reducedRequest: false,
    attestation: false,
    electronicSignature: '',
    applicationDate: '',
    expirationDate: '',
    applicants: [],
    dateCreated: '',
    payments: [],
    approvedDate: ''
  }
  public routeSubscription: Subscription;
  public documentTypes: IDocumentType[] = [];
  public documentTypeReference: { [id: number]: string } = {};
  public personalIdentification: string = 'Personal Identification';
  public applicantInformation: string = 'Applicant Information';
  public personalIdentificationType: number = 0;
  public applicantInfoType: number = 0;
  public applicantDocuments: IDocument[] = [];
  public cardColor: string = this.sharedService.red + '10';
  public canApplyStatuses: string[] = [
    this.sharedService.denied,
    this.sharedService.expired,
    this.sharedService.withdrawn,
    this.sharedService.revoked,
    this.sharedService.closed,
    this.sharedService.surrendered
  ];

  public applicantForm = new UntypedFormGroup({
    noSsn: new UntypedFormControl(false),
    ssn: new UntypedFormControl(""),
    ssnNoAttestation: new UntypedFormControl(false),
    ssnAttestation: new UntypedFormControl(false, [Validators.requiredTrue]),
    legalFirstName: new UntypedFormControl("", [Validators.required]),
    legalMiddleName: new UntypedFormControl(""),
    legalLastName: new UntypedFormControl("", [Validators.required]),
    preferredFirstName: new UntypedFormControl(""),
    preferredMiddleName: new UntypedFormControl(""),
    preferredLastName: new UntypedFormControl(""),
    alternateNames: new UntypedFormControl(""),
    physicalState: new UntypedFormControl("", [Validators.required]),
    physicalStreet: new UntypedFormControl("", [Validators.required]),
    physicalCity: new UntypedFormControl("", [Validators.required]),
    physicalZip: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    physicalCounty: new UntypedFormControl(""),
    mailingIsPhysical: new UntypedFormControl(false),
    mailingStreet: new UntypedFormControl(""),
    mailingCity: new UntypedFormControl(""),
    mailingState: new UntypedFormControl(""),
    mailingZip: new UntypedFormControl("", [Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    mailingCounty: new UntypedFormControl(""),
    phone: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
    alternatePhone: new UntypedFormControl("", [Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
    email: new UntypedFormControl("", [Validators.required, Validators.email]),
    confirmEmail: new UntypedFormControl("", [Validators.required, Validators.email]),
    language: new UntypedFormControl(""),
    previousLicense: new UntypedFormControl(false),
    dob: new UntypedFormControl("", [Validators.required]),
    affiliatedLicenses: new UntypedFormControl("")
  }, {
    validators: [
      this.mailingStreetValidator(),
      this.mailingCityValidator(),
      this.mailingZipValidator(),
      this.mailingStateValidator(),
      this.emailValidator(),
      this.ssnValidator(),
      this.ssnAttestationValidator(),
      this.previousLicenseValidator()
    ]
  }
  );

  public licenseForm = new UntypedFormGroup({
    attestation: new UntypedFormControl(false, [Validators.requiredTrue]),
    electronicSignature: new UntypedFormControl("", [Validators.required])
  });

  public documentColumns: string[] = ['name', 'type'];
  public documentSource = new MatTableDataSource<IDocument>();

  @ViewChild('documentPaginator', { static: false }) documentPaginator: MatPaginator;
  @ViewChild('documentSort', { static: false }) documentSort: MatSort;

  constructor(private workerService: WorkerService,
    public sharedService: SharedService,
    private router: Router,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public paymentService: PaymentService) { }

  ngOnInit(): void {
    this.loading = true;

    this.applicantForm.patchValue({
      identification: false,
      applicantInfo: false
    });
    this.getDocumentTypes();
    this.workerService.getWorkerLicenses().subscribe(
      response => this.workerLicenses = response,
      error => console.log('error', error),
      () => {

        this.workerLicenses.forEach(license => {
          if(this.canApplyStatuses.includes(license.status)){
            this.showStartButton = true;
          }
        });

        if(this.workerLicenses.length == 0)
          this.showStartButton = true;

        this.documentSource.sort = this.documentSort;
        this.documentSource.paginator = this.documentPaginator;
        if (this.sharedService.userProfile.userId === null) {
          this.sharedService.getUserInfo().subscribe(
            response => this.sharedService.userProfile = response,
            error => console.log('error', error),
            () => {
              this.loading = false;
              this.getWorkerLicense();
              if (this.workerLicense == null) {
                this.toastr.error("Not Authorized");
                this.router.navigate(['/license-dashboard']);
              }
              this.sharedService.toggleTheme();
              this.updateUserState();
              if (this.workerLicense.applicants[0].applicantDocuments.length >= 1) {
                this.applicantDocuments = this.workerLicense.applicants[0].applicantDocuments;
                this.documentSource.data = this.workerLicense.applicants[0].applicantDocuments;
              }
              else
                this.applicantDocuments = [];
            }
          );
        }
        else {
          this.getWorkerLicense();
          this.updateUserState();
        }
      }
    );

  }

  public get canApplyAgain(): boolean {
    let canApply: boolean = true;
    this.workerLicenses.forEach(license => {
      if(!this.canApplyStatuses.includes(license.status)){
        canApply = false;
      }
    });
    return canApply;
  }

  hasPaidApplicationFee(license: IWorkerLicense): boolean {
    if(license.payments == null || license.payments.length == 0) {
      return false;
    }

    let appFee = license.payments.find(tp => tp.paid);

    if(appFee == null || appFee == undefined) {
      return false;
    }

    return true;
  }

  hasUnpaidFees(permit: IWorkerLicense): boolean {
    if (permit.payments == null) {
      return false;
    }
    let unpaid = permit.payments.find(p => !p.paid);
    if (unpaid == null || unpaid == undefined) {
      return false;
    }
    return true;
  }

  setupRouteSubscription(): void {
    this.routeSubscription = this.router.events
    .pipe(filter((event) => event instanceof NavigationStart))
    .subscribe((e) => {
      this.updatePersonalInformationForm();
    });
  }

  validateSsnAttestation(): boolean {
    if (this.applicantForm.hasError('ssnAttestationRequired') && this.applicantForm.get('ssnAttestation').touched) {
      this.applicantForm.get('ssnAttestation').setErrors(['ssnAttestationRequired']);
      return true;
    }
    this.applicantForm.get('ssnAttestation').clearValidators();
    this.applicantForm.get('ssnAttestation').updateValueAndValidity();
    return false;
  }

  changeAttestation(agree: boolean): void {
    if (agree) {
      this.applicantForm.patchValue({
        ssnNoAttestation: false
      });
    }
    if (!agree) {
      this.applicantForm.patchValue({
        ssnAttestation: false
      });
    }

  }

  validAttestation(): void {
    if (this.applicantForm.get('ssnAttestation').value === null) {
      this.applicantForm.get('ssnAttestation').setErrors(['ssnAttestationRequired']);
    }
  }

  ssnAttestationValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const ssn = control.value.ssnAttestation;
      const noSsn = control.value.ssnNoAttestation;
      if (noSsn) {
        return null;
      }
      return (ssn !== null && ssn !== '' && ssn !== undefined) ? null : { ssnAttestationRequired: true };
    }
  }

  getPaymentFeeStatus(license: IWorkerLicense): string {
    if(license.payments == null || license.payments.length == 0) {
      return "";
    }
     return license.payments[0].status;
  }

  getapplicationClearedDate(license: IWorkerLicense): string {
    if(license.payments == null || license.payments.length == 0) {
      return "";
    }

    return license.payments[0].paymentCleared;
  }

  getDocumentTypes(): void {
    this.workerService.getWorkerDocumentTypes().subscribe(
      response => this.documentTypes = response,
      error => console.log('error', error),
      () => {
        this.personalIdentificationType = this.documentTypes.find(dt => dt.type === this.personalIdentification).id;
        this.applicantInfoType = this.documentTypes.find(dt => dt.type === this.applicantInformation).id;
        this.documentTypeReference[this.personalIdentificationType] = this.personalIdentification;
        this.documentTypeReference[this.applicantInfoType] = this.applicantInformation;
      }
    );
  }

  getWorkerLicense(): void {
    if (this.workerLicenses.length == 0) {
      this.showForm = true;
    }
    else {
      if(this.workerLicenses.length === 1){
        let currentLicense = this.workerLicenses.find(fl => fl.status === 'Pending');
        if(currentLicense){
          this.editApplication(currentLicense);
          this.showForm = true;
        }
        else{
          this.showForm = false;
          this.showSubmitted = true;
        }
      }
      else{
        this.showForm = false;
        this.showSubmitted = true;
      }
      }
  }

  clearForms(): void {
    this.applicantForm.patchValue({
      noSsn: new UntypedFormControl(false),
      ssn: new UntypedFormControl(""),
      legalFirstName: new UntypedFormControl("", [Validators.required]),
      legalMiddleName: new UntypedFormControl(""),
      legalLastName: new UntypedFormControl("", [Validators.required]),
      preferredFirstName: new UntypedFormControl(""),
      preferredMiddleName: new UntypedFormControl(""),
      preferredLastName: new UntypedFormControl(""),
      alternateNames: new UntypedFormControl(""),
      physicalStreet: new UntypedFormControl("", [Validators.required]),
      physicalCity: new UntypedFormControl("", [Validators.required]),
      physicalZip: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\d{5}(-\d{4})?$/)]),
      physicalCounty: new UntypedFormControl(""),
      mailingIsPhysical: new UntypedFormControl(false),
      mailingStreet: new UntypedFormControl(""),
      mailingCity: new UntypedFormControl(""),
      mailingState: new UntypedFormControl(""),
      mailingZip: new UntypedFormControl("", [Validators.pattern(/^\d{5}(-\d{4})?$/)]),
      mailingCounty: new UntypedFormControl(""),
      phone: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
      alternatePhone: new UntypedFormControl("", [Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
      email: new UntypedFormControl("", [Validators.required, Validators.email]),
      confirmEmail: new UntypedFormControl("", [Validators.required, Validators.email]),
      language: new UntypedFormControl(""),
      previousLicense: new UntypedFormControl(false),
      dob: null,
      affiliatedLicenses: new UntypedFormControl(""),
      identification: new UntypedFormControl(false),
      applicantInfo: new UntypedFormControl(false)
    });
  }

  updateUserState(): void {
    if (this.workerSteps.includes(this.sharedService.userProfile.currentStep))
      this.step = this.stepsIndex[this.sharedService.userProfile.currentStep]
    if (this.sharedService.userProfile.currentPage !== "license/worker-permit") {
      this.sharedService.userProfile.currentPage = "license/worker-permit";
      this.sharedService.updateUserProfileState();
    }
    this.loading = false;
  }

  startApplication(): void {
    this.showStartButton = false;
    this.workerService.createWorkerLicense().subscribe(
      response => this.workerLicense = response,
      error => console.log('error', error),
      () => {
        this.setupRouteSubscription();
        this.showStartApplication = false;
        this.showResidentialHistory = true;
        this.workerLicenses.push(this.workerLicense);
        this.updateApplicantForm();
        this.updateLicenseForm();
        this.viewEdit = false;
      }
    );
  }

  updateApplicantForm(): void {
    let applicant = this.workerLicense.applicants[0];
    if (applicant.ssnAttestation === false) {
      this.applicantForm.patchValue({
        ssnNoAttestation: true
      });
    }
    this.applicantForm.patchValue({
      ssn: applicant.ssn,
      noSsn: applicant.noSSN,
      ssnAttestation: applicant.ssnAttestation,
      legalFirstName: applicant.legalFirstName,
      legalMiddleName: applicant.legalMiddleName,
      legalLastName: applicant.legalLastName,
      preferredFirstName: applicant.preferredFirstName,
      preferredMiddleName: applicant.preferredMiddleName,
      preferredLastName: applicant.preferredLastName,
      alternateNames: applicant.alternateNames,
      physicalState: applicant.physicalState,
      physicalStreet: applicant.physicalStreet,
      physicalCity: applicant.physicalCity,
      physicalZip: applicant.physicalZip,
      physicalCounty: applicant.physicalCounty,
      mailingIsPhysical: applicant.mailingIsPhysical,
      mailingStreet: applicant.mailingStreet,
      mailingCity: applicant.mailingCity,
      mailingState: applicant.mailingState,
      mailingZip: applicant.mailingZip,
      mailingCounty: applicant.mailingCounty,
      phone: applicant.phone,
      alternatePhone: applicant.alternatePhone,
      email: applicant.email,
      confirmEmail: applicant.email,
      language: applicant.language,
      previousLicense: applicant.previousLicense,
      affiliatedLicenses: applicant.affiliatedLicenses,
      identification: new UntypedFormControl(false),
      applicantInfo: new UntypedFormControl(false)
    });

    if (applicant.dob != null && applicant.dob.length > 0)
      this.applicantForm.get('dob').patchValue(new Date(applicant.dob));
  }

  updateLicenseForm(): void {
    this.licenseForm.patchValue({
      attestation: this.workerLicense.attestation,
      electronicSignature: this.workerLicense.electronicSignature
    });
  }

  previousLicenseValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasPreviousLicense = control.value.previousLicense;
      const affiliatedLicenses = control.value.affiliatedLicenses;
      if(!hasPreviousLicense){
        return null;
      }
      return (affiliatedLicenses !== null && affiliatedLicenses !== '' && affiliatedLicenses !== undefined) ? null : { licensesRequired: true };
    }
  }

  validatePreviousLicense(): boolean {
    if (this.applicantForm.hasError('licensesRequired') && this.applicantForm.get('affiliatedLicenses').touched) {
      return true;
    }
    return false;
  }

  mailingIsSame(): void {
    this.applicantForm.get('mailingStreet').updateValueAndValidity();
    this.applicantForm.get('mailingCity').updateValueAndValidity();
    this.applicantForm.get('mailingState').updateValueAndValidity();
    this.applicantForm.get('mailingZip').updateValueAndValidity();
  }

  emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email = control.value.email;
      const confirmation = control.value.confirmEmail;
      return email === confirmation ? null : { emailConfirmed: true };
    }
  }

  validateEmail(): boolean {
    if (this.applicantForm.hasError('emailConfirmed') && this.applicantForm.get('confirmEmail').touched) {
      this.applicantForm.get('confirmEmail').setErrors(['emailConfirmed']);
      return true;
    }
    this.applicantForm.get('confirmEmail').clearValidators();
    this.applicantForm.get('confirmEmail').updateValueAndValidity();
    return false;
  }

  mailingStreetValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const street = control.value.mailingStreet;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (street !== null && street !== '' && street !== undefined) ? null : { mailingStreetRequired: true };
      }
      return null;
    };
  }

  validateMailingStreet(): boolean {
    if (this.applicantForm.hasError('mailingStreetRequired') && this.applicantForm.get('mailingStreet').touched) {
      this.applicantForm.get('mailingStreet').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingStreet').clearValidators();
    this.applicantForm.get('mailingStreet').updateValueAndValidity();
    return false;
  }

  mailingCityValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const city = control.value.mailingCity;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (city !== null && city !== '' && city !== undefined) ? null : { mailingCityRequired: true };
      }
      return null;
    };
  }

  validateMailingCity(): boolean {
    if (this.applicantForm.hasError('mailingCityRequired') && this.applicantForm.get('mailingCity').touched) {
      this.applicantForm.get('mailingCity').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingCity').clearValidators();
    this.applicantForm.get('mailingCity').updateValueAndValidity();
    return false;
  }

  mailingStateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const state = control.value.mailingState;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (state !== null && state !== '' && state !== undefined) ? null : { mailingStateRequired: true };
      }
      return null;
    };
  }

  validateMailingState(): boolean {
    if (this.applicantForm.hasError('mailingStateRequired') && this.applicantForm.get('mailingState').touched) {
      this.applicantForm.get('mailingState').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingState').clearValidators();
    this.applicantForm.get('mailingState').updateValueAndValidity();
    return false;
  }

  mailingZipValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const zip = control.value.mailingZip;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (zip !== null && zip !== '' && zip !== undefined) ? null : { mailingZipRequired: true };
      }
      return null;
    };
  }

  validateMailingZip(): boolean {
    if (this.applicantForm.hasError('mailingZipRequired') && this.applicantForm.get('mailingZip').touched) {
      this.applicantForm.get('mailingZip').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingZip').clearValidators();
    this.applicantForm.get('mailingZip').updateValueAndValidity();
    return false;
  }

  ssnValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const ssn = control.value.ssn;
      const noSsn = control.value.noSsn;
      if(noSsn){
        return null;
      }
      return (ssn !== null && ssn !== '' && ssn !== undefined) ? null : { ssnRequired: true };
    }
  }

  validateSsn(): boolean {
    if (this.applicantForm.hasError('ssnRequired') && this.applicantForm.get('ssn').touched) {
      this.applicantForm.get('ssn').setErrors(['ssnRequired']);
      return true;
    }
    this.applicantForm.get('ssn').clearValidators();
    this.applicantForm.get('ssn').updateValueAndValidity();
    return false;
  }

  showOregonResidency(applicationDate: string): boolean {
    const currentDate = new Date();
    const comparisonDate = new Date(2025, 0, 2);
    const parsedDate = new Date(applicationDate);

    if(applicationDate && parsedDate > comparisonDate)
    {
      return false;
    }

    if (currentDate < comparisonDate) {
      return true;
    }

    if (!applicationDate) {
      return false;
    }
    return parsedDate < comparisonDate;
  }

  updatePersonalInformationForm(): void {
    this.workerLicense.applicants[0].ssn = this.applicantForm.get('ssn').value;
    this.workerLicense.applicants[0].noSSN = this.applicantForm.get('noSsn').value;
    this.workerLicense.applicants[0].ssnAttestation = this.applicantForm.get('ssnAttestation').value;
    this.workerLicense.applicants[0].legalFirstName = this.applicantForm.get('legalFirstName').value;
    this.workerLicense.applicants[0].legalMiddleName = this.applicantForm.get('legalMiddleName').value;
    this.workerLicense.applicants[0].legalLastName = this.applicantForm.get('legalLastName').value;
    this.workerLicense.applicants[0].affiliatedLicenses = this.applicantForm.get('affiliatedLicenses').value;
    this.workerLicense.applicants[0].preferredFirstName = this.applicantForm.get('preferredFirstName').value;
    this.workerLicense.applicants[0].preferredMiddleName = this.applicantForm.get('preferredMiddleName').value;
    this.workerLicense.applicants[0].preferredLastName = this.applicantForm.get('preferredLastName').value;
    this.workerLicense.applicants[0].alternateNames = this.applicantForm.get('alternateNames').value;
    this.workerLicense.applicants[0].physicalState = this.applicantForm.get('physicalState').value;
    this.workerLicense.applicants[0].physicalCity = this.applicantForm.get('physicalCity').value;
    this.workerLicense.applicants[0].physicalStreet = this.applicantForm.get('physicalStreet').value;
    this.workerLicense.applicants[0].physicalZip = this.applicantForm.get('physicalZip').value;
    this.workerLicense.applicants[0].physicalCounty = this.applicantForm.get('physicalCounty').value;
    this.workerLicense.applicants[0].mailingIsPhysical = this.applicantForm.get('mailingIsPhysical').value;
    this.workerLicense.applicants[0].mailingState = this.applicantForm.get('mailingState').value;
    this.workerLicense.applicants[0].mailingCity = this.applicantForm.get('mailingCity').value;
    this.workerLicense.applicants[0].mailingStreet = this.applicantForm.get('mailingStreet').value;
    this.workerLicense.applicants[0].mailingZip = this.applicantForm.get('mailingZip').value;
    this.workerLicense.applicants[0].mailingCounty = this.applicantForm.get('mailingCounty').value;
    this.workerLicense.applicants[0].phone = this.applicantForm.get('phone').value;
    this.workerLicense.applicants[0].alternatePhone = this.applicantForm.get('alternatePhone').value;
    this.workerLicense.applicants[0].email = this.applicantForm.get('email').value;
    this.workerLicense.applicants[0].language = this.applicantForm.get('language').value;
    this.workerLicense.applicants[0].previousLicense = this.applicantForm.get('previousLicense').value;
    this.workerLicense.applicants[0].dob = this.applicantForm.get('dob').value;
    this.workerLicense.electronicSignature = this.licenseForm.get('electronicSignature').value;
    this.workerLicense.attestation = this.licenseForm.get('attestation').value;
    if(this.workerLicense.status == 'Pending')
      this.workerService.updateWorkerApplication(this.workerLicense);
  }

  changeStep(step: StepperSelectionEvent): void {
    if (step.previouslySelectedIndex === 1) {
      this.updatePersonalInformationForm();
      this.applicantForm.markAllAsTouched();
      this.applicantForm.markAsDirty();
      this.validAttestation();
    }
    if (step.selectedIndex === 3) {
      this.applicantForm.markAsTouched();
      this.applicantForm.markAsDirty();
      this.licenseForm.markAsDirty();
    }
    if (step.selectedIndex === 2) {
      this.applicantForm.patchValue({
        identification: false,
        applicantInfo: false
      });
    }
    if (step.previouslySelectedIndex === 3) {
      this.updatePersonalInformationForm();
    }
    if (step.selectedIndex === 4) {
      this.applicantForm.markAsDirty();
      this.licenseForm.markAsDirty();
    }

    this.sharedService.userProfile.currentStep = this.workerSteps[step.selectedIndex];
    this.sharedService.updateUserProfileState();
  }

  uploadApplicantDocument(event: Event): void {
    let types: number[] = [this.personalIdentificationType];
    let dirtyFile = (event.target as HTMLInputElement).files[0];
    let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, ''));
    if(this.sharedService.validateFile(file))
    {
      let upload: IDocument = {
        id: 0,
        name: file.name,
        comments: "",
        extenstion: "",
        dateCreated: "",
        dateLastUpdated: "",
        createdBy: "",
        lastUpdatedBy: "",
        parentId: this.workerLicense.applicants[0].id,
        types: types,
        adminOnly: false,
        deprecated: false
      }

      const formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("document", JSON.stringify(upload));
      this.workerService.uploadDocument(formData, true).subscribe(
        response => {
          this.workerLicense.applicants[0].applicantDocuments.push(response)
          this.documentSource.data = this.workerLicense.applicants[0].applicantDocuments;
        },
        error => console.log('error', error)
      );
    }
    else
      this.toastr.error("Unsupported File Type");
  }

  downloadDocument(fileId: number, fileName: string, applicant: boolean): void {
    this.workerService.downloadFile(fileId, applicant, this.workerLicense.id).subscribe(
      (response) => this.saveFile(fileName, response),
      (error) => console.log("error", error)
    );
  }

  saveFile(fileName: string, blob: Blob) {
    let file = URL.createObjectURL(blob);
    var fileDownload = document.createElement("a");
    fileDownload.href = file;
    fileDownload.download = fileName;
    fileDownload.click();
  }

  deleteFile(id: number, name: string, applicant: boolean) {
    this.sharedService.openConfirm('Delete ' + name + '?');
    this.sharedService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.workerService.deleteDocument(id, applicant, this.workerLicense.id).subscribe(
          () => {
            if (applicant) {
              this.applicantDocuments = this.applicantDocuments.filter(item => item.name !== name);
              this.workerLicense.applicants[0].applicantDocuments = this.applicantDocuments;
              this.documentSource.data = this.workerLicense.applicants[0].applicantDocuments;
            }
          },
          error => console.log('error', error)
        );
      }
    });
  }

  submitApplication(): void {
    this.workerLicense.attestation = this.licenseForm.get('attestation').value;
    this.workerLicense.electronicSignature = this.licenseForm.get('electronicSignature').value;
    this.workerLicense.status = "Submitted";
    this.workerService.submitApplication(this.workerLicense).subscribe(
      response => {
        this.workerLicenses = response;
      },
      error => console.log('error', error),
      () => {
        this.routeSubscription.unsubscribe();
        this.sharedService.userProfile.currentStep = this.workerSteps[0];
        this.sharedService.updateUserProfileState();
        this.showForm = false;
        this.showConfirmed = true;
        this.loading = false;
        this.viewEdit = false;
      }
    );
  }

  checkSignature(): void {
    this.workerLicense.electronicSignature = this.licenseForm.get('electronicSignature').value;
    if (this.workerLicense.electronicSignature != "")
      this.licenseForm.get('electronicSignature').setErrors(null);
  }

  continueToDashboard(): void {
    this.router.navigateByUrl('/license-dashboard');
  }

  continue(): void {
    this.showConfirmed = false;
    this.showSubmitted = true;
  }

  pageTitle() {
    return "Worker Permit Submitted!";
  }

  canRenew(permit: IWorkerLicense): boolean {
    if (!permit.canRenew) {
      return false;
    }
    if (permit.status === 'Approved') {
      return true;
    }
    if (permit.status === 'expired') {
      return true;
    }
    return false;
  }

  renewPermit(permitId: number): void {
    // Needs to be implemented
    // this.trainingProgramService.renewTrainingProgram(programId).subscribe(
    //   response => {
    //     this.trainingProgram = response;
    //     this.trainingPrograms.push(this.trainingProgram);
    //   },
    //   error => this.sharedService.consoleLog(error),
    //   () => {
    //     this.getProgramInformation();
    //     this.updateContactsTable();
    //   }
    // );
  }
  requestWithdraw(permit: IWorkerLicense): void{
    this.sharedService.openConfirm("Are you sure you want to request to withdraw your application?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.workerService.requestWithdraw(permit.id).subscribe(
            response => {
              let index = this.workerLicenses.findIndex(fl => fl.id == permit.id);
              this.workerLicenses[index] = response;
            },
            error => console.log('error', error),
          )
    }});
  }

  requestSurrender(permit: IWorkerLicense): void{
    this.sharedService.openConfirm("Are you sure you want to request to surrender your application?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.workerService.requestSurrender(permit.id).subscribe(
            response => {
              let index = this.workerLicenses.findIndex(fl => fl.id == permit.id);
              this.workerLicenses[index] = response;
            },
            error => console.log('error', error),
          )
    }});
  }
  toggleView(): void {
    if(this.workerLicense.status == 'Pending'){
      this.sharedService.openConfirm("Some of your changes may be lost. Continue?");
        this.sharedService.confirmed().subscribe(
          confirmed => {
            if(confirmed){
    this.showForm = false;
    this.viewEdit = false;
    this.showSubmitted = true;
  }});
}
  else{
    this.showForm = false;
    this.viewEdit = false;
    this.showSubmitted = true;
  }
  }

  editApplication(permit: IWorkerLicense): void {
    this.showForm = true;
    this.step = 0;
    this.workerLicense = permit;
    this.workerLicense.applicants[0].applicantDocuments = permit.applicants[0].applicantDocuments;
    this.documentSource.data = this.workerLicense.applicants[0].applicantDocuments;
    this.applicantDocuments = permit.applicants[0].applicantDocuments;

    this.applicantForm.patchValue({
      ssn: permit.applicants[0].ssn,
      noSsn: permit.applicants[0].noSSN,
      ssnAttestation: permit.applicants[0].ssnAttestation,
      legalFirstName: permit.applicants[0].legalFirstName,
      legalMiddleName: permit.applicants[0].legalMiddleName,
      legalLastName: permit.applicants[0].legalLastName,
      affiliatedLicenses: permit.applicants[0].affiliatedLicenses,
      preferredFirstName: permit.applicants[0].preferredFirstName,
      preferredMiddleName: permit.applicants[0].preferredMiddleName,
      preferredLastName: permit.applicants[0].preferredLastName,
      alternateNames: permit.applicants[0].alternateNames,
      physicalState: permit.applicants[0].physicalState,
      physicalStreet: permit.applicants[0].physicalStreet,
      physicalCity: permit.applicants[0].physicalCity,
      physicalZip: permit.applicants[0].physicalZip,
      physicalCounty: permit.applicants[0].physicalCounty,
      mailingIsPhysical: permit.applicants[0].mailingIsPhysical,
      mailingStreet: permit.applicants[0].mailingStreet,
      mailingCity: permit.applicants[0].mailingCity,
      mailingState: permit.applicants[0].mailingState,
      mailingZip: permit.applicants[0].mailingZip,
      mailingCounty: permit.applicants[0].mailingCounty,
      phone: permit.applicants[0].phone,
      alternatePhone: permit.applicants[0].alternatePhone,
      email: permit.applicants[0].email,
      confirmEmail: permit.applicants[0].email,
      language: permit.applicants[0].language,
      previousLicense: permit.applicants[0].previousLicense,
      identification: new UntypedFormControl(false),
      applicantInfo: new UntypedFormControl(false)
    });

    if (permit.applicants[0].dob != null && permit.applicants[0].dob.length > 0)
      this.applicantForm.get('dob').patchValue(new Date(permit.applicants[0].dob));

    if (this.workerLicense.status != 'Pending')
    {
      this.showResidentialHistory = false;
      this.applicantForm.disable();
      this.licenseForm.disable();
    }
    if (this.workerLicense.status == 'Pending')
    {
      this.applicantForm.enable();
      this.licenseForm.enable();
      this.showResidentialHistory = true;
    }
    this.viewEdit = true;
    this.setupRouteSubscription();
    setTimeout(() => { this.showEditLoading = false; }, 2000);
    setTimeout(() => { this.showSubmitted = false; }, 100);
  }

  resubmit(permitId: number): void {
    this.sharedService.openConfirm('Resubmit this application?');
    this.sharedService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.workerService.ResubmitPermit(permitId).subscribe(
          () => {
            this.workerLicenses.forEach(license => {
              if (license.id === permitId) {
                license.status = 'Submitted';
              }
            });
          }
        );
      }
    });
  }

  withdrawLicense(permitId: number): void {
    this.sharedService.openConfirm('Withdraw this application?');
    this.sharedService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.workerService.withdrawPermit(permitId).subscribe(
          () => {
            this.workerLicenses.forEach(license => {
              if (license.id === permitId) {
                license.status = 'Withdrawn';
              }
            });
          }
        );
      }
    });
  }

  viewWorkerLicenseDetails(license: IWorkerLicense): void {
    if (license[0] != null) {
      this.dialog.open(WorkerDetailsDialog, {
        data: license[0],
        maxWidth: !this.sharedService.mobile ? '900px' : '800px',
        maxHeight: this.sharedService.mobile ? '750px' : '900px',
        autoFocus: false,
        panelClass: this.sharedService.userProfile.theme === "dark" ? "theme-dark" : "",
      });
    }
    else {
      this.dialog.open(WorkerDetailsDialog, {
        data: license,
        maxWidth: !this.sharedService.mobile ? '900px' : '800px',
        maxHeight: this.sharedService.mobile ? '750px' : '900px',
        autoFocus: false,
        panelClass: this.sharedService.userProfile.theme === "dark" ? "theme-dark" : "",
      });
    }
  }

  ngOnDestroy(): void {
    setTimeout(() => {this.routeSubscription.unsubscribe()}, 250);
  }
}

@Component({
  selector: "worker-details-dialog",
  templateUrl: "./worker-dialogs/dialog-worker-details.html",
  styleUrls: ["../../license-dashboard/license-dashboard.component.scss"]
})

export class WorkerDetailsDialog {
  public url = environment.baseUrl;

  constructor(public dialogRef: MatDialogRef<WorkerDetailsDialog>,
    public sharedService: SharedService,
    public paymentService: PaymentService,
    @Inject(MAT_DIALOG_DATA) public license: IWorkerLicense) { }

    print() {
      window.print();
    }

  cancel(): void {
    this.dialogRef.close('cancel');
  }
}
