import { trigger, transition, style, animate } from '@angular/animations';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn, AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationStart, Router } from '@angular/router';
import { environment } from '@env/environment';
import { PaymentService } from 'app/components/payments/service/payment.service';
import { IDocument } from 'app/models/documents/document';
import { IDocumentType } from 'app/models/documents/documentType';
import { IApplicant } from 'app/models/licenses/applicant';
import { IServiceCenterLicense } from 'app/models/licenses/service-center-license';
import { ITrainingProgram } from 'app/models/training/training-program';
import { SharedService } from 'app/services/core/shared.service';
import { ServiceCenterService } from 'app/services/licenses/service-center.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ApplicantComponent } from '../applicant/applicant.component';
import { EntityApplicantComponent } from '../entity-applicant/entity-applicant.component';
import { DialogAddPermitComponent } from '../dialog-add-permit/dialog-add-permit.component';
import { DialogChangeDirectoryComponent } from '../dialog-change-directory-consent/dialog-change-directory-consent.component';
import { IEntityApplicant } from 'app/models/licenses/entity-applicant';
import { ApplicantService } from 'app/services/licenses/applicant.service';
import { DialogAddPermitSB303Component } from '../dialog-add-permit-sb303/dialog-add-permit-sb303.component';

@Component({
  selector: 'app-service-center',
  templateUrl: './service-center.component.html',
  styleUrls: ['./service-center.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})

export class ServiceCenterComponent implements OnInit, OnDestroy {
  @ViewChild(ApplicantComponent) individualApplicants: ApplicantComponent;
  @ViewChild(EntityApplicantComponent) entityApplicants: EntityApplicantComponent;
  public loadingServiceCenter: boolean = false;
  public showStartApplication: boolean = false;
  public showStartButton: boolean = false;
  public showSubmitted: boolean = false;
  public viewForm: boolean = true;
  public loadingIndex: {[id: number]: boolean} = {};
  public licenseSteps: string[] = [
    "gettingStarted",
    "serviceCenterInformation",
    "applicants",
    "financialInterests",
    "schoolProximity",
    "practicumSite",
    "documents",
    "feeInformation",
    "reviewAndSubmit"
  ];
  public stepsIndex: { [id: string]: number } = {
    "gettingStarted": 0,
    "serviceCenterInformation": 1,
    "applicants": 2,
    "financialInterests": 3,
    "schoolProximity": 4,
    "practicumSite": 5,
    "documents": 6,
    "feeInformation": 7,
    "reviewAndSubmit": 8
  };
  public licenses: IServiceCenterLicense[] = [];
  public license: IServiceCenterLicense = {
    id: 0,
    userId: '',
    licenseId: '',
    status: '',
    entityName: '',
    entityWebsite: '',
    registeredBusiness: null,
    businessInOregon: null,
    secretaryofStateNumber: '',
    registrationAttestation: false,
    registryNumber: '',
    registeredIn: '',
    financialAttestation: false,
    applicantAttestation: false,
    physicalState: 'OR',
    physicalCity: '',
    physicalStreet: '',
    physicalZip: '',
    physicalCounty: '',
    premisesDescription: '',
    mailingIsPhysical: false,
    mailingState: '',
    mailingCity: '',
    mailingStreet: '',
    mailingZip: '',
    mailingCounty: '',
    practicumSiteAttestation: false,
    schoolAttestation: false,
    schoolInProximity: false,
    primaryContactName: '',
    primaryContactNumber: '',
    primaryContactEmail: '',
    primaryContactId: '',
    language: '',
    previousLicense: false,
    affiliatedLicenses: '',
    canRenew: false,
    canOperate: false,
    reducedRequest: false,
    electronicSignature: '',
    attestation: false,
    directoryConsent: null,
    directoryConsentWebsite: false,
    directoryConsentEmail: false,
    directoryConsentPhone: false,
    directoryPhone: '',
    directoryEmail: '',
    directoryWebsite: '',
    applicationDate: '',
    approvedDate: '',
    renewedDate: '',
    expirationDate: '',
    entityApplicants: [],
    applicants: [],
    workerPermits: [],
    sB303WorkerPermits: [],
    licenseDocuments: [],
    payments: [],
    practicumSites: [],
    barrierPedestrianCrossing: '',
    barrierAttestation: false,
    barrierDescription: '',
    distanceFromSchool: '',
    timely: false,
    financialInterestCorrect: null,
    financialInterestCorrectReason: '',
    serviceCenterInformationCorrect: false,
    serviceCenterInformationCorrectReason: '',
    practicumSiteInformationCorrect: false,
    practicumSiteInformationCorrectReason: '',
    isRenewal: false,
    applicantsCorrect: false,
    applicantsCorrectReason: '',
    sB303Records: []
  }
  public routeSubscription: Subscription;
  public trainingPrograms: ITrainingProgram[] = [];
  public step: number = 0;
  public renewing: boolean = false;
  public editingSchoolId: number = 0;
  public schoolIncomplete: boolean = false;
  public licenseLocked: boolean = false;
  public documentTypes: IDocumentType[] = [];
  public documentTypeReference: { [id: number]: string } = {};
  public missingTypes: {[type: number]: boolean} = {};
  public lucs: string = 'Land Use Compatibility Statement'
  public barrier: string = 'Barrier Document'
  public premisesSketch: string = 'Premises Plan';
  public socialEquityPlan: string = 'Social Equity Plan';
  public authorizedIndividual: string = 'Authorized Individual Form';
  public reducedFee: string = 'Reduced Fee';
  public premisePicture: string = 'Premise Picture';
  public updatedEquityPlan: string = "Updated Social Equity Plan";
  public equityPlanEvaluation: string = "Social Equity Plan Evaluation";
  public updatedPremisePicture: string = "Updated Premise Picture";
  public lucsType: number = 0;
  public barrierType: number = 0;
  public premisesType: number = 0;
  public socialEquityType: number = 0;
  public authorizedIndividualType: number = 0;
  public reducedFeeType: number = 0;
  public premisePictureType: number = 0;
  public updatedEquityPlanType: number = 0;
  public equityPlanEvaluationType: number = 0;
  public updatedPremisePictureType: number = 0;
  public financialAttestationValid: boolean = true;
  public applicantAttestationValid: boolean = true;
  public schoolAttestationValid: boolean = true;
  public barrierAttestationValid: boolean = true;
  public schoolProximityValid: boolean = true;
  public barrierQuestionsValid: boolean = true;
  public barrierDocumentsValid: boolean = true;
  public practicumValid: boolean = true;
  public contactValid: boolean = true;
  public documentsValid: boolean = true;
  public applicantsValid: boolean = true;
  public entityApplicantsValid: boolean = true;
  public editingApplicant: boolean = false;
  public editingEntityApplicant: boolean = false;
  public cardColor: string = this.sharedService.green + '20';
  public canApply: boolean = false;
  public startedRenewal: boolean = false;

  public licenseForm = new UntypedFormGroup({
    entityName: new UntypedFormControl('', Validators.required),
    entityWebsite: new UntypedFormControl('', Validators.pattern( /^(http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]/i )),
    businessInOregon: new UntypedFormControl(Validators.required),
    registrationAttestation: new UntypedFormControl(false, Validators.requiredTrue),
    registryNumber: new UntypedFormControl(''),
    registeredBusiness: new UntypedFormControl(),
    secretaryofStateNumber: new UntypedFormControl(''),
    registeredIn: new UntypedFormControl(''),
    financialAttestation: new UntypedFormControl(false),
    applicantFeeAttestation: new UntypedFormControl(false),
    applicantDenialAttestation: new UntypedFormControl(false),
    physicalCity: new UntypedFormControl('', Validators.required),
    physicalStreet: new UntypedFormControl('', Validators.required),
    physicalZip: new UntypedFormControl('', [Validators.required, Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    physicalCounty: new UntypedFormControl('', [Validators.required]),
    premisesDescription: new UntypedFormControl(''),
    mailingIsPhysical: new UntypedFormControl(false),
    mailingState: new UntypedFormControl(''),
    mailingCity: new UntypedFormControl(''),
    mailingStreet: new UntypedFormControl(''),
    mailingZip: new UntypedFormControl('', [Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    mailingCounty: new UntypedFormControl(''),
    practicumSiteAttestation: new UntypedFormControl(false),
    schoolAttestation: new UntypedFormControl(false),
    schoolInProximity: new UntypedFormControl(),
    noSchoolInProximity: new UntypedFormControl(),
    barrierPedestrianCrossing: new UntypedFormControl(''),
    barrierAttestation: new UntypedFormControl(false),
    barrierDescription: new UntypedFormControl(''),
    distanceFromSchool: new UntypedFormControl(''),
    primaryContactName: new UntypedFormControl(''),
    primaryContactNumber: new UntypedFormControl(''),
    primaryContactEmail: new UntypedFormControl(''),
    primaryContactId: new UntypedFormControl(''),
    language: new UntypedFormControl(''),
    previousLicense: new UntypedFormControl(false),
    affiliatedLicenses: new UntypedFormControl(''),
    applicantsCorrect: new UntypedFormControl(),
    applicantsCorrectReason: new UntypedFormControl(''),
    financialInterestCorrect: new UntypedFormControl(null),
    financialInterestCorrectReason: new UntypedFormControl(''),
    serviceCenterInformationCorrect: new UntypedFormControl(),
    serviceCenterInformationCorrectReason: new UntypedFormControl(''),
  }, {
    validators: [
      this.mailingStreetValidator(),
      this.mailingCityValidator(),
      this.mailingStateValidator(),
      this.mailingZipValidator(),
      this.previousLicenseValidator(),
      this.businessInOregonValidator(),
      this.secretaryOfStateNumberValidator(),
      this.businessInOtherStateValidator(),
      this.registeredLocationValidator(),
      this.registryNumberValidator(),
      this.serviceCenterInformationCorrectValidator(),
    ]
  });
  public practicumForm = new FormGroup({
    practicumSiteInformationCorrect: new FormControl(),
    practicumSiteInformationCorrectReason: new FormControl(''),
  }, {
    validators: [
      this.practicumSiteInformationCorrectValidator(),
    ]
  });

  public schoolForm = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    address: new UntypedFormControl('')
  });

  public documentsForm = new UntypedFormGroup({
    lucs: new UntypedFormControl(false),
    premisesSketch: new UntypedFormControl(false),
    socialEquityPlan: new UntypedFormControl(false),
    reducedFee: new UntypedFormControl(false),
    premisePicture: new UntypedFormControl(false),
    updatedEquityPlan: new UntypedFormControl(false),
    equityPlanEvaluation: new UntypedFormControl(false),
    updatedPremisePicture: new UntypedFormControl(false),
  });

  public verifyForm = new UntypedFormGroup({
    electronicSignature: new UntypedFormControl('', [Validators.required]),
    attestation: new UntypedFormControl(false, [Validators.requiredTrue]),
    directoryConsent: new UntypedFormControl("", [Validators.required]),
     directoryConsentWebsite: new UntypedFormControl(false),
    directoryConsentEmail: new UntypedFormControl(false),
    directoryConsentPhone: new UntypedFormControl(false),
    directoryPhone: new UntypedFormControl(""),
    directoryEmail: new UntypedFormControl(""),
    directoryWebsite: new UntypedFormControl(''),
  }, {
    validators: [
      this.directoryConsentWebsiteValidator(),
      this.directoryConsentEmailValidator(),
      this.directoryConsentPhoneValidator(),
    ]
  });

  public dataColumns: string[] = ['programName', 'actions'];
  public dataSource = new MatTableDataSource<ITrainingProgram>(this.trainingPrograms);
  @ViewChild('paginator', {static: false}) paginator: MatPaginator;
  @ViewChild('sort', {static: false}) sort: MatSort;

  public trainingSource = new MatTableDataSource<ITrainingProgram>(this.license.practicumSites);
  @ViewChild('trainingPaginator', {static: false}) trainingPaginator: MatPaginator;
  @ViewChild('trainingSort', {static: false}) trainingSort: MatSort;

  constructor(public sharedService: SharedService,
              public licenseService: ServiceCenterService,
              private router: Router,
              private dialog: MatDialog,
              public paymentService: PaymentService,
              public toastr: ToastrService) { }

  ngOnInit(): void {
    this.loadingServiceCenter = true;
    this.getDocumentTypes();
    this.getTrainingPrograms();
    this.licenseService.getLicenses().subscribe(
      response => this.licenses = response,
      error => console.log('error', error),
      () => {
        this.viewForm = this.licenses.length === 0;
        this.showStartButton = this.canStillApply();
        this.canApply = this.canStillApply();
        if (this.sharedService.userProfile.userId === null) {
          this.sharedService.getUserInfo().subscribe(
            response => this.sharedService.userProfile = response,
            error => console.log('error', error),
            () => {
              this.loadingServiceCenter = false;
              this.sharedService.toggleTheme();
              this.updateUserState();
            }
          );
        }
        else {
          this.loadingServiceCenter = false;
          this.updateUserState();
        }
      }
    );
  }

  getDocumentTypes(): void {
    this.licenseService.getDocumentTypes().subscribe(
      response => this.documentTypes = response,
      error => console.log('error', error),
      () => {
        this.lucsType = this.documentTypes.find(dt => dt.type === this.lucs).id
        this.barrierType = this.documentTypes.find(dt => dt.type === this.barrier).id
        this.premisesType = this.documentTypes.find(dt => dt.type === this.premisesSketch).id
        this.socialEquityType = this.documentTypes.find(dt => dt.type === this.socialEquityPlan).id;
        this.authorizedIndividualType = this.documentTypes.find(dt => dt.type === this.authorizedIndividual).id;
        this.reducedFeeType = this.documentTypes.find(dt => dt.type === this.reducedFee).id;
        this.premisePictureType = this.documentTypes.find(dt => dt.type === this.premisePicture).id;
        this.updatedEquityPlanType = this.documentTypes.find(dt => dt.type === this.updatedEquityPlan).id;
        this.equityPlanEvaluationType = this.documentTypes.find(dt => dt.type === this.equityPlanEvaluation).id;
        this.updatedPremisePictureType = this.documentTypes.find(dt => dt.type === this.updatedPremisePicture).id;

        this.documentTypeReference[this.lucsType] = this.lucs;
        this.documentTypeReference[this.barrierType] = this.barrier;
        this.documentTypeReference[this.premisesType] = this.premisesSketch;
        this.documentTypeReference[this.socialEquityType] = this.socialEquityPlan;
        this.documentTypeReference[this.authorizedIndividualType] = this.authorizedIndividual;
        this.documentTypeReference[this.reducedFeeType] = this.reducedFee;
        this.documentTypeReference[this.premisePictureType] = this.premisePicture;
        this.documentTypeReference[this.updatedEquityPlanType] = this.updatedEquityPlan;
        this.documentTypeReference[this.equityPlanEvaluationType] = this.equityPlanEvaluation;
        this.documentTypeReference[this.updatedPremisePictureType] = this.updatedPremisePicture;

        this.missingTypes[this.barrierType] = false;
        this.missingTypes[this.lucsType] = false;
        this.missingTypes[this.premisesType] = false;
        this.missingTypes[this.socialEquityType] = false;
        this.missingTypes[this.premisePictureType] = false;
      }
    );
  }

  getTrainingPrograms(): void {
    this.licenseService.getTrainingPrograms().subscribe(
      response => this.trainingPrograms = response,
      error => console.log('error', error),
      () => this.updateTrainingTable()
    );
  }

  canStillApply(): boolean {
    let applicableLicenses = this.licenses.filter(l => l.status !== this.sharedService.withdrawn &&
                                                       l.status !== this.sharedService.expired &&
                                                       l.status !== this.sharedService.denied &&
                                                       l.status !== this.sharedService.revoked &&
                                                       l.status !== this.sharedService.closed &&
                                                       l.status !== this.sharedService.surrendered &&
                                                       l.status !== this.sharedService.renewalSubmitted);
    return applicableLicenses.length < 5;
  }

  setupLoadingIndex(): void {
    this.licenses.forEach(license => {
      this.loadingIndex[license.id] = false;
    });
  }

  setupRouteSubscription(): void {
    this.routeSubscription = this.router.events
    .pipe(filter((event) => event instanceof NavigationStart))
    .subscribe((e) => {
      this.updateLicense();
    });
  }

  viewApplication(license: IServiceCenterLicense): void {
    this.loadingIndex[license.id] = true;
    this.license = license;
    this.licenseLocked = this.license.status !== this.sharedService.pending;
    if(this.licenseLocked){
        this.step = 0;
        this.licenseForm.disable();
        this.schoolForm.disable();
        this.documentsForm.disable();
        this.verifyForm.disable();
    }
    else{
      this.licenseForm.enable();
      this.schoolForm.enable();
      this.documentsForm.enable();
      this.verifyForm.enable();
      this.setupRouteSubscription();
    }

    this.updateLicenseForm();
    setTimeout(() => {
      this.loadingIndex[license.id] = false;
      this.viewForm = true;
      if(this.step === 8){
        this.verifyForm.markAsDirty();
        this.verifyForm.markAllAsTouched();
        this.licenseForm.markAsDirty();
        this.validateApplication();
      }
      setTimeout(() =>
      {
        this.updateTrainingTable();
        this.updatePracticumTable();
      }, 400);
    }, 100);
  }

  updatePracticumTable(): void {
    this.trainingSource.data = this.license.practicumSites;
    this.trainingSource.sort = this.trainingSort;
    this.trainingSource.paginator = this.trainingPaginator;
  }

  updateTrainingTable(): void {
    this.dataSource.data = this.trainingPrograms;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  closeApplication(): void {
    if(this.license.status == 'Pending' || this.license.status == this.sharedService.pendingRenewal){
      this.sharedService.openConfirm("Some of your changes may be lost. Continue?");
      this.sharedService.confirmed().subscribe(
        confirmed => {
          if (confirmed) {
            this.license = {
              id: 0,
              userId: '',
              licenseId: '',
              status: '',
              entityName: '',
              entityWebsite: '',
              registeredBusiness: null,
              businessInOregon: null,
              secretaryofStateNumber: '',
              registrationAttestation: false,
              registryNumber: '',
              registeredIn: '',
              financialAttestation: false,
              applicantAttestation: false,
              physicalState: 'OR',
              physicalCity: '',
              physicalStreet: '',
              physicalZip: '',
              physicalCounty: '',
              premisesDescription: '',
              mailingIsPhysical: false,
              mailingState: '',
              mailingCity: '',
              mailingStreet: '',
              mailingZip: '',
              mailingCounty: '',
              practicumSiteAttestation: false,
              schoolAttestation: false,
              schoolInProximity: false,
              primaryContactName: '',
              primaryContactNumber: '',
              primaryContactEmail: '',
              primaryContactId: '',
              language: '',
              previousLicense: false,
              affiliatedLicenses: '',
              canRenew: false,
              canOperate: false,
              reducedRequest: false,
              electronicSignature: '',
              attestation: false,
              directoryConsent: null,
              directoryConsentWebsite: false,
              directoryConsentEmail: false,
              directoryConsentPhone: false,
              directoryPhone: '',
              directoryEmail: '',
              directoryWebsite: '',
              applicationDate: '',
              approvedDate: '',
              renewedDate: '',
              expirationDate: '',
              entityApplicants: [],
              applicants: [],
              workerPermits: [],
              sB303WorkerPermits: [],
              licenseDocuments: [],
              payments: [],
              practicumSites: [],
              barrierPedestrianCrossing: '',
              barrierAttestation: false,
              barrierDescription: '',
              distanceFromSchool: '',
              timely: false,
              financialInterestCorrect: null,
              financialInterestCorrectReason: '',
              serviceCenterInformationCorrect: null,
              serviceCenterInformationCorrectReason: '',
              practicumSiteInformationCorrect: null,
              practicumSiteInformationCorrectReason: '',
              isRenewal: false,
              applicantsCorrect: false,
              applicantsCorrectReason: '',
              sB303Records: []
            }
            if (!this.licenseLocked) {
              this.routeSubscription.unsubscribe();
            }
            this.editingApplicant = false;
            this.editingEntityApplicant = false;
            this.viewForm = false;
            this.renewing = false;
          }
        });
    }
    else {
      this.license = {
        id: 0,
        userId: '',
        licenseId: '',
        status: '',
        entityName: '',
        entityWebsite: '',
        registeredBusiness: null,
        businessInOregon: null,
        secretaryofStateNumber: '',
        registrationAttestation: false,
        registryNumber: '',
        registeredIn: '',
        financialAttestation: false,
        applicantAttestation: false,
        physicalState: 'OR',
        physicalCity: '',
        physicalStreet: '',
        physicalZip: '',
        physicalCounty: '',
        premisesDescription: '',
        mailingIsPhysical: false,
        mailingState: '',
        mailingCity: '',
        mailingStreet: '',
        mailingZip: '',
        mailingCounty: '',
        practicumSiteAttestation: false,
        schoolAttestation: false,
        schoolInProximity: false,
        primaryContactName: '',
        primaryContactNumber: '',
        primaryContactEmail: '',
        primaryContactId: '',
        language: '',
        previousLicense: false,
        affiliatedLicenses: '',
        canRenew: false,
        canOperate: false,
        reducedRequest: false,
        electronicSignature: '',
        attestation: false,
        directoryConsent: null,
        directoryConsentWebsite: false,
        directoryConsentEmail: false,
        directoryConsentPhone: false,
        directoryPhone: '',
        directoryEmail: '',
        directoryWebsite: '',
        applicationDate: '',
        approvedDate: '',
        renewedDate: '',
        expirationDate: '',
        entityApplicants: [],
        applicants: [],
        workerPermits: [],
        sB303WorkerPermits: [],
        licenseDocuments: [],
        payments: [],
        practicumSites: [],
        barrierPedestrianCrossing: '',
        barrierAttestation: false,
        barrierDescription: '',
        distanceFromSchool: '',
        timely: false,
        financialInterestCorrect: null,
        financialInterestCorrectReason: '',
        serviceCenterInformationCorrect: null,
        serviceCenterInformationCorrectReason: '',
        practicumSiteInformationCorrect: null,
        practicumSiteInformationCorrectReason: '',
        isRenewal: false,
        applicantsCorrect: false,
        applicantsCorrectReason: '',
        sB303Records: []
      }
      if (!this.licenseLocked) {
        this.routeSubscription.unsubscribe();
      }
      this.editingApplicant = false;
      this.editingEntityApplicant = false;
      this.viewForm = false;
      this.renewing = false;
    }
  }

  addWorkerPermit(license: IServiceCenterLicense): void {
    const dialogRef = this.dialog.open(DialogAddPermitComponent, {
      data: license.workerPermits,
      role: 'dialog',
      ariaLabel: 'Add Worker Permits',
      minWidth: !this.sharedService.mobile ? '600px' : '400px',
      maxWidth: !this.sharedService.mobile ? '800px' : '600px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : "",
    });

    dialogRef.afterClosed().subscribe((response) => {
      if(!this.sharedService.isCancelled(response)){
        this.licenseService.addWorkerPermit(license.id, response).subscribe({
          next: response => license.workerPermits = [...license.workerPermits, ...response],
          error: e => console.log('error', e)
        });
      }
    });
  }

  removeWorkerPermit(license: IServiceCenterLicense, permitId: string): void {
    this.sharedService.openConfirm("Remove Worker Permit: " + permitId + "?");
    this.sharedService.confirmed().subscribe((confirmed) => {
      if(confirmed){
        this.licenseService.removeWorkerPermit(license.id, permitId).subscribe({
          complete: () => {
            license.workerPermits = license.workerPermits.filter(wp => wp.licenseId !== permitId);
          },
          error: e => console.log('error', e)
        });
      }
    });
  }

  addSB303WorkerPermit(license: IServiceCenterLicense): void {
    const dialogRef = this.dialog.open(DialogAddPermitSB303Component, {
      data: license.sB303WorkerPermits,
      role: 'dialog',
      ariaLabel: 'Add 303 Reporting Worker Permits',
      minWidth: !this.sharedService.mobile ? '600px' : '400px',
      maxWidth: !this.sharedService.mobile ? '800px' : '600px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : "",
    });

    dialogRef.afterClosed().subscribe((response) => {
      if(!this.sharedService.isCancelled(response)){
        this.licenseService.addSB303WorkerPermit(license.id, response).subscribe({
          next: response => license.sB303WorkerPermits = [...license.sB303WorkerPermits, ...response],
          error: e => console.log('error', e)
        });
      }
    });
  }

  removeSB303WorkerPermit(license: IServiceCenterLicense, permitId: string): void {
    this.sharedService.openConfirm("Remove Worker Permit: " + permitId + "?");
    this.sharedService.confirmed().subscribe((confirmed) => {
      if(confirmed){
        this.licenseService.removeSB303WorkerPermit(license.id, permitId).subscribe({
          complete: () => {
            license.sB303WorkerPermits = license.sB303WorkerPermits.filter(wp => wp.licenseId !== permitId);
          },
          error: e => console.log('error', e)
        });
      }
    });
  }

  requestWithdraw(license: IServiceCenterLicense): void{
    this.sharedService.openConfirm("Are you sure you want to request to withdraw your application?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.licenseService.requestWithdraw(license.id).subscribe(
            response => {
              let index = this.licenses.findIndex(fl => fl.id == license.id);
              this.licenses[index] = response;
            },
            error => console.log('error', error),
          )
    }});
  }

  requestSurrender(license: IServiceCenterLicense): void{
    this.sharedService.openConfirm("Are you sure you want to request to surrender your application?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.licenseService.requestSurrender(license.id).subscribe(
            response => {
              let index = this.licenses.findIndex(fl => fl.id == license.id);
              this.licenses[index] = response;
            },
            error => console.log('error', error),
          )
    }});
  }

  startApplication(): void {
    this.showStartButton = false;
    this.sharedService.openConfirm("Would you like to begin a service center license application?");
    this.sharedService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.licenseService.createLicense().subscribe(
          response => this.license = response,
          error => console.log('error', error),
          () => {
            this.setupRouteSubscription();
            this.step = 0;
            this.loadingIndex[this.license.id] = false;
            this.licenses.push(this.license);
            this.updateLicenseForm();
            this.viewForm = true;
            this.showStartButton = this.canStillApply();
            this.canApply = this.canStillApply();
          }
        );
      }
      else{
        this.showStartButton = true;
      }
    });
  }

  updateLicenseForm(): void {
    if(this.license.businessInOregon || this.license.businessInOregon === false){
      this.licenseForm.patchValue({
        businessInOregon: this.license.businessInOregon,
      });
    }
    if(this.license.schoolInProximity === false){
      this.licenseForm.patchValue({
        noSchoolInProximity: true
      });
    }

    if(this.license.directoryWebsite == '' && this.license.entityWebsite != '')
      this.verifyForm.patchValue({
        directoryWebsite: this.license.entityWebsite,
      });
    else
      this.verifyForm.patchValue({
        directoryWebsite: this.license.directoryWebsite,
      });

    this.licenseForm.patchValue({
      entityName: this.license.entityName,
      entityWebsite: this.license.entityWebsite,
      secretaryofStateNumber: this.license.secretaryofStateNumber,
      registeredBusiness: this.license.registeredBusiness,
      registeredIn: this.license.registeredIn,
      registryNumber: this.license.registryNumber,
      registrationAttestation: this.license.registrationAttestation,
      financialAttestation: this.license.financialAttestation,
      applicantFeeAttestation: this.license.applicantAttestation,
      applicantDenialAttestation: this.license.applicantAttestation,
      physicalCity: this.license.physicalCity,
      physicalStreet: this.license.physicalStreet,
      physicalZip: this.license.physicalZip,
      physicalCounty: this.license.physicalCounty,
      premisesDescription: this.license.premisesDescription,
      mailingIsPhysical: this.license.mailingIsPhysical,
      mailingState: this.license.mailingState,
      mailingCity: this.license.mailingCity,
      mailingStreet: this.license.mailingStreet,
      mailingZip: this.license.mailingZip,
      mailingCounty: this.license.mailingCounty,
      practicumSiteAttestation: this.license.practicumSiteAttestation,
      schoolAttestation: this.license.schoolAttestation,
      schoolInProximity: this.license.schoolInProximity,
      barrierPedestrianCrossing: this.license.barrierPedestrianCrossing,
      barrierAttestation: this.license.barrierAttestation,
      barrierDescription: this.license.barrierDescription,
      distanceFromSchool: this.license.distanceFromSchool,
      primaryContactName: this.license.primaryContactName,
      primaryContactNumber: this.license.primaryContactNumber,
      primaryContactEmail: this.license.primaryContactEmail,
      primaryContactId: this.license.primaryContactId,
      language: this.license.language,
      previousLicense: this.license.previousLicense,
      affiliatedLicenses: this.license.affiliatedLicenses,
      applicantsCorrect: this.license.applicantsCorrect,
      applicantsCorrectReason: this.license.applicantsCorrectReason,
      financialInterestCorrect: this.license.financialInterestCorrect,
      financialInterestCorrectReason: this.license.financialInterestCorrectReason,
      serviceCenterInformationCorrect: this.license.serviceCenterInformationCorrect,
      serviceCenterInformationCorrectReason: this.license.serviceCenterInformationCorrectReason,
    });

    this.practicumForm.patchValue({
      practicumSiteInformationCorrect: this.license.practicumSiteInformationCorrect,
      practicumSiteInformationCorrectReason: this.license.practicumSiteInformationCorrectReason,
    });

    this.verifyForm.patchValue({
      electronicSignature: this.license.electronicSignature,
      attestation: this.license.attestation,
      directoryConsent: this.license.directoryConsent,
      directoryConsentWebsite: this.license.directoryConsentWebsite,
      directoryConsentEmail: this.license.directoryConsentEmail,
      directoryConsentPhone: this.license.directoryConsentPhone,
      directoryPhone: this.license.directoryPhone,
      directoryEmail: this.license.directoryEmail,
    });
  }

  updateLicense(): void {
    if(this.license.directoryWebsite == '' && this.license.entityWebsite != '')
    this.verifyForm.patchValue({
      directoryWebsite: this.license.entityWebsite,
    });
  else
    this.verifyForm.patchValue({
      directoryWebsite: this.license.directoryWebsite,
    });
    let form = this.licenseForm.value;
    let practicumForm = this.practicumForm.value;
    if(!this.renewing)
    {
      this.license.entityName = form.entityName;
      this.license.secretaryofStateNumber = form.secretaryofStateNumber;
      this.license.registeredIn = form.registeredIn;
      this.license.registryNumber = form.registryNumber;
      this.license.premisesDescription = form.premisesDescription;
      this.license.physicalState = 'OR';
      this.license.physicalCity = form.physicalCity;
      this.license.physicalStreet = form.physicalStreet;
      this.license.physicalZip = form.physicalZip;
      this.license.physicalCounty = form.physicalCounty;
    }

    this.license.entityWebsite = form.entityWebsite;
    this.license.businessInOregon = form.businessInOregon;
    this.license.registrationAttestation = form.registrationAttestation;

    this.license.registeredBusiness = form.registeredBusiness;
    this.license.financialAttestation = form.financialAttestation;
    this.license.practicumSiteAttestation = form.practicumSiteAttestation;
    this.license.applicantAttestation = form.applicantDenialAttestation && form.applicantFeeAttestation;
    this.license.schoolAttestation = form.schoolAttestation;
    this.license.schoolInProximity = form.schoolInProximity;
    this.license.barrierPedestrianCrossing = form.barrierPedestrianCrossing;
    this.license.barrierAttestation = form.barrierAttestation;
    this.license.barrierDescription = form.barrierDescription;
    this.license.distanceFromSchool = form.distanceFromSchool;
    this.license.applicantsCorrect = form.applicantsCorrect;
    this.license.applicantsCorrectReason = form.applicantsCorrectReason;
    this.license.financialInterestCorrect = form.financialInterestCorrect;
    this.license.financialInterestCorrectReason = form.financialInterestCorrectReason;
    this.license.serviceCenterInformationCorrect = form.serviceCenterInformationCorrect;
    this.license.serviceCenterInformationCorrectReason = form.serviceCenterInformationCorrectReason;
    this.license.practicumSiteInformationCorrect = practicumForm.practicumSiteInformationCorrect;
    this.license.practicumSiteInformationCorrectReason = practicumForm.practicumSiteInformationCorrectReason;
    this.license.mailingIsPhysical = form.mailingIsPhysical;
    if(form.mailingIsPhysical){
      this.license.mailingState = this.license.physicalState;
      this.license.mailingCity = form.physicalCity;
      this.license.mailingStreet = form.physicalStreet;
      this.license.mailingZip = form.physicalZip;
      this.license.mailingCounty = form.physicalCounty;
    }
    else{
      this.license.mailingState = form.mailingState;
      this.license.mailingCity = form.mailingCity;
      this.license.mailingStreet = form.mailingStreet;
      this.license.mailingZip = form.mailingZip;
      this.license.mailingCounty = form.mailingCounty;
    }
    this.license.primaryContactName = form.primaryContactName;
    this.license.primaryContactNumber = form.primaryContactNumber;
    this.license.primaryContactEmail = form.primaryContactEmail;
    this.license.primaryContactId = form.primaryContactId;
    this.license.language = form.language;
    this.license.previousLicense = form.previousLicense;
    this.license.affiliatedLicenses = form.affiliatedLicenses;
    this.licenseService.updateLicense(this.license);
  }

  updateUserState(): void {
    if(this.licenses.length == 0)
    {
      this.sharedService.userProfile.currentPage = "license/testing-lab";
      this.sharedService.userProfile.currentStep = "gettingStarted";
      this.sharedService.updateUserProfileState();
    }
    if (this.licenseSteps.includes(this.sharedService.userProfile.currentStep)) {
      this.step = this.stepsIndex[this.sharedService.userProfile.currentStep];
    }
    if(this.step === 8 && this.license.status === this.sharedService.pending){
      this.financialAttestationValid = this.licenseForm.get('financialAttestation').value;

      this.applicantAttestationValid = this.licenseForm.get('applicantFeeAttestation').value &&
                                       this.licenseForm.get('applicantDenialAttestation').value;
      this.licenseForm.markAsDirty();
      this.licenseForm.markAllAsTouched();
      this.validateApplication();
    }
    if (this.sharedService.userProfile.currentPage !== "license/service-center") {
      this.sharedService.userProfile.currentPage = "license/service-center";
      this.sharedService.updateUserProfileState();
    }
  }

  changeStep(step: StepperSelectionEvent): void {
    if(!this.licenseLocked){
      if (step.previouslySelectedIndex === 1) {
        this.licenseForm.markAsDirty();
        this.licenseForm.markAllAsTouched();
        this.updateLicense();
        this.validateServiceCenterInformationCorrect();
        this.validateLicenseDocuments();
      }
      if(step.previouslySelectedIndex === 2){
        this.validateApplicants();
        this.validateEntityApplicants();
        this.validatePrimaryContact();
        this.updateLicense();
        if(!this.renewing)
          this.applicantAttestationValid = this.licenseForm.get('applicantFeeAttestation').value &&
                                          this.licenseForm.get('applicantDenialAttestation').value;
        else
        this.applicantAttestationValid = this.licenseForm.get('applicantFeeAttestation').value &&
          this.licenseForm.get('applicantDenialAttestation').value && (this.licenseForm.get('applicantsCorrect').value == true ||
          (this.licenseForm.get('applicantsCorrect').value == false && (this.licenseForm.get('applicantsCorrectReason').value !== '' && this.licenseForm.get('applicantsCorrectReason').value !== null)));
      }
      if (step.previouslySelectedIndex === 3) {
        this.updateLicense();
        if(!this.renewing)
          this.financialAttestationValid = this.licenseForm.get('financialAttestation').value;
        if(this.renewing)
        this.financialAttestationValid = this.licenseForm.get('financialAttestation').value && (this.licenseForm.get('financialInterestCorrect').value || ((this.licenseForm.get('financialInterestCorrectReason').value !== '' && this.licenseForm.get('financialInterestCorrectReason').value !== null) && this.licenseForm.get('financialInterestCorrect').value == false));
      }
      if (step.previouslySelectedIndex === 4 && !this.renewing) {
        this.updateLicense();
        this.validateSchoolProximity();
      }
      if (step.previouslySelectedIndex === 4 && this.renewing) {
        this.updateLicense();
        this.validatePracticumSite();
        this.validatePracticumSiteInformationCorrect();
      }
      if (step.previouslySelectedIndex === 5 && !this.renewing) {
        this.updateLicense();
        this.validatePracticumSite();
      }
      if (step.previouslySelectedIndex === 5 && this.renewing) {
        this.validateLicenseDocuments();
      }
      if (step.previouslySelectedIndex === 6 && !this.renewing) {
        this.validateLicenseDocuments();
      }
      if (step.previouslySelectedIndex === 7 && this.renewing) {
        this.financialAttestationValid = this.licenseForm.get('financialAttestation').value && (this.licenseForm.get('financialInterestCorrect').value || ((this.licenseForm.get('financialInterestCorrectReason').value !== '' && this.licenseForm.get('financialInterestCorrectReason').value !== null) && this.licenseForm.get('financialInterestCorrect').value == false));

        this.applicantAttestationValid = this.licenseForm.get('applicantFeeAttestation').value &&
          this.licenseForm.get('applicantDenialAttestation').value && (this.licenseForm.get('applicantsCorrect').value == true ||
          (this.licenseForm.get('applicantsCorrect').value == false && (this.licenseForm.get('applicantsCorrectReason').value !== '' && this.licenseForm.get('applicantsCorrectReason').value !== null)));

        this.licenseForm.markAsDirty();
        this.licenseForm.markAllAsTouched();
        this.validateApplication();
      }
      if (step.selectedIndex === 8 && !this.renewing){
        this.financialAttestationValid = this.licenseForm.get('financialAttestation').value;

        this.applicantAttestationValid = this.licenseForm.get('applicantFeeAttestation').value &&
                                        this.licenseForm.get('applicantDenialAttestation').value;

        this.licenseForm.markAsDirty();
        this.licenseForm.markAllAsTouched();
        this.validateApplication();
      }
    }
    this.sharedService.userProfile.currentStep = this.licenseSteps[step.selectedIndex];
    this.sharedService.updateUserProfileState();
  }

  changeAttestation(found: boolean): void {
    if (found) {
      this.licenseForm.patchValue({
        noSchoolInProximity: false
      });
    }
    if (!found) {
      this.licenseForm.patchValue({
        schoolInProximity: false
      });
    }
  }

  addTrainingProgram(trainingProgramId: number): void {
    this.licenseService.addTrainingProgram(this.license.id, trainingProgramId).subscribe(
      response => {
        if(response){
          this.license.practicumSites.push(this.trainingPrograms.find(ia => ia.id === trainingProgramId));
        }
      },
      error => console.log('error', error),
      () => this.updatePracticumTable()
    );
  }

  deleteTrainingProgram(trainingProgramId: number): void {
    this.sharedService.openConfirm('Remove Training Program?');
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.licenseService.deleteTrainingProgram(this.license.id, trainingProgramId).subscribe(
            response => {
              if(response){
                this.license.practicumSites = this.license.practicumSites.filter(a => a.id !== trainingProgramId);
              }
            },
            error => console.log('error', error),
            () => this.updatePracticumTable()
          );
        }
      }
    );
  }

  setPointOfContact(applicant: IApplicant): void {
    this.licenseForm.patchValue({
      primaryContactName: applicant.legalFirstName + ' ' + applicant.legalLastName,
      primaryContactNumber: applicant.phone,
      primaryContactEmail: applicant.email,
      primaryContactId: applicant.applicantId,
      language: applicant.language,
    });
    this.updateLicense();
    this.validatePrimaryContact();
  }

  editApplicant(editing: boolean): void {
    this.editingApplicant = editing;
  }

  editEntityApplicant(editing: boolean): void {
    this.editingEntityApplicant = editing;
  }

  hasPaidFee(license: IServiceCenterLicense, paymentType: string): boolean {
    if(license == null || license.payments == null || license.payments.length == 0) {
      return false;
    }

    let appFee = license.payments.find(tp => tp.paid && tp.type == paymentType);

    if(paymentType == 'LicenseFee')
      {
         appFee = license.payments.find(tp => tp.paid && (tp.type == 'LicenseFee' || tp.type == 'ReducedLicenseFee'));
      }
    if(appFee == null || appFee == undefined) {
      return false;
    }

    return true;
  }

  getPaymentFeeStatus(license: IServiceCenterLicense, paymentType: string) : string {
    if(license == null || license.payments == null || license.payments.length == 0) {
      return "";
    }

    let appFee = license.payments.find(tp => tp.type == paymentType);

    if(appFee == null || appFee == undefined) {
      return "";
    }

    return appFee.status;
  }

  getFeeCleared(license: IServiceCenterLicense, paymentType: string) : string {
    if(license == null || license.payments == null || license.payments.length == 0) {
      return "";
    }

    let appFee = license.payments.find(tp => tp.type == paymentType);

    if(appFee == null || appFee == undefined) {
      return "";
    }

    return appFee.paymentCleared;
  }

  uploadBarrierDocument(event: Event): void {
    let types: number[] = [];
      types.push(this.barrierType);

      let dirtyFile = (event.target as HTMLInputElement).files[0];
      let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, ''));
      if(this.sharedService.validateFile(file))
      {
        let upload: IDocument = {
          id: 0,
          name: file.name,
          comments: "",
          extenstion: "",
          dateCreated: "",
          dateLastUpdated: "",
          createdBy: "",
          lastUpdatedBy: "",
          parentId: this.license.id,
          types: types,
          adminOnly: false,
          deprecated: false
        }

        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("document", JSON.stringify(upload));
        this.licenseService.uploadDocument(formData).subscribe(
          response => this.license.licenseDocuments.push(response),
          error => {
            (event.target as HTMLInputElement).value = '';
            console.log('error', error);
          },
          () => {
            (event.target as HTMLInputElement).value = '';
            this.validateSchoolProximity();
          });
      }
      else
      this.toastr.error("Unsupported File Type")
    }

  uploadLicenseDocument(event: Event): void {
    let documentForm = this.documentsForm.value;
    let types: number[] = [];

    if (documentForm.lucs) {
      types.push(this.lucsType);
    }
    if (documentForm.premisesSketch){
      types.push(this.premisesType);
    }
    if (documentForm.socialEquityPlan) {
      types.push(this.socialEquityType);
    }
    if (documentForm.reducedFee) {
      types.push(this.reducedFeeType);
    }
    if(documentForm.premisePicture) {
      types.push(this.premisePictureType);
    }
    if (documentForm.updatedEquityPlan) {
      types.push(this.updatedEquityPlanType);
    }
    if (documentForm.equityPlanEvaluation) {
      types.push(this.equityPlanEvaluationType);
    }
    if (documentForm.updatedPremisePicture) {
      types.push(this.updatedPremisePictureType);
    }

    types.forEach(type => {
      this.missingTypes[type] = false;
    });

    if(types.length > 0){
      let dirtyFile = (event.target as HTMLInputElement).files[0];
      let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, ''));

      if(this.sharedService.validateFile(file))
      {
        let upload: IDocument = {
          id: 0,
          name: file.name,
          comments: "",
          extenstion: "",
          dateCreated: "",
          dateLastUpdated: "",
          createdBy: "",
          lastUpdatedBy: "",
          parentId: this.license.id,
          types: types,
          adminOnly: false,
          deprecated: false
        }

        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("document", JSON.stringify(upload));
        this.licenseService.uploadDocument(formData).subscribe(
          response => this.license.licenseDocuments.push(response),
          error => {
            (event.target as HTMLInputElement).value = '';
            console.log('error', error);
          },
          () => {
            (event.target as HTMLInputElement).value = '';
            this.validateLicenseDocuments();
            this.documentsForm.patchValue({
              lucs: false,
              premisesSketch: false,
              socialEquityPlan: false,
              reducedFee: false,
              premisePicture: false,
              updatedEquityPlan: false,
              equityPlanEvaluation: false,
              updatedPremisePicture: false
            });
          });
      }
      else
        this.toastr.error("Unsupported File Type");
    }
    else{
      (event.target as HTMLInputElement).value = '';
      this.toastr.error("Please select at least one requirement met by the document");
    }
  }

  downloadDocument(fileId: number, fileName: string): void {
    this.licenseService.downloadFile(fileId).subscribe(
      (response) => this.saveFile(fileName, response),
      (error) => console.log("error", error)
    );
  }

  saveFile(fileName: string, blob: Blob) {
    let file = URL.createObjectURL(blob);
    var fileDownload = document.createElement("a");
    fileDownload.href = file;
    fileDownload.download = fileName;
    fileDownload.click();
  }

  deleteFile(id: number, name: string) {
    this.sharedService.openConfirm("Delete " + name + "?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.licenseService.deleteDocument(id).subscribe(
            () => {
              this.license.licenseDocuments = this.license.licenseDocuments.filter(item => item.id !== id);
              this.validateLicenseDocuments();
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  validateLicenseDocuments(): void {
    this.documentsValid = true;
    let requiredTypes: number[];
    if(!this.renewing)
      requiredTypes = [
        this.lucsType,
        this.premisesType,
        this.socialEquityType,
        this.premisePictureType
      ];
    if(this.renewing)
      requiredTypes = [
        this.equityPlanEvaluationType
      ];
    let types: number[] = [];
    this.license.licenseDocuments.forEach(ld => ld.types.forEach(t => types.push(t)));

    requiredTypes.forEach(type => {
      if(!types.includes(type)){
        this.missingTypes[type] = true;
        this.documentsValid = false;
      }
    });
  }

  validateApplicants(): void{
    if(this.individualApplicants?.applicants.length === 0){
      this.applicantsValid = false;
    }
    else{
      this.applicantsValid = true;
      this.individualApplicants?.applicants.forEach(applicant => {
        if(!applicant.complete){
          this.applicantsValid = false;
        }
      });
    }
  }

  validateEntityApplicants(): void{
    if(this.entityApplicants?.applicants.length === 0){
      this.entityApplicantsValid = true;
    }
    else{
      this.entityApplicantsValid = true;
      this.entityApplicants?.applicants.forEach(applicant => {
        if(!applicant.complete){
          this.entityApplicantsValid = false;
        }
      });
    }
  }

  validatePrimaryContact(): void {
    if (this.licenseForm.get('primaryContactName').value !== '' &&
      this.licenseForm.get('primaryContactNumber').value !== '' &&
      this.licenseForm.get('primaryContactEmail').value !== '') {
      this.contactValid = true;
    }
    else {
      this.contactValid = false;
    }
  }

  validateSchoolProximity(): void{
    let form = this.licenseForm.value;
    this.schoolAttestationValid = form.schoolAttestation;
    this.barrierAttestationValid = form.noSchoolInProximity || form.barrierAttestation;
    this.schoolProximityValid = (form.schoolInProximity || form.noSchoolInProximity);
    if(form.noSchoolInProximity)
    {
      this.barrierQuestionsValid = true;
      this.barrierDocumentsValid = true;
    }
    if(form.schoolInProximity)
    {
      if(this.license.licenseDocuments.length == 0)
        this.barrierDocumentsValid = false;
      else
      {
        if(this.license.licenseDocuments.find(d => d.types.includes(20)) != null)
          this.barrierDocumentsValid = true;
        else
          this.barrierDocumentsValid = false;
      }
      if(form.distanceFromSchool == '' || form.barrierDescription == '' || form.barrierPedestrianCrossing == '')
      {
        this.barrierQuestionsValid = false;
      }
      else {
        this.barrierQuestionsValid = true;
      }
    }

  }

  validatePracticumSite(): void {
    let form = this.licenseForm.value;
    this.practicumValid = form.practicumSiteAttestation;

    if(this.renewing){
      this.practicumValid = this.licenseForm.get('practicumSiteAttestation').value && (this.practicumForm.get('practicumSiteInformationCorrect').value ||
      ((this.practicumForm.get('practicumSiteInformationCorrectReason').value !== '' && this.practicumForm.get('practicumSiteInformationCorrectReason').value !== null && this.practicumForm.get('practicumSiteInformationCorrectReason').value !== undefined) && this.practicumForm.get('practicumSiteInformationCorrect').value == false));
    }
  }

  validateApplication(): void{
    this.validateLicenseDocuments();
    this.validateApplicants();
    this.validateEntityApplicants();
    this.validatePrimaryContact();
    this.validateSchoolProximity();
    this.validatePracticumSite();
  }

  applicationValid(): boolean {
    this.validateDirectoryConsentWebsite();
    this.validatePracticumSite();
    if (this.renewing) {
      this.financialAttestationValid = this.licenseForm.get('financialAttestation').value && (this.licenseForm.get('financialInterestCorrect').value || ((this.licenseForm.get('financialInterestCorrectReason').value !== '' && this.licenseForm.get('financialInterestCorrectReason').value !== null) && this.licenseForm.get('financialInterestCorrect').value == false));

      this.applicantAttestationValid = this.licenseForm.get('applicantFeeAttestation').value &&
        this.licenseForm.get('applicantDenialAttestation').value && (this.licenseForm.get('applicantsCorrect').value == true ||
        (this.licenseForm.get('applicantsCorrect').value == false && (this.licenseForm.get('applicantsCorrectReason').value !== '' && this.licenseForm.get('applicantsCorrectReason').value !== null)));
    }

    return (
      this.documentsValid &&
      this.applicantsValid &&
      this.entityApplicantsValid &&
      this.financialAttestationValid &&
      this.applicantAttestationValid &&
      this.schoolAttestationValid &&
      this.barrierQuestionsValid &&
      this.contactValid &&
      this.schoolProximityValid &&
      this.barrierAttestationValid &&
      this.barrierDocumentsValid &&
      this.practicumValid &&
      this.licenseForm.valid
    );
  }

  submitApplication(): void {
    this.license.attestation = true;
    this.license.electronicSignature = this.verifyForm.get('electronicSignature').value;
    this.license.directoryConsent = this.verifyForm.get('directoryConsent').value;
    this.license.directoryConsentWebsite = this.verifyForm.get('directoryConsentWebsite').value;
    this.license.directoryConsentEmail = this.verifyForm.get('directoryConsentEmail').value;
    this.license.directoryConsentPhone = this.verifyForm.get('directoryConsentPhone').value;
    this.license.directoryPhone = this.verifyForm.get('directoryPhone').value;
    this.license.directoryEmail = this.verifyForm.get('directoryEmail').value;
    this.license.directoryWebsite = this.verifyForm.get('directoryWebsite').value;
    this.licenseService.submitApplication(this.license, this.renewing).subscribe(
      response => this.license = response,
      error => console.log('error', error),
      () => {
        this.routeSubscription.unsubscribe();
        let licenseIndex = this.licenses.findIndex(fl => fl.id === this.license.id);
        this.licenses[licenseIndex] = this.license;
        this.showSubmitted = true;
      }
    );
  }

  reopenApplication(license: IServiceCenterLicense): void {
    if(this.canStillApply()){
      this.sharedService.openConfirm("Re-open this application?");
      this.sharedService.confirmed().subscribe(
        confirmed => {
          if(confirmed){
            license.status = this.sharedService.pending;
            this.licenseService.reopenApplication(license).subscribe(
              response => this.license = response,
              error => console.log('error', error),
              () => {
                let licenseIndex = this.licenses.findIndex(fl => fl.id === this.license.id);
                this.licenses[licenseIndex] = this.license;
                this.viewApplication(this.license);
              }
            );
          }
        }
      );
    }
  }

  continue(): void {
    this.renewing = false;
    this.viewForm = false;
    this.showSubmitted = false;
  }

  withdrawApplication(license: IServiceCenterLicense): void {
    this.sharedService.openConfirm("Withdraw this application?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.licenseService.withdrawApplication(license).subscribe(
            response => license = response,
            error => console.log('error', error),
            () => {
              let licenseIndex = this.licenses.findIndex(fl => fl.id === license.id);
              this.licenses[licenseIndex] = license;
            }
          );
        }
      }
    );
  }

  viewPrintScreen(license: IServiceCenterLicense): void {
    if (license[0] != null) {
      this.dialog.open(ServiceCenterDetailsDialog, {
        data: license[0],
        maxWidth: !this.sharedService.mobile ? '900px' : '800px',
        maxHeight: this.sharedService.mobile ? '750px' : '900px',
        autoFocus: false,
        panelClass: this.sharedService.userProfile.theme === "dark" ? "theme-dark" : "",
      });
    }
    else {
      this.dialog.open(ServiceCenterDetailsDialog, {
        data: license,
        maxWidth: !this.sharedService.mobile ? '900px' : '800px',
        maxHeight: this.sharedService.mobile ? '750px' : '900px',
        autoFocus: false,
        panelClass: this.sharedService.userProfile.theme === "dark" ? "theme-dark" : "",
      });
    }
  }

  changeDirectory(license: IServiceCenterLicense): void {
    if (license.id > 0) {
      const dialogRef = this.dialog.open(DialogChangeDirectoryComponent, {
        data: {license: license, type: 'ServiceCenter'},
        maxWidth: !this.sharedService.mobile ? '900px' : '800px',
        maxHeight: this.sharedService.mobile ? '750px' : '900px',
        autoFocus: false,
        panelClass: this.sharedService.userProfile.theme === "dark" ? "theme-dark" : "",
      });
      dialogRef.afterClosed().subscribe((response) => {
        if(response) {
        license.directoryConsent = response.directoryConsent;
        license.directoryConsentWebsite = response.directoryConsentWebsite;
        license.directoryConsentEmail = response.directoryConsentEmail;
        license.directoryConsentPhone = response.directoryConsentPhone;
        license.directoryPhone = response.directoryPhone;
        license.directoryEmail = response.directoryEmail;
        license.directoryWebsite= response.directoryWebsite;
        }

      });
    }
  }

  licenseHasUnpaidFees(license : IServiceCenterLicense) : boolean{
    if(license != null && license.payments != null && license.payments.length > 0) {
       if(license.payments.filter(p => !p.paid).length > 0) {
         return true;
       }
    }
    return false;
  }

  //Start of Custom Validators
  showRequiredApplicantDataAttestation(applicationDate: string): boolean {
    if (!applicationDate) {
      return true;
    }
    const parsedDate = new Date(applicationDate);
    const comparisonDate = new Date(2025, 0, 2);
    return parsedDate > comparisonDate;
  }

  showOregonResidency(applicationDate: string): boolean {
    const currentDate = new Date();
    const comparisonDate = new Date(2025, 0, 2);
    const parsedDate = new Date(applicationDate);

    if(applicationDate && parsedDate > comparisonDate)
    {
      return false;
    }

    if (currentDate < comparisonDate) {
      return true;
    }

    if (!applicationDate) {
      return false;
    }
    return parsedDate < comparisonDate;
  }

  previousLicenseValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasPreviousLicense = control.value.previousLicense;
      const affiliatedLicenses = control.value.affiliatedLicenses;
      if(!hasPreviousLicense){
        return null;
      }
      return (affiliatedLicenses !== null && affiliatedLicenses !== '' && affiliatedLicenses !== undefined) ? null : { licensesRequired: true };
    }
  }

  validatePreviousLicense(): boolean {
    if (this.licenseForm.hasError('licensesRequired') && this.licenseForm.get('affiliatedLicenses').touched) {
      return true;
    }
    return false;
  }

  businessInOregonValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isOregonBusiness = control.value.businessInOregon;

      if(isOregonBusiness != null){
        return null;
      }
      if(this.renewing)
        return null;
      return { businessInOregonRequired: true };
    }
  }

  validateBusinessInOregon(): boolean {
    if (this.licenseForm.hasError('businessInOregonRequired') && this.licenseForm.get('businessInOregon').touched) {
      return true;
    }
    return false;
  }

  businessInOtherStateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isOregonBusiness = control.value.businessInOregon;
      const otherState = control.value.registeredBusiness;
      if(isOregonBusiness){
        return null;
      }
      if(this.renewing)
        return null;
      return (otherState != null) ? null : { otherBusinessRequired: true };
    }
  }

  validateBusinessInOtherState(): boolean {
    if (this.licenseForm.hasError('otherBusinessRequired') && this.licenseForm.get('registeredBusiness').touched) {
      return true;
    }
    return false;
  }

  secretaryOfStateNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isOregonBusiness = control.value.businessInOregon;
      const secretaryofStateNumber = control.value.secretaryofStateNumber;
      if(!isOregonBusiness){
        return null;
      }
      if(this.renewing)
        return null;
      return (secretaryofStateNumber != null && secretaryofStateNumber != '' && secretaryofStateNumber != undefined) ? null : { secretaryofStateNumberRequired: true };
    }
  }

  validateSecretaryOfStateNumber(): boolean {
    if (this.licenseForm.hasError('secretaryofStateNumberRequired') && this.licenseForm.get('secretaryofStateNumber').touched) {
      return true;
    }
    return false;
  }

  registeredLocationValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isOtherBusiness = control.value.registeredBusiness;
      const registeredLocation = control.value.registeredIn;
      const isOregonBusiness = control.value.businessInOregon;
      if(!isOtherBusiness || isOregonBusiness){
        return null;
      }
      if(this.renewing)
        return null;
      return (registeredLocation !== null && registeredLocation !== '' && registeredLocation !== undefined) ? null : { registeredLocationRequired: true };
    }
  }

  validateRegisteredLocation(): boolean {
    if (this.licenseForm.hasError('registeredLocationRequired') && this.licenseForm.get('registeredIn').touched) {
      return true;
    }
    return false;
  }

  registryNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isOtherBusiness = control.value.registeredBusiness;
      const registryNumber = control.value.registryNumber;
      const isOregonBusiness = control.value.businessInOregon;
      if(!isOtherBusiness || isOregonBusiness){
        return null;
      }
      if(this.renewing)
        return null;
      return (registryNumber !== null && registryNumber !== '' && registryNumber !== undefined) ? null : { registryNumberRequired: true };
    }
  }

  validateRegistryNumber(): boolean {
    if (this.licenseForm.hasError('registryNumberRequired') && this.licenseForm.get('registryNumber').touched) {
      return true;
    }
    return false;
  }

  serviceCenterInformationCorrectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const pageCorrect = control.value.serviceCenterInformationCorrect;
      const reason = control.value.serviceCenterInformationCorrectReason;
      if (!pageCorrect && this.license.isRenewal) {
        return (reason !== null && reason !== '' && reason !== undefined) ? null : { serviceCenterInformationCorrectReasonRequired: true };
      }
      return null;
    };
  }

  validateServiceCenterInformationCorrect(): boolean {
    if (this.licenseForm.hasError('serviceCenterInformationCorrectReasonRequired') && this.licenseForm.get('serviceCenterInformationCorrect').value === false) {
      this.licenseForm.get('serviceCenterInformationCorrectReason').setErrors(['required']);
      return true;
    }
    this.licenseForm.get('serviceCenterInformationCorrectReason').clearValidators();
    this.licenseForm.get('serviceCenterInformationCorrectReason').updateValueAndValidity();
    return false;
  }

  practicumSiteInformationCorrectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const pageCorrect = control.value.practicumSiteInformationCorrect;
      const reason = control.value.practicumSiteInformationCorrectReason;
      if (!pageCorrect && this.license.isRenewal) {
        return (reason !== null && reason !== '' && reason !== undefined) ? null : { practicumSiteInformationCorrectReasonRequired: true };
      }
      return null;
    };
  }

  validatePracticumSiteInformationCorrect(): boolean {
    if (this.practicumForm.hasError('practicumSiteInformationCorrectReasonRequired') && this.practicumForm.get('practicumSiteInformationCorrect').value === false) {
      this.practicumForm.get('practicumSiteInformationCorrectReason').setErrors(['required']);
      return true;
    }
    this.practicumForm.get('practicumSiteInformationCorrectReason').clearValidators();
    this.practicumForm.get('practicumSiteInformationCorrectReason').updateValueAndValidity();
    return false;
  }

  validateFinancialInterestCorrect(): boolean {
    return (this.licenseForm.get('financialInterestCorrect').value || ((this.licenseForm.get('financialInterestCorrectReason').value !== '' && this.licenseForm.get('financialInterestCorrectReason').value !== null) && this.licenseForm.get('financialInterestCorrect').value == false));
  }

  mailingIsSame(): void {
    this.licenseForm.get('mailingStreet').updateValueAndValidity();
    this.licenseForm.get('mailingCity').updateValueAndValidity();
    this.licenseForm.get('mailingState').updateValueAndValidity();
    this.licenseForm.get('mailingZip').updateValueAndValidity();
  }

  validateMailingAddress(): void {
    this.validateMailingAddress();
    this.validateMailingCity();
    this.validateMailingStreet();
    this.validateMailingZip();
  }

  mailingStreetValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const street = control.value.mailingStreet;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (street !== null && street !== '' && street !== undefined) ? null : { mailingStreetRequired: true };
      }
      return null;
    };
  }

  validateMailingStreet(): boolean {
    if (this.licenseForm.hasError('mailingStreetRequired') && this.licenseForm.get('mailingStreet').touched) {
      this.licenseForm.get('mailingStreet').setErrors(['required']);
      return true;
    }
    this.licenseForm.get('mailingStreet').clearValidators();
    this.licenseForm.get('mailingStreet').updateValueAndValidity();
    return false;
  }

  mailingCityValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const city = control.value.mailingCity;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (city !== null && city !== '' && city !== undefined) ? null : { mailingCityRequired: true };
      }
      return null;
    };
  }

  validateMailingCity(): boolean {
    if (this.licenseForm.hasError('mailingCityRequired') && this.licenseForm.get('mailingCity').touched) {
      this.licenseForm.get('mailingCity').setErrors(['required']);
      return true;
    }
    this.licenseForm.get('mailingCity').clearValidators();
    this.licenseForm.get('mailingCity').updateValueAndValidity();
    return false;
  }

  mailingStateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const state = control.value.mailingState;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (state !== null && state !== '' && state !== undefined) ? null : { mailingStateRequired: true };
      }
      return null;
    };
  }

  validateMailingState(): boolean {
    if (this.licenseForm.hasError('mailingStateRequired') && this.licenseForm.get('mailingState').touched) {
      this.licenseForm.get('mailingState').setErrors(['required']);
      return true;
    }
    this.licenseForm.get('mailingState').clearValidators();
    this.licenseForm.get('mailingState').updateValueAndValidity();
    return false;
  }

  mailingZipValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const zip = control.value.mailingZip;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (zip !== null && zip !== '' && zip !== undefined) ? null : { mailingZipRequired: true };
      }
      return null;
    };
  }

  validateMailingZip(): boolean {
    if (this.licenseForm.hasError('mailingZipRequired') && this.licenseForm.get('mailingZip').touched) {
      this.licenseForm.get('mailingZip').setErrors(['required']);
      return true;
    }
    this.licenseForm.get('mailingZip').clearValidators();
    this.licenseForm.get('mailingZip').updateValueAndValidity();
    return false;
  }

  directoryConsentWebsiteValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const websiteConsent = control.value.directoryConsentWebsite;
      const website = control.value.directoryWebsite;
      const directoryConsent = control.value.directoryConsent;
      if (websiteConsent && directoryConsent) {
        return (website !== null && website !== '' && website !== undefined) ? null : { directoryWebsiteRequired: true };
      }
      return null;
    };
  }

  validateDirectoryConsentWebsite(): boolean {
    const websiteControl = this.verifyForm.get('directoryWebsite');
    const websiteConsent = this.verifyForm.get('directoryConsentWebsite').value;
    const directoryConsent = this.verifyForm.get('directoryConsent').value;

    if(websiteConsent && directoryConsent)
    {
      if (websiteControl.value === '' || websiteControl.value === null || websiteControl.value === undefined) {
        websiteControl.setErrors({ required: true });
        return true;
      }

      const pattern = /^(http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]/i;

      if (!pattern.test(websiteControl.value)) {
        websiteControl.setErrors({ pattern: true });
        return true;
      }
    }
    websiteControl.clearValidators();
    websiteControl.updateValueAndValidity();
    return false;
  }

  directoryConsentEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const emailConsent = control.value.directoryConsentEmail;
      const email = control.value.directoryEmail;
      const directoryConsent = control.value.directoryConsent;
      if (emailConsent && directoryConsent) {
        return (email !== null && email !== '' && email !== undefined) ? null : { directoryEmailRequired: true };
      }
      return null;
    };
  }

  validateDirectoryConsentEmail(): boolean {
    if (this.verifyForm.hasError('directoryEmailRequired') && this.verifyForm.get('directoryEmail').value === true) {
      this.verifyForm.get('directoryEmail').setErrors(['required']);
      return true;
    }
    this.verifyForm.get('directoryEmail').clearValidators();
    this.verifyForm.get('directoryEmail').updateValueAndValidity();
    return false;
  }


  directoryConsentPhoneValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const phoneConsent = control.value.directoryConsentPhone;
      const phone = control.value.directoryPhone;
      const directoryConsent = control.value.directoryConsent;
      if (phoneConsent && directoryConsent) {
        return (phone !== null && phone !== '' && phone !== undefined) ? null : { directoryPhoneRequired: true };
      }
      return null;
    };
  }

  validateDirectoryConsentPhone(): boolean {
    if (this.verifyForm.hasError('directoryPhoneRequired') && this.verifyForm.get('directoryPhone').value === true) {
      this.verifyForm.get('directoryPhone').setErrors(['required']);
      return true;
    }
    this.verifyForm.get('directoryPhone').clearValidators();
    this.verifyForm.get('directoryPhone').updateValueAndValidity();
    return false;
  }
  //End of Custom Validators

  hasRenewal(license: IServiceCenterLicense): boolean {
    if(this.licenses.find(l => (l.isRenewal && l.licenseId == license.licenseId && (l.status != this.sharedService.approved && l.status != this.sharedService.resubmitted && l.status != this.sharedService.incomplete && l.status != this.sharedService.insufficient
      && l.status != this.sharedService.denied && l.status != this.sharedService.revoked && l.status != this.sharedService.expired && l.status != this.sharedService.withdrawn
      && l.status != this.sharedService.surrendered && l.status != this.sharedService.closed))) != null )
      return true;
    return false;
  }

  createRenewal(license: IServiceCenterLicense): void {
    this.sharedService.openConfirm("Are you sure you want to start a renewal application?");

    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.startedRenewal = true;
          this.loadingServiceCenter = true;
          this.licenseService.createRenewalLicense(license.id).subscribe(
          response =>
          {
            this.licenses.push(response);
            this.loadingServiceCenter = false;
            this.viewRenewal(response);
            this.step = 0;
          },
          error =>
          {
            console.log('error', error)
          });
    }});
  }

  viewRenewal(license: IServiceCenterLicense): void
  {
    this.loadingIndex[license.id] = true;
    this.license = license;
    this.licenseLocked = this.license.status !== this.sharedService.pendingRenewal;
    if (this.licenseLocked) {
      this.step = 0;
      this.verifyForm.disable();
      this.documentsForm.disable();
      this.licenseForm.disable();
    }
    else{
      this.verifyForm.enable();
      this.documentsForm.enable();
      this.licenseForm.enable();
      this.verifyForm.markAllAsTouched();
      this.documentsForm.markAllAsTouched();
      this.licenseForm.markAllAsTouched();
      this.setupRouteSubscription();
    }
    this.updateLicenseForm();

    this.licenseForm.controls['serviceCenterInformationCorrect'].setValidators([Validators.required]);
    this.licenseForm.controls['serviceCenterInformationCorrect'].updateValueAndValidity();

    this.licenseForm.controls['entityName'].disable();
    this.licenseForm.controls['secretaryofStateNumber'].disable();
    this.licenseForm.controls['registeredIn'].disable();
    this.licenseForm.controls['registryNumber'].disable();
    this.licenseForm.controls['physicalStreet'].disable();
    this.licenseForm.controls['physicalCity'].disable();
    this.licenseForm.controls['physicalZip'].disable();
    this.licenseForm.controls['physicalCounty'].disable();
    this.licenseForm.controls['premisesDescription'].disable();

    setTimeout(() => {
      this.loadingIndex[license.id] = false;
      this.viewForm = true;
      this.renewing = true;
      if(this.step === 8){
        this.verifyForm.markAsDirty();
        this.verifyForm.markAllAsTouched();
        this.licenseForm.markAsDirty();
        this.validateApplication();
      }
      setTimeout(() =>
      {
        this.updateTrainingTable();
        this.updatePracticumTable();
      }, 400);
    }, 100);
  }
  ngOnDestroy(): void {
    setTimeout(() => {this.routeSubscription.unsubscribe()}, 250);
  }
}
@Component({
  selector: "service-center-details-dialog",
  templateUrl: "./service-center-details.html",
  styleUrls: ["../../license-dashboard/license-dashboard.component.scss"]
})

export class ServiceCenterDetailsDialog {
  public url = environment.baseUrl;
  public applicants: IApplicant[] = [];
  public entityApplicants: IEntityApplicant[] = [];

  constructor(public dialogRef: MatDialogRef<ServiceCenterDetailsDialog>,
    public sharedService: SharedService,
    public applicantService: ApplicantService,
    @Inject(MAT_DIALOG_DATA) public license: IServiceCenterLicense) { }

    ngOnInit() {
      this.applicantService.getApplicants(this.sharedService.serviceCenter, this.license.id).subscribe(
        response => this.applicants = response,
        error => console.log('error', error),
      );
      this.applicantService.getEntityApplicants(this.sharedService.serviceCenter, this.license.id).subscribe(
        response => this.entityApplicants = response,
        error => console.log('error', error),
      );
    }

    print() {
      window.print();
    }

  cancel(): void {
    this.dialogRef.close('cancel');
  }
}
