import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { IApplicantData } from "app/models/303/applicantData/applicantData";
import { Observable } from "rxjs";

@Injectable({providedIn: 'root'})
export class ApplicantDataService {
  private api: string = environment.publicApi + 'ApplicantData/';

  constructor(private http: HttpClient) { }

  checkLicenseExists(licenseId: string): Observable<boolean>{
    return this.http.get<boolean>(this.api + 'CheckLicenseExists/' + licenseId);
  }

  submitApplicantData(licenseId: string, applicantData: IApplicantData): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'SubmitApplicantData/' + licenseId, applicantData);
  }
}
