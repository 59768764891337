import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationStart, Router } from '@angular/router';
import { IDocument } from 'app/models/documents/document';
import { IDocumentType } from 'app/models/documents/documentType';
import { IApplicant } from 'app/models/licenses/applicant';
import { SharedService } from 'app/services/core/shared.service';
import { ApplicantService } from 'app/services/licenses/applicant.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PrimaryContactDialogComponent } from './primary-contact-dialog/primary-contact-dialog.component';
import * as moment from 'moment';

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-applicant',
  templateUrl: './applicant.component.html',
  styleUrls: ['./applicant.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }]
})

export class ApplicantComponent implements OnInit {
  @Input() licenseId: number = 0;
  @Input() licenseType: string = '';
  @Input() primaryContactId: string = '';
  @Input() cardColor: string = '';
  @Input() tableColor: string = '';
  @Input() renewal: boolean = false;
  @Input() licenseLocked: boolean = false;
  @Input() status: string = '';
  @Input() documentTypes: IDocumentType[] = [];
  @Output() notifyParent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() setPointOfContact: EventEmitter<IApplicant> = new EventEmitter<IApplicant>();

  public routeSubscription: Subscription;
  public applicants: IApplicant[] = [];
  public applicant: IApplicant = {
    id: 0,
    applicantId: '',
    ssn: '',
    noSSN: false,
    complete: false,
    ssnAttestation: false,
    legalFirstName: '',
    legalMiddleName: '',
    legalLastName: '',
    preferredFirstName: '',
    preferredMiddleName: '',
    preferredLastName: '',
    alternateNames: '',
    highSchoolDiploma: false,
    oregonResidency: false,
    physicalState: '',
    physicalCity: '',
    physicalStreet: '',
    //physicalAptUnitSuite: '',
    physicalZip: '',
    physicalCounty: '',
    mailingIsPhysical: false,
    mailingState: '',
    mailingCity: '',
    mailingStreet: '',
    //mailingAptUnitSuite: '',
    mailingZip: '',
    mailingCounty: '',
    phone: '',
    alternatePhone: '',
    email: '',
    language: '',
    previousLicense: false,
    affiliatedLicenses: '',
    dob: '',
    backgroundCheckDate: '',
    applicantDocuments: [],
    residentialHistory: [],
    heldLicense: null,
    licensesHeld: '',
    deniedLicense: null,
    subjectToDiscipline: null,
    suedForDamages: null,
    settledAllegations: null,
    allegedAbuse: null,
    substantiatedAllegation: null,
    changeInConvictionHistory: null,
    applicantPageCorrect: null,
    applicantPageCorrectReason: '',
    applicantStillEmployed: null
  }

  public addingApplicant: boolean = false;
  public documentsValid: boolean = true;
  public additionalDocumentsValid: boolean = true;
  public backgroundExpiring: boolean = false;
  public documentTypeReference: { [id: number]: string } = {};
  public personalIdentificationType: number = 0;
  public applicantInfoType: number = 0;
  public personalIdentification: string = 'Personal Identification';
  public applicantInfo: string = 'Applicant Information';
  public deniedLicense: string = 'Previous Denied License Information';
  public subjectToDiscipline: string = 'Previous License Discipline or Revocation Information';
  public suedForDamages: string = 'Previously Sued Information';
  public settledAllegations: string = 'Previous License Allegations or Claims';
  public allegedAbuse: string = 'Previous Alleged Abuse of Child or Adult';
  public deniedLicenseType: number = 0;
  public subjectToDisciplineType: number = 0;
  public suedForDamagesType: number = 0;
  public settledAllegationsType: number = 0;
  public allegedAbuseType: number = 0;
  public missingTypes: {[type: number]: boolean} = {};
  public applicantForm = new UntypedFormGroup({
    ssn: new UntypedFormControl(""),
    noSsn: new UntypedFormControl(false),
    ssnAttestation: new UntypedFormControl(false, Validators.requiredTrue),
    ssnNoAttestation: new UntypedFormControl(false),
    legalFirstName: new UntypedFormControl("", [Validators.required]),
    legalMiddleName: new UntypedFormControl(""),
    legalLastName: new UntypedFormControl("", [Validators.required]),
    preferredFirstName: new UntypedFormControl(""),
    preferredMiddleName: new UntypedFormControl(""),
    preferredLastName: new UntypedFormControl(""),
    alternateNames: new UntypedFormControl(""),
    oregonResidency: new UntypedFormControl(false),
    physicalStreet: new UntypedFormControl("", [Validators.required]),
    physicalCity: new UntypedFormControl("", [Validators.required]),
    physicalState: new UntypedFormControl("", [Validators.required]),
    physicalZip: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    physicalCounty: new UntypedFormControl(""),
    mailingIsPhysical: new UntypedFormControl(false),
    mailingStreet: new UntypedFormControl(""),
    mailingCity: new UntypedFormControl(""),
    mailingState: new UntypedFormControl(""),
    mailingZip: new UntypedFormControl("", [Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    mailingCounty: new UntypedFormControl(""),
    phone: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
    alternatePhone: new UntypedFormControl("", [Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
    email: new UntypedFormControl("", [Validators.required, Validators.email]),
    confirmEmail: new UntypedFormControl("", [Validators.required, Validators.email]),
    language: new UntypedFormControl(""),
    previousLicense: new UntypedFormControl(false),
    affiliatedLicenses: new UntypedFormControl(''),
    dob: new UntypedFormControl("", [Validators.required]),
    identification: new UntypedFormControl(false),
    applicantInfo: new UntypedFormControl(false),
    heldLicense: new UntypedFormControl(Validators.required),
    deniedLicense: new UntypedFormControl(Validators.required),
    subjectToDiscipline: new UntypedFormControl(Validators.required),
    suedForDamages: new UntypedFormControl(Validators.required),
    settledAllegations: new UntypedFormControl(Validators.required),
    allegedAbuse: new UntypedFormControl(Validators.required),
    substantiatedAllegation: new UntypedFormControl(null),
    applicantPageCorrect: new UntypedFormControl(),
    applicantPageCorrectReason: new UntypedFormControl(''),
    changeInConvictionHistory: new UntypedFormControl(false),
    }, {
      validators: [
        this.mailingStreetValidator(),
        this.mailingCityValidator(),
        this.mailingStateValidator(),
        this.mailingZipValidator(),
        this.emailValidator(),
        this.ssnValidator(),
        this.dobValidator(21),
        this.previousLicenseValidator(),
        this.ssnAttestationValidator(),
        this.heldLicenseValidator(),
        this.deniedLicenseValidator(),
        this.subjectToDisciplineValidator(),
        this.suedForDamagesValidator(),
        this.settledAllegationsValidator(),
        this.allegedAbuseValidator(),
        this.substantiatedAllegationValidator(),
        this.applicantPageCorrectValidator()
      ]
    }
  );

  public dataColumns: string[] = ['legalFirstName', 'legalLastName', 'complete', 'actions'];
  public dataSource = new MatTableDataSource<IApplicant>(this.applicants);
  @ViewChild('paginator', {static: false}) paginator: MatPaginator;
  @ViewChild('sort', {static: false}) sort: MatSort;

  constructor(public sharedService: SharedService,
              private router: Router,
              public applicantService: ApplicantService,
              public toastr: ToastrService,
              public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.getApplicants();
    this.setupDocumentTypeReference();
  }

  setupDocumentTypeReference(): void {
    this.personalIdentificationType = this.documentTypes.find(dt => dt.type === this.personalIdentification).id;
    this.applicantInfoType = this.documentTypes.find(dt => dt.type === this.applicantInfo).id;
    this.deniedLicenseType = this.documentTypes.find(dt => dt.type === this.deniedLicense).id;
    this.subjectToDisciplineType = this.documentTypes.find(dt => dt.type === this.subjectToDiscipline).id;
    this.suedForDamagesType = this.documentTypes.find(dt => dt.type === this.suedForDamages).id;
    this.settledAllegationsType = this.documentTypes.find(dt => dt.type === this.settledAllegations).id;
    this.allegedAbuseType = this.documentTypes.find(dt => dt.type === this.allegedAbuse).id;

    this.documentTypeReference[this.personalIdentificationType] = this.personalIdentification;
    this.documentTypeReference[this.applicantInfoType] = this.applicantInfo;
    this.documentTypeReference[this.deniedLicenseType] = this.deniedLicense;
    this.documentTypeReference[this.subjectToDisciplineType] = this.subjectToDiscipline;
    this.documentTypeReference[this.suedForDamagesType] = this.suedForDamages;
    this.documentTypeReference[this.settledAllegationsType] = this.settledAllegations;
    this.documentTypeReference[this.allegedAbuseType] = this.allegedAbuse;

    this.missingTypes[this.applicantInfoType] = false;
  }

  getApplicants(): void {
    this.applicantService.getApplicants(this.licenseType, this.licenseId).subscribe(
      response => this.applicants = response,
      error => console.log('error', error),
      () => {
        if(this.renewal)
          this.checkCompleted();
        setTimeout(
          () => this.updateApplicantTable(), 250
        );
      }
    );
  }

  checkCompleted():void{
    this.applicants.forEach(a => {
      if(a.applicantPageCorrect != true && (a.applicantPageCorrectReason == '' || a.applicantPageCorrectReason == null))
        a.complete = false });
  }

  setupRouteObserver(): void {
    this.routeSubscription = this.router.events
    .pipe(filter((event) => event instanceof NavigationStart))
    .subscribe((e) => {
      this.navigationSave();
    });
  }

  updateApplicantTable(): void{
    this.dataSource.data = this.applicants;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  filterTable(event: Event){
    let value = (event.target as HTMLInputElement).value;
    let filter = value.trim().toLocaleLowerCase();
    this.dataSource.filter = filter;
  }

  addApplicant(): void {
    this.validateDocuments();
    this.validateAdditionalDocuments();
    this.addingApplicant = true;
    this.applicantService.createApplicant(this.licenseType, this.licenseId).subscribe(
      response => this.applicant = response,
      error => console.log('error', error),
      () => {
        this.setupRouteObserver();
        this.applicants.push(this.applicant);
        this.updateApplicantForm();
        this.applicantForm.markAsUntouched();
        this.applicantForm.markAsPristine();
      }
    );
    this.notifyParent.emit(true);
  }

  editApplicant(applicant: IApplicant): void {
    if(!this.licenseLocked){
      this.setupRouteObserver();
    }
    else{
      this.applicantForm.disable();
    }
    this.applicant = applicant;
    if(this.renewal)
    {
      let expirationDate = new Date(applicant.backgroundCheckDate);
      expirationDate.setFullYear(expirationDate.getFullYear() + 5);

      let currentDatePlusOneYear = new Date();
      currentDatePlusOneYear.setFullYear(currentDatePlusOneYear.getFullYear() + 1);

      this.backgroundExpiring = expirationDate < currentDatePlusOneYear;
      this.applicantForm.controls['ssn'].disable();
      this.applicantForm.controls['dob'].disable();
      this.applicantForm.controls['noSsn'].disable();
      this.applicantForm.controls['legalFirstName'].disable();
      this.applicantForm.controls['legalLastName'].disable();
      this.applicantForm.controls['legalMiddleName'].disable();
      this.applicantForm.controls['language'].disable();
      this.applicantForm.controls['ssnAttestation'].disable();
      this.applicantForm.controls['ssnNoAttestation'].disable();
      this.applicantForm.markAllAsTouched();
    }
    this.updateApplicantForm();
    this.notifyParent.emit(true);
    this.validateDocuments();
    this.validateAdditionalDocuments();
  }

  getEmptyApplicant(): IApplicant {
    let emptyApplicant: IApplicant = {
      id: 0,
      applicantId: '',
      ssn: '',
      noSSN: false,
      complete: false,
      ssnAttestation: null,
      legalFirstName: '',
      legalMiddleName: '',
      legalLastName: '',
      preferredFirstName: '',
      preferredMiddleName: '',
      preferredLastName: '',
      alternateNames: '',
      highSchoolDiploma: false,
      oregonResidency: false,
      physicalState: '',
      physicalCity: '',
      physicalStreet: '',
      // physicalAptUnitSuite: '',
      physicalZip: '',
      physicalCounty: '',
      mailingIsPhysical: false,
      mailingState: '',
      mailingCity: '',
      mailingStreet: '',
      // mailingAptUnitSuite: '',
      mailingZip: '',
      mailingCounty: '',
      phone: '',
      alternatePhone: '',
      email: '',
      language: '',
      previousLicense: false,
      affiliatedLicenses: '',
      dob: '',
      backgroundCheckDate: '',
      applicantDocuments: [],
      residentialHistory: [],
      heldLicense: null,
      licensesHeld: '',
      deniedLicense: null,
      subjectToDiscipline: null,
      suedForDamages: null,
      settledAllegations: null,
      allegedAbuse: null,
      substantiatedAllegation: null,
      changeInConvictionHistory: null,
      applicantPageCorrect: null,
      applicantPageCorrectReason: '',
      applicantStillEmployed: null
    }
    return emptyApplicant;
  }

  saveApplicant(close: boolean): void {
    this.updateApplicant();
    this.validateApplicantPageCorrect();
    this.applicant.complete = this.applicantIsValid();
    this.applicantService.editApplicant(this.applicant, this.licenseType, this.licenseId).subscribe(
      () => {
        if(this.primaryContactId === this.applicant.applicantId){
          this.setPointOfContact.emit(this.applicant);
        }
        this.addingApplicant = false;
        let index = this.applicants.findIndex(a => a.id === this.applicant.id);
        this.applicants[index] = this.applicant;

        if(!this.applicant.complete){
          this.validSSNAttestation();
          this.applicantForm.markAllAsTouched();
          this.toastr.error("Please Complete Required Fields");
        }

        if(close) {
          if(!this.applicant.complete){
            this.sharedService.openConfirm(`The applicant information is incomplete.
                                            You will not be able to submit your application until all information is provided.
                                            Continue?`);
            this.sharedService.confirmed().subscribe(
              confirmed => {
                if(confirmed){
                  this.routeSubscription.unsubscribe();
                  this.cancel();
                }
              }
            )
          }
          else{
            this.routeSubscription.unsubscribe();
            this.cancel();
          }
        }
      },
      error => console.log('error', error)
    );
  }

  navigationSave(): void {
    this.updateApplicant();
    this.applicant.complete = this.applicantIsValid();
    if(this.primaryContactId === this.applicant.applicantId){
      this.setPointOfContact.emit(this.applicant);
    }
    this.applicantService.editApplicant(this.applicant, this.licenseType, this.licenseId).subscribe();
  }

  updateApplicant(): void {
    let applicantInfo = this.applicantForm.value;
    if(!this.renewal)
    {
      this.applicant.ssn = applicantInfo.ssn;
      this.applicant.noSSN = applicantInfo.noSsn;
      this.applicant.dob = applicantInfo.dob;
      this.applicant.legalFirstName = applicantInfo.legalFirstName;
      this.applicant.legalMiddleName = applicantInfo.legalMiddleName;
      this.applicant.legalLastName = applicantInfo.legalLastName;
      this.applicant.language = applicantInfo.language;
      this.applicant.ssnAttestation = applicantInfo.ssnAttestation;
    }

    this.applicant.preferredFirstName = applicantInfo.preferredFirstName;
    this.applicant.preferredMiddleName = applicantInfo.preferredMiddleName;
    this.applicant.preferredLastName = applicantInfo.preferredLastName;
    this.applicant.alternateNames = applicantInfo.alternateNames;
    this.applicant.oregonResidency = applicantInfo.oregonResidency;
    this.applicant.physicalState = applicantInfo.physicalState;
    this.applicant.physicalCity = applicantInfo.physicalCity;
    this.applicant.physicalStreet = applicantInfo.physicalStreet;
    this.applicant.physicalZip = applicantInfo.physicalZip;
    this.applicant.physicalCounty = applicantInfo.physicalCounty;
    this.applicant.mailingIsPhysical = applicantInfo.mailingIsPhysical;
    this.applicant.mailingState = applicantInfo.mailingState;
    this.applicant.mailingCity = applicantInfo.mailingCity;
    this.applicant.mailingStreet = applicantInfo.mailingStreet;
    this.applicant.mailingZip = applicantInfo.mailingZip;
    this.applicant.mailingCounty = applicantInfo.mailingCounty;
    this.applicant.phone = applicantInfo.phone;
    this.applicant.alternatePhone = applicantInfo.alternatePhone;
    this.applicant.email = applicantInfo.email;
    this.applicant.previousLicense = applicantInfo.previousLicense;
    this.applicant.affiliatedLicenses = applicantInfo.affiliatedLicenses;
    this.applicant.applicantPageCorrect = applicantInfo.applicantPageCorrect;
    this.applicant.applicantPageCorrectReason = applicantInfo.applicantPageCorrectReason;
    this.applicant.changeInConvictionHistory = applicantInfo.changeInConvictionHistory;
    this.applicant.heldLicense = applicantInfo.heldLicense;
    this.applicant.deniedLicense = applicantInfo.deniedLicense;
    this.applicant.subjectToDiscipline = applicantInfo.subjectToDiscipline;
    this.applicant.suedForDamages = applicantInfo.suedForDamages;
    this.applicant.settledAllegations = applicantInfo.settledAllegations;
    this.applicant.allegedAbuse  = applicantInfo.allegedAbuse;
    this.applicant.substantiatedAllegation = applicantInfo.substantiatedAllegation;

    if(applicantInfo.mailingIsPhysical){
      this.applicant.mailingState = applicantInfo.physicalState;
      this.applicant.mailingCity = applicantInfo.physicalCity;
      this.applicant.mailingStreet = applicantInfo.physicalStreet;
      this.applicant.mailingZip = applicantInfo.physicalZip;
      this.applicant.mailingCounty = applicantInfo.physicalCounty;
      this.applicantForm.patchValue({
        mailingState: applicantInfo.physicalState,
        mailingCity: applicantInfo.physicalCity,
        mailingStreet: applicantInfo.physicalStreet,
        mailingZip: applicantInfo.physicalZip,
        mailingCounty: applicantInfo.physicalCounty
      });
    }
  }

  updateApplicantForm(): void {
    if(this.applicant.ssnAttestation === false)
    {
      this.applicantForm.patchValue({
        ssnNoAttestation: true
      });
    }
    this.applicantForm.patchValue({
      ssn: this.applicant.ssn,
      noSsn: this.applicant.noSSN,
      ssnAttestation: this.applicant.ssnAttestation,
      legalFirstName: this.applicant.legalFirstName,
      legalMiddleName: this.applicant.legalMiddleName,
      legalLastName: this.applicant.legalLastName,
      preferredFirstName: this.applicant.preferredFirstName,
      preferredMiddleName: this.applicant.preferredMiddleName,
      preferredLastName: this.applicant.preferredLastName,
      alternateNames: this.applicant.alternateNames,
      oregonResidency: this.applicant.oregonResidency,
      physicalStreet: this.applicant.physicalStreet,
      physicalCity: this.applicant.physicalCity,
      physicalState: this.applicant.physicalState,
      physicalZip: this.applicant.physicalZip,
      physicalCounty: this.applicant.physicalCounty,
      mailingIsPhysical: this.applicant.mailingIsPhysical,
      mailingStreet: this.applicant.mailingStreet,
      mailingCity: this.applicant.mailingCity,
      mailingState: this.applicant.mailingState,
      mailingZip: this.applicant.mailingZip,
      mailingCounty: this.applicant.mailingCounty,
      phone: this.applicant.phone,
      alternatePhone: this.applicant.alternatePhone,
      email: this.applicant.email,
      confirmEmail: this.applicant.email,
      language: this.applicant.language,
      previousLicense: this.applicant.previousLicense,
      affiliatedLicenses: this.applicant.affiliatedLicenses,
      applicantPageCorrect: this.applicant.applicantPageCorrect,
      applicantPageCorrectReason: this.applicant.applicantPageCorrectReason,
      changeInConvictionHistory: this.applicant.changeInConvictionHistory,
      dob: '',
      identification: false,
      residency: false,
      heldLicense: this.applicant.heldLicense,
      deniedLicense: this.applicant.deniedLicense,
      subjectToDiscipline: this.applicant.subjectToDiscipline,
      suedForDamages: this.applicant.suedForDamages,
      settledAllegations: this.applicant.settledAllegations,
      allegedAbuse: this.applicant.allegedAbuse,
      substantiatedAllegation: this.applicant.substantiatedAllegation,
    });
    if(this.applicant.dob != null && this.applicant.dob != '')
    {
      this.applicantForm.patchValue({
        dob: new Date(this.applicant.dob)
      });
    }
  }

  changeAttestation(agree: boolean): void {
    if (agree) {
      this.applicantForm.patchValue({
        ssnNoAttestation: false
      });
    }
    if (!agree) {
      this.applicantForm.patchValue({
        ssnAttestation: false
      });
    }
  }

  selectPointOfContact(applicant: IApplicant): void {
    let dialogRef = this.dialog.open(PrimaryContactDialogComponent, {
        data: applicant,
       role: 'dialog',
       ariaLabel: "Confirmation Dialog",
       minWidth: '600px',
       maxWidth: !this.sharedService.mobile? '700px' : '300px',
       maxHeight: this.sharedService.mobile? '600px' : '700px',
       panelClass: this.sharedService.userProfile.theme === "dark" ? "theme-dark" : "",
    });
    dialogRef.afterClosed().subscribe(response => {
        if(response){
          this.setPointOfContact.emit(applicant);
        }
    });
  }

  cancel(): void {
    let applicantId: number = this.applicant.id;
    if(this.addingApplicant){
      this.sharedService.openConfirm("Changes to this applicant will not be saved. Continue?");
      this.sharedService.confirmed().subscribe(
        confirmed => {
          if(confirmed){
            this.applicantService.deleteApplicant(applicantId, this.licenseId, this.licenseType).subscribe(
              () => {
                this.routeSubscription.unsubscribe();
                this.applicants = this.applicants.filter(a => a.id !== applicantId);
                this.applicant = this.getEmptyApplicant();
                this.updateApplicantForm();
                this.addingApplicant = false;
                this.notifyParent.emit(false);
                setTimeout(
                  () => this.updateApplicantTable(), 250
                );
              },
              error => {
                console.log('error', error);
                this.addingApplicant = false;
              }
            );
          }
        }
      );
    }
    else{
      if(!this.licenseLocked){
        this.routeSubscription.unsubscribe();
      }
      this.applicant = this.getEmptyApplicant();
      this.updateApplicantForm();
      this.notifyParent.emit(false);
      setTimeout(
        () => this.updateApplicantTable(), 250
      );
    }
  }

  deleteApplicant(applicant: IApplicant): void {
    this.sharedService.openConfirm("Remove " + applicant.legalFirstName + " " + applicant.legalLastName + "?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.applicantService.deleteApplicant(applicant.id, this.licenseId, this.licenseType).subscribe(
            () => {
              if(this.primaryContactId == applicant.applicantId){
                let emptyApplicant = this.getEmptyApplicant();
                this.setPointOfContact.emit(emptyApplicant);
              }
              this.applicants = this.applicants.filter(a => a.id !== applicant.id);
              this.updateApplicantTable();
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  validateDocuments(): void {
    this.documentsValid = true;
    let requiredTypes: number[];

    if(this.renewal) {
      requiredTypes = [
        this.applicantInfoType
      ];
    }
    else {
      requiredTypes = [
        this.applicantInfoType,
        this.personalIdentificationType
      ];
    }
    let types: number[] = [];
    this.applicant.applicantDocuments.forEach(ld => {
      if(!ld.deprecated)
        ld.types.forEach(t => types.push(t))
      }
      );

    requiredTypes.forEach(type => {
      if(!types.includes(type)){
        this.documentsValid = false;
        this.missingTypes[type] = true;
      }
    });
  }
  validDeniedLicenseDocument(): boolean {
    let applicantInfo = this.applicantForm.value;
    if(applicantInfo.deniedLicense == true && this.applicant.applicantDocuments.find(d => d.types.includes(this.deniedLicenseType)) == null)
      return false
    return true;
  }

  validSubjectToDisciplineDocument(): boolean {
    let applicantInfo = this.applicantForm.value;
    if(applicantInfo.subjectToDiscipline == true && this.applicant.applicantDocuments.find(d => d.types.includes(this.subjectToDisciplineType)) == null)
      return false
    return true;
  }

  validSuedForDamagesDocument(): boolean {
    let applicantInfo = this.applicantForm.value;
    if(applicantInfo.suedForDamages == true && this.applicant.applicantDocuments.find(d => d.types.includes(this.suedForDamagesType)) == null)
      return false
    return true;
  }

  validSettledAllegationsDocument(): boolean {
    let applicantInfo = this.applicantForm.value;
    if(applicantInfo.settledAllegations == true && this.applicant.applicantDocuments.find(d => d.types.includes(this.settledAllegationsType)) == null)
      return false
    return true;
  }

  validAllegedAbuseDocument(): boolean {
    let applicantInfo = this.applicantForm.value;
    if(applicantInfo.allegedAbuse == true && this.applicant.applicantDocuments.find(d => d.types.includes(this.allegedAbuseType)) == null)
      return false
    return true;
  }

  validateAdditionalDocuments(): void {
    this.additionalDocumentsValid = (this.validDeniedLicenseDocument() && this.validSubjectToDisciplineDocument() && this.validSuedForDamagesDocument() && this.validSettledAllegationsDocument() && this.validAllegedAbuseDocument());
  }

  uploadAdditionalDocument(event: Event, type: number): void {
    let types: number[] = [type];
    let dirtyFile = (event.target as HTMLInputElement).files[0];
    let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, ''));
    let upload: IDocument = {
      id: 0,
      name: file.name,
      comments: "",
      extenstion: "",
      dateCreated: "",
      dateLastUpdated: "",
      createdBy: "",
      lastUpdatedBy: "",
      parentId: this.applicant.id,
      types: types,
      adminOnly: true,
      deprecated: false
    }

    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("document", JSON.stringify(upload));
      this.applicantService.uploadDocument(formData).subscribe(
        response => this.applicant.applicantDocuments.push(response),
        error => {
          (event.target as HTMLInputElement).value = '';
          console.log('error', error);
        },
        () => {
          (event.target as HTMLInputElement).value = '';
          this.validateAdditionalDocuments();
        });
  }

  uploadDocument(event: Event): void {
    let form = this.applicantForm.value;
    let types: number[] = [];

    if(form.identification) {
      types.push(this.personalIdentificationType);
    }

    if(form.applicantInfo){
      types.push(this.applicantInfoType);
    }

    types.forEach(type => {
      this.missingTypes[type] = false;
    });

    if(types.length > 0){
      let dirtyFile = (event.target as HTMLInputElement).files[0];
      let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, ''));
      if(this.sharedService.validateFile(file))
      {
        let upload: IDocument = {
          id: 0,
          name: file.name,
          comments: "",
          extenstion: "",
          dateCreated: "",
          dateLastUpdated: "",
          createdBy: "",
          lastUpdatedBy: "",
          parentId: this.applicant.id,
          types: types,
          adminOnly: false,
          deprecated: false
        }

        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("document", JSON.stringify(upload));
        this.applicantService.uploadDocument(formData).subscribe(
          response => this.applicant.applicantDocuments.push(response),
          error => {
            (event.target as HTMLInputElement).value = '';
            console.log('error', error);
          },
          () => {
            (event.target as HTMLInputElement).value = '';
            this.validateDocuments();
            this.applicantForm.patchValue({
              identification: false,
              applicantInfo: false
            });
          });
      }
      else
      this.toastr.error("Unsupported File Type");
    }
    else{
      (event.target as HTMLInputElement).value = '';
      this.toastr.error("Please select at least one requirement met by the document");
    }
  }

  downloadDocument(fileId: number, fileName: string): void {
    this.applicantService.downloadFile(fileId).subscribe(
      (response) => this.saveFile(fileName, response),
      (error) => console.log("error", error)
    );
  }

  saveFile(fileName: string, blob: Blob) {
    let file = URL.createObjectURL(blob);
    var fileDownload = document.createElement("a");
    fileDownload.href = file;
    fileDownload.download = fileName;
    fileDownload.click();
  }

  deleteFile(id: number, name: string) {
    this.sharedService.openConfirm("Delete " + name + "?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.applicantService.deleteDocument(id).subscribe(
            () => {
              this.applicant.applicantDocuments = this.applicant.applicantDocuments.filter(item => item.id !== id);
              this.validateDocuments();
              this.validateAdditionalDocuments();
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  applicantIsValid(): boolean {
    this.validateDocuments();
    this.validateAdditionalDocuments();
    this.validateSubstantiatedAllegation();
    if(this.renewal)
    {
      this.applicantForm.controls['applicantPageCorrect'].setValidators([Validators.required]);
      this.applicantForm.controls['applicantPageCorrect'].updateValueAndValidity();
      this.applicantForm.controls['changeInConvictionHistory'].setValidators([Validators.required]);
      this.applicantForm.controls['changeInConvictionHistory'].updateValueAndValidity();
    }
    return this.applicantForm.valid && this.documentsValid && this.additionalDocumentsValid;
  }

  //Applicant Custom Validators
  heldLicenseValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const heldLicense = control.value.heldLicense;

      if(heldLicense != null){
        return null;
      }

      return { heldLicenseRequired: true };
    }
  }

  validateHeldLicense(): boolean {
    if (this.applicantForm.hasError('heldLicenseRequired') && this.applicantForm.get('heldLicense').touched) {
      this.applicantForm.get('heldLicense').setErrors(['heldLicenseRequired']);
      return true;
    }
    this.applicantForm.get('heldLicense').clearValidators();
    this.applicantForm.get('heldLicense').updateValueAndValidity();
    return false;
  }

  deniedLicenseValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const deniedLicense = control.value.deniedLicense;

      if(deniedLicense != null){
        return null;
      }

      return { deniedLicenseRequired: true };
    }
  }

  validateDeniedLicense(): boolean {
    if (this.applicantForm.hasError('deniedLicenseRequired') && this.applicantForm.get('deniedLicense').touched) {
      this.applicantForm.get('deniedLicense').setErrors(['deniedLicenseRequired']);
      return true;
    }
    this.applicantForm.get('deniedLicense').clearValidators();
    this.applicantForm.get('deniedLicense').updateValueAndValidity();
    return false;
  }

  subjectToDisciplineValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const subjectToDiscipline = control.value.subjectToDiscipline;

      if(subjectToDiscipline != null){
        return null;
      }

      return { subjectToDisciplineRequired: true };
    }
  }

  validateSubjectToDiscipline(): boolean {
    if (this.applicantForm.hasError('subjectToDisciplineRequired') && this.applicantForm.get('subjectToDiscipline').touched) {
      this.applicantForm.get('subjectToDiscipline').setErrors(['subjectToDisciplineRequired']);
      return true;
    }
    this.applicantForm.get('subjectToDiscipline').clearValidators();
    this.applicantForm.get('subjectToDiscipline').updateValueAndValidity();
    return false;
  }

  suedForDamagesValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const suedForDamages = control.value.suedForDamages;

      if(suedForDamages != null){
        return null;
      }

      return { suedForDamagesRequired: true };
    }
  }

  validateSuedForDamages(): boolean {
    if (this.applicantForm.hasError('suedForDamagesRequired') && this.applicantForm.get('suedForDamages').touched) {
      this.applicantForm.get('suedForDamages').setErrors(['suedForDamagesRequired']);
      return true;
    }
    this.applicantForm.get('suedForDamages').clearValidators();
    this.applicantForm.get('suedForDamages').updateValueAndValidity();
    return false;
  }

  settledAllegationsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const settledAllegations = control.value.settledAllegations;

      if(settledAllegations != null){
        return null;
      }

      return { settledAllegationsRequired: true };
    }
  }

  validateSettledAllegations(): boolean {
    if (this.applicantForm.hasError('settledAllegationsRequired') && this.applicantForm.get('settledAllegations').touched) {
      this.applicantForm.get('settledAllegations').setErrors(['settledAllegationsRequired']);
      return true;
    }
    this.applicantForm.get('settledAllegations').clearValidators();
    this.applicantForm.get('settledAllegations').updateValueAndValidity();
    return false;
  }

  allegedAbuseValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const allegedAbuse = control.value.allegedAbuse;

      if(allegedAbuse != null){
        return null;
      }

      return { allegedAbuseRequired: true };
    }
  }

  validateAllegedAbuse(): boolean {
    if (this.applicantForm.hasError('allegedAbuseRequired') && this.applicantForm.get('allegedAbuse').touched) {
      this.applicantForm.get('allegedAbuse').setErrors(['allegedAbuseRequired']);
      return true;
    }
    this.applicantForm.get('allegedAbuse').clearValidators();
    this.applicantForm.get('allegedAbuse').updateValueAndValidity();
    return false;
  }

  substantiatedAllegationValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const allegedAbuse = control.value.allegedAbuse;
      const substantiatedAllegation = control.value.substantiatedAllegation

      if(allegedAbuse == null || allegedAbuse == false){
        return null;
      }
      return (substantiatedAllegation == true || substantiatedAllegation == false) ? null : { substantiatedAllegationRequired: true };
    }
  }

  validateSubstantiatedAllegation(): boolean {
    if (this.applicantForm.hasError('substantiatedAllegationRequired') && this.applicantForm.get('substantiatedAllegation').touched) {
      this.applicantForm.get('substantiatedAllegation').setErrors(['substantiatedAllegationRequired']);
      return true;
    }
    this.applicantForm.get('substantiatedAllegation').clearValidators();
    this.applicantForm.get('substantiatedAllegation').updateValueAndValidity();
    return false;
  }

  applicantPageCorrectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const pageCorrect = control.value.applicantPageCorrect;
      const reason = control.value.applicantPageCorrectReason;
      if (!pageCorrect && this.renewal) {
        return (reason !== null && reason !== '' && reason !== undefined) ? null : { applicantPageCorrectReasonRequired: true };
      }
      return null;
    };
  }

  validateApplicantPageCorrect(): boolean {
    if (this.applicantForm.hasError('applicantPageCorrectReasonRequired') && this.applicantForm.get('applicantPageCorrect').value === false) {
      this.applicantForm.get('applicantPageCorrectReason').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('applicantPageCorrectReason').clearValidators();
    this.applicantForm.get('applicantPageCorrectReason').updateValueAndValidity();
    return false;
  }

  dobValidator(age): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if(this.renewal)
        return null;
      let dob = control.value.dob;
      if(dob == null || dob == '' || dob == undefined){
        return { ageRequired: true };
      }

      var yearsPassed = moment().diff(dob, 'years');
      if (yearsPassed >= age) {
        return null;
      }

      return { validAgeRequired: true };
    };
  }

  isAgeValid(age: number): boolean {
    if(this.renewal)
      return true;
    if (this.applicantForm.hasError('validAgeRequired')) {
      this.applicantForm.get('dob').setErrors(['required']);
      return false;
    }

    let dob = this.applicantForm.get('dob').value;
    if (!dob) {
      return false;
    }

    var yearsPassed = moment().diff(dob, 'years');
    if (yearsPassed < age) {
      return false;
    }

    if (this.applicantForm.hasError('ageRequired') && this.applicantForm.get('dob').touched) {
      return false;
    }

    this.applicantForm.get('dob').clearValidators();
    this.applicantForm.get('dob').updateValueAndValidity();
    return true;
  }

  ssnValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const ssn = control.value.ssn;
      const noSsn = control.value.noSsn;
      if(noSsn){
        return null;
      }
      if(this.renewal)
        return null;
      return (ssn !== null && ssn !== '' && ssn !== undefined) ? null : { ssnRequired: true };
    }
  }

  validateSsn(): boolean {
    if (this.applicantForm.hasError('ssnRequired') && this.applicantForm.get('ssn').touched) {
      this.applicantForm.get('ssn').setErrors(['ssnRequired']);
      return true;
    }
    this.applicantForm.get('ssn').clearValidators();
    this.applicantForm.get('ssn').updateValueAndValidity();
    return false;
  }

  validateSsnAttestation(): boolean {
    if (this.applicantForm.hasError('ssnAttestationRequired') && this.applicantForm.get('ssnAttestation').touched) {
      this.applicantForm.get('ssnAttestation').setErrors(['ssnAttestationRequired']);
      return true;
    }
    this.applicantForm.get('ssnAttestation').clearValidators();
    this.applicantForm.get('ssnAttestation').updateValueAndValidity();
    return false;
  }

  validSSNAttestation(): void {
    if (this.applicantForm.get('ssnAttestation').value === null && !this.renewal) {
      this.applicantForm.get('ssnAttestation').setErrors(['ssnAttestationRequired']);
    }
  }

  ssnAttestationValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const ssn = control.value.ssnAttestation;
      const noSsn = control.value.ssnNoAttestation;
      if (noSsn) {
        return null;
      }
      if(this.renewal)
        return null;
      return (ssn || noSsn) ? null : { ssnAttestationRequired: true };
    }
  }

  previousLicenseValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasPreviousLicense = control.value.previousLicense;
      const affiliatedLicenses = control.value.affiliatedLicenses;
      if(!hasPreviousLicense){
        return null;
      }
      return (affiliatedLicenses !== null && affiliatedLicenses !== '' && affiliatedLicenses !== undefined) ? null : { licensesRequired: true };
    }
  }

  validatePreviousLicense(): boolean {
    if (this.applicantForm.hasError('licensesRequired') && this.applicantForm.get('affiliatedLicenses').touched) {
      this.applicantForm.get('affiliatedLicenses').setErrors(['licensesRequired']);
      return true;
    }
    this.applicantForm.get('affiliatedLicenses').clearValidators();
    this.applicantForm.get('affiliatedLicenses').updateValueAndValidity();
    return false;
  }

  mailingIsSame(): void {
    this.applicantForm.get('mailingStreet').clearValidators();
    this.applicantForm.get('mailingCity').clearValidators();
    this.applicantForm.get('mailingState').clearValidators();
    this.applicantForm.get('mailingZip').clearValidators();

    this.applicantForm.get('mailingStreet').updateValueAndValidity();
    this.applicantForm.get('mailingCity').updateValueAndValidity();
    this.applicantForm.get('mailingState').updateValueAndValidity();
    this.applicantForm.get('mailingZip').updateValueAndValidity();
  }

  emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email = control.value.email;
      const confirmation = control.value.confirmEmail;
      return email === confirmation ? null : { emailConfirmed: true };
    }
  }

  validateEmail(): boolean {
    if (this.applicantForm.hasError('emailConfirmed') && this.applicantForm.get('confirmEmail').touched) {
      this.applicantForm.get('confirmEmail').setErrors(['emailConfirmed']);
      return true;
    }
    this.applicantForm.get('confirmEmail').clearValidators();
    this.applicantForm.get('confirmEmail').updateValueAndValidity();
    return false;
  }

  mailingStreetValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const street = control.value.mailingStreet;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (street !== null && street !== '' && street !== undefined) ? null : { mailingStreetRequired: true };
      }
      return null;
    };
  }

  validateMailingStreet(): boolean {
    if (this.applicantForm.hasError('mailingStreetRequired') && this.applicantForm.get('mailingStreet').touched) {
      this.applicantForm.get('mailingStreet').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingStreet').clearValidators();
    this.applicantForm.get('mailingStreet').updateValueAndValidity();
    return false;
  }

  mailingCityValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const city = control.value.mailingCity;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (city !== null && city !== '' && city !== undefined) ? null : { mailingCityRequired: true };
      }
      return null;
    };
  }

  validateMailingCity(): boolean {
    if (this.applicantForm.hasError('mailingCityRequired') && this.applicantForm.get('mailingCity').touched) {
      this.applicantForm.get('mailingCity').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingCity').clearValidators();
    this.applicantForm.get('mailingCity').updateValueAndValidity();
    return false;
  }

  mailingStateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const state = control.value.mailingState;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (state !== null && state !== '' && state !== undefined) ? null : { mailingStateRequired: true };
      }
      return null;
    };
  }

  validateMailingState(): boolean {
    if (this.applicantForm.hasError('mailingStateRequired') && this.applicantForm.get('mailingState').touched) {
      this.applicantForm.get('mailingState').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingState').clearValidators();
    this.applicantForm.get('mailingState').updateValueAndValidity();
    return false;
  }

  mailingZipValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const zip = control.value.mailingZip;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (zip !== null && zip !== '' && zip !== undefined) ? null : { mailingZipRequired: true };
      }
      return null;
    };
  }

  validateMailingZip(): boolean {
    if (this.applicantForm.hasError('mailingZipRequired') && this.applicantForm.get('mailingZip').touched) {
      this.applicantForm.get('mailingZip').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingZip').clearValidators();
    this.applicantForm.get('mailingZip').updateValueAndValidity();
    return false;
  }
  //End of Validators

  ngOnDestroy() {
    setTimeout(() => {
      this.routeSubscription.unsubscribe();
    }, 250);
  }

}
