import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { RoutesModule } from './routes.module';
import { SharedModule } from './shared/shared.module';
import { FormlyConfigModule } from './configuration/angular/formly-config.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DefaultLayoutComponent } from '../app/layout/default-layout/default-layout.component';
import { SidebarComponent } from '../app/layout/sidebar/sidebar.component';
import { AccordionDirective } from '../app/layout/sidebar/accordion.directive';
import { AccordionItemDirective } from '../app/layout/sidebar/accordionItem.directive';
import { AccordionAnchorDirective } from '../app/layout/sidebar/accordionanchor.directive';
import { NavbarComponent } from '../app/layout/navbar/navbar.component';
import { FooterComponent } from '../app/layout/footer/footer.component';
import { TranslateComponent } from '../app/layout/widgets/translate.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AppTimeoutComponent, TimeoutDialog } from './layout/widgets/timeout.component';
import { ComplaintsComponent } from './components/complaints/complaints.component';

import { environment } from '@env/environment';
import { BASE_URL } from '@core/interceptors/base-url-interceptor';
import { httpInterceptorProviders } from '@core/interceptors';
import { appInitializerProviders } from '@core/initializers';

import { IPublicClientApplication, PublicClientApplication, InteractionType, LogLevel, BrowserCacheLocation } from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule, MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent
} from '@azure/msal-angular';

import { b2cPolicies, protectedResources } from '../app/auth/auth-config';
import { ApplyComponent } from './components/apply/apply.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { PublicProgramListComponent } from './components/public-program-list/public-program-list.component';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { AccountInformationComponent } from './components/account-information/account-information.component';
import { PublicLicenseSearchComponent } from './components/public-license-search/public-license-search.component';
import { LicenseDirectoryComponent } from './components/directory/license-directory.component';
import { ApplicantDataComponent } from './components/applicant-data/applicant-data.component';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.b2c.clientId,
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      redirectUri: environment.b2c.redirectUri,
      postLogoutRedirectUri: environment.b2c.postLogoutRedirectUri,
      knownAuthorities: [b2cPolicies.authorityDomain]
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Warning,
        piiLoggingEnabled: true
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(protectedResources.endpoint, protectedResources.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...protectedResources.scopes]
    },
    loginFailedRoute: 'login-failed'
  };
}

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

// Required for AOT compilation
export function TranslateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    TimeoutDialog,
    AppTimeoutComponent,
    SidebarComponent,
    AccordionDirective,
    AccordionItemDirective,
    AccordionAnchorDirective,
    NavbarComponent,
    FooterComponent,
    TranslateComponent,
    ApplyComponent,
    ConfirmDialogComponent,
    PublicProgramListComponent,
    PublicLicenseSearchComponent,
    LicenseDirectoryComponent,
    ComplaintsComponent,
    AccountInformationComponent,
    ApplicantDataComponent

  ],
  imports: [
    BrowserModule,
    NgIdleKeepaliveModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    RoutesModule,
    SharedModule,
    MsalModule,
    FormlyConfigModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    PDFExportModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    { provide: BASE_URL, useValue: environment.baseUrl },
    httpInterceptorProviders,
    appInitializerProviders
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
