import { Injectable } from "@angular/core";
import { AbstractControl, AsyncValidator, ValidationErrors } from "@angular/forms";
import { ApplicantDataService } from "app/services/applicant-data/applicant-data.service";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable({ providedIn: 'root' })

export class ApplicantLicenseIdValidator implements AsyncValidator {
  constructor(private service: ApplicantDataService){}

  validate(
    control: AbstractControl
  ): Observable< ValidationErrors | null > {
    return this.service.checkLicenseExists(control.value).pipe(
      map(exists => (!exists ? { idNotFound: true} : null )),
      catchError(() => of(null))
    )
  }
}
