export const environment = {
  production: false,
  baseUrl: 'https://test-psilocybin.oregon.gov',
  useHash: false,
  hmr: false,
  showThemeToggle: true,
  translateText: false,
  collapsibleSidebar: false,
  useAppInsights: false,
  appInsightsInstrumentationKey: 'e6f30bb6-a160-4d69-a8d2-8642430020c1',
  useTimeout: true,
  idleInterval: 1800,
  build: 'Test',
  version: '3.3.0',
  displaySidebar: true,
  theme: 'light',
  publicApi: 'https://test-psilocybin.oregon.gov/api/public/',
  privateApi: 'https://test-psilocybin.oregon.gov/api/private/',
  b2c: {
    clientId: 'a2cdd05d-0ebb-4799-9c33-54125c4de78f',
    authority: 'https://oregonpsilocybindevb2c.b2clogin.com/',
    authorityDomain:'oregonpsilocybindevb2c.b2clogin.com',
    domain: 'oregonpsilocybindevb2c.onmicrosoft.com/',
    signUpSignInPolicy: 'B2C_1_OHA_PH_PSILOCYBIN_DEV_SUSI',
    scopes: ['openid', 'https://oregonpsilocybindevb2c.onmicrosoft.com/a2cdd05d-0ebb-4799-9c33-54125c4de78f/User.Access'],
    redirectUri: 'https://test-psilocybin.oregon.gov/',
    postLogoutRedirectUri: 'https://test-psilocybin.oregon.gov/'
  },
  activeLicenses: {
    all: true,
    facilitator: true,
    manufacturer: true,
    serviceCenter: true,
    testingLab: true,
    workerPermit: true
  },
  enableHttpsRedirect: true,
  staffInbox: "Licensing.Psilocybin@odhsoha.oregon.gov",
  internalMessaging: {
    enabled: true,
    canRespond: true,
    canCreate: true,
    canAttach: true,
    canSetPriority: true,
    canViewArchived: true,
    canAnnounce: false,
    canViewStaffMessages: false,
    defaultStaffRecipients: [
      { recipient: "Psilocybin Licensing Team",
        name: "Psilocybin Licensing Team",
        email: "Licensing.Psilocybin@odhsoha.oregon.gov"
      },
      { recipient: "Psilocybin Compliance Team",
        name: "Psilocybin Compliance Team",
        email: "Compliance.Psilocybin@odhsoha.oregon.gov"
      },
      { recipient: "Product Tracking Team",
      name: "Product Tracking Team",
      email: "Compliance.Psilocybin@odhsoha.oregon.gov"
      }
    ]
  }
};

