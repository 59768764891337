import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { IClientData } from "app/models/303/clientData";
import { ISB303 } from "app/models/303/sb303";
import { IServiceCenterData } from "app/models/303/serviceCenterData";
import { IServiceCenterLicense } from "app/models/licenses/service-center-license";
import { Observable } from "rxjs";

@Injectable({providedIn: 'root'})
export class SB303Service {
  private api: string = environment.privateApi + 'SB303/';

  constructor(private http: HttpClient) { }

  getServiceCenterLicenses(): Observable<boolean> {
    return this.http.get<boolean>(this.api + 'GetServiceCenterLicenses');
  }

  getLicenses(): Observable<IServiceCenterLicense[]> {
    return this.http.get<IServiceCenterLicense[]>(this.api + 'GetLicenses');
  }

  getSB303Record(id: number): Observable<ISB303> {
    return this.http.get<ISB303>(this.api + 'GetSB303Record/' + id);
  }

  createSB303Record(id: number): Observable<ISB303> {
    return this.http.get<ISB303>(this.api + 'CreateSB303Report/' + id);
  }

  updateClientData(clientData: IClientData, submit: boolean): Observable<Boolean> {
    return this.http.post<Boolean>(this.api + 'UpdateClientData/' + submit, clientData);
  }

  updateServiceCenterData(serviceCenterData: IServiceCenterData, submit: boolean): Observable<Boolean> {
    return this.http.post<Boolean>(this.api + 'UpdateServiceCenterData/' + submit, serviceCenterData);
  }
}
