import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDocument } from 'app/models/documents/document';
import { IDocumentType } from 'app/models/documents/documentType';
import { IExamAnswer } from 'app/models/exam/exam-answer';
import { IExamAttempt } from 'app/models/exam/exam-attempt';
import { IExamQuestion } from 'app/models/exam/exam-question';
import { IFacilitatorLicense } from 'app/models/licenses/facilitator-license';
import { SharedService } from 'app/services/core/shared.service';
import { ExamService } from 'app/services/exam/exam.service';
import { FacilitatorService } from 'app/services/licenses/facilitator.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { IApplicant } from 'app/models/licenses/applicant';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from 'app/components/payments/service/payment.service';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { environment } from '@env/environment';
import { DialogChangeDirectoryComponent } from '../dialog-change-directory-consent/dialog-change-directory-consent.component';

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-facilitator',
  templateUrl: './facilitator.component.html',
  styleUrls: ['./facilitator.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})

export class FacilitatorComponent implements OnInit, OnDestroy {
  public loadingFacilitator: boolean = false;
  public showStartApplication: boolean = false;
  public showSubmitted: boolean = false;
  public viewForm: boolean = false;
  public showEditLoading: boolean = false;
  public facilitatorSteps: string[] = [
    "gettingStarted",
    "personalInformation",
    "documents",
    "ohaExam",
    "feeInformation",
    "reviewAndSubmit"
  ];
  public stepsIndex: { [id: string]: number } = {
    "gettingStarted": 0,
    "personalInformation": 1,
    "documents": 2,
    "ohaExam": 3,
    "feeInformation": 4,
    "reviewAndSubmit": 5
  };
  public showStartButton: boolean = false;
  public hasApplication: boolean = false;
  public licenseLocked: boolean = false;
  public licenseDocumentsValid: boolean = true;
  public applicantDocumentsValid: boolean = true;
  public additionalDocumentsValid: boolean = true;
  public applicationValid: boolean = false;
  public step: number = 0;
  public backgroundExpiring: boolean = false;
  public renewalStep: number = 0;
  public facilitatorLicenses: IFacilitatorLicense[] = [];
  public facilitatorLicense: IFacilitatorLicense = {
    id: 0,
    userId: '',
    licenseId: '',
    status: '',
    canRenew: false,
    canOperate: false,
    reducedRequest: false,
    electronicSignature: '',
    attestation: false,
    directoryConsent: null,
    trainingProgram: '',
    trainingDate: '',
    applicationDate: '',
    approvedDate: '',
    renewedDate: '',
    expirationDate: '',
    passedExam: false,
    examDate: undefined,
    applicants: [],
    licenseDocuments: [],
    examAttempts: [],
    payments: [],
    isRenewal: false,
    timely: false,
    documentPageCorrect: null,
    directoryConsentWebsite: false,
    directoryConsentEmail: false,
    directoryConsentPhone: false,
    directoryPhone: '',
    directoryEmail: '',
    directoryWebsite: '',
    directoryPreferredName: false,
    documentPageCorrectReason: ''
  }
  private letterArray: string[] = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'zz',
    'aa', 'bb', 'cc', 'dd', 'ee', 'ff', 'gg', 'hh', 'ii', 'jj', 'kk', 'll', 'mm', 'nn', 'oo', 'pp', 'qq', 'rr', 'ss', 'tt', 'uu', 'vv', 'ww', 'xx', 'yy', 'zz'
  ]
  public editingResidenceId: number = 0;
  public residenceHistoryIncomplete: boolean = false;
  public examMap: IExamQuestion[] = [];
  public examAttempt: IExamAttempt = {
    score: 0,
    passed: false,
    dateTaken: '',
    valid: false,
    facilitatorLicenseId: 0,
    examQuestions: []
  };
  public renewing: boolean = false;
  public trainingPrograms: string[] =[];
  public filteredOptions: Observable<string[]>;
  public examReferenceIds: string[] = [];
  public examPageKey: { [examReferenceId: string]: string } = {};
  public referenceIds: string[] = [];
  public routeSubscription: Subscription;
  public documentTypes: IDocumentType[] = [];
  public documentTypeReference: { [id: number]: string } = {};
  public missingTypes: { [type: number]: boolean } = {};
  public trainingCertificate: string = 'Training Certificate';
  public socialEquityPlan: string = 'Social Equity Plan';
  public personalIdentification: string = 'Personal Identification';
  public reducedFee: string = 'Reduced Fee';
  public trainingCertificateType: number = 0;
  public socialEquityPlanType: number = 0;
  public personalIdentificationType: number = 0;
  public reducedFeeType: number = 0;
  public updatedEquityPlanType: number = 0;
  public equityPlanEvaluationType: number = 0;
  public deniedLicense: string = 'Previous Denied License Information';
  public subjectToDiscipline: string = 'Previous License Discipline or Revocation Information';
  public suedForDamages: string = 'Previously Sued Information';
  public settledAllegations: string = 'Previous License Allegations or Claims';
  public allegedAbuse: string = 'Previous Alleged Abuse of Child or Adult';
  public updatedEquityPlan: string = "Updated Social Equity Plan";
  public equityPlanEvaluation: string = "Social Equity Plan Evaluation";
  public deniedLicenseType: number = 0;
  public subjectToDisciplineType: number = 0;
  public suedForDamagesType: number = 0;
  public settledAllegationsType: number = 0;
  public allegedAbuseType: number = 0;
  public cardColor: string = this.sharedService.purple + '30';
  public startedRenewal: boolean = false;
  public canApplyStatuses: string[] = [this.sharedService.denied, this.sharedService.expired, this.sharedService.withdrawn, this.sharedService.revoked, this.sharedService.closed, this.sharedService.surrendered];
  public applicantForm = new UntypedFormGroup({
    ssn: new UntypedFormControl(""),
    noSsn: new UntypedFormControl(false),
    ssnNoAttestation: new UntypedFormControl(false),
    ssnAttestation: new UntypedFormControl(false),
    legalFirstName: new UntypedFormControl("", [Validators.required]),
    legalMiddleName: new UntypedFormControl(""),
    legalLastName: new UntypedFormControl("", [Validators.required]),
    preferredFirstName: new UntypedFormControl(""),
    preferredMiddleName: new UntypedFormControl(""),
    preferredLastName: new UntypedFormControl(""),
    alternateNames: new UntypedFormControl(""),
    physicalStreet: new UntypedFormControl("", [Validators.required]),
    // physicalAptUnitSuite: new FormControl(""),
    physicalCity: new UntypedFormControl("", [Validators.required]),
    physicalZip: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    oregonResidency: new UntypedFormControl(false, Validators.requiredTrue),
    highSchoolDiploma: new UntypedFormControl(false, Validators.requiredTrue),
    physicalCounty: new UntypedFormControl(""),
    mailingIsPhysical: new UntypedFormControl(false),
    mailingStreet: new UntypedFormControl(""),
    //mailingAptUnitSuite: new FormControl(""),
    mailingCity: new UntypedFormControl(""),
    mailingState: new UntypedFormControl(""),
    mailingZip: new UntypedFormControl("", [Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    mailingCounty: new UntypedFormControl(""),
    phone: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
    alternatePhone: new UntypedFormControl("", [Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
    email: new UntypedFormControl("", [Validators.required, Validators.email]),
    confirmEmail: new UntypedFormControl("", [Validators.required, Validators.email]),
    language: new UntypedFormControl(""),
    previousLicense: new UntypedFormControl(false),
    affiliatedLicenses: new UntypedFormControl(''),
    dob: new UntypedFormControl("", [Validators.required]),
    heldLicense: new UntypedFormControl(Validators.required),
    licensesHeld: new UntypedFormControl(Validators.required),
    deniedLicense: new UntypedFormControl(Validators.required),
    subjectToDiscipline: new UntypedFormControl(Validators.required),
    suedForDamages: new UntypedFormControl(Validators.required),
    settledAllegations: new UntypedFormControl(Validators.required),
    allegedAbuse: new UntypedFormControl(Validators.required),
    substantiatedAllegation: new UntypedFormControl(Validators.required),
    changeInConvictionHistory: new UntypedFormControl(false),
    applicantPageCorrect: new UntypedFormControl(),
    applicantPageCorrectReason: new UntypedFormControl(),
  }, {
    validators: [
      this.mailingStreetValidator(),
      this.mailingCityValidator(),
      this.mailingStateValidator(),
      this.mailingZipValidator(),
      this.emailValidator(),
      this.ssnValidator(),
      this.ssnAttestationValidator(),
      this.previousLicenseValidator(),
      this.heldLicenseValidator(),
      this.deniedLicenseValidator(),
      this.subjectToDisciplineValidator(),
      this.suedForDamagesValidator(),
      this.settledAllegationsValidator(),
      this.allegedAbuseValidator(),
      this.heldLicensesValidator(),
      this.substantiatedAllegationValidator(),
      this.applicantPageCorrectValidator()
    ]
  });

  public documentsForm = new UntypedFormGroup({
    trainingProgram: new UntypedFormControl('', Validators.required),
    trainingDate: new UntypedFormControl('', Validators.required),
    trainingCertificate: new UntypedFormControl(false),
    socialEquityPlan: new UntypedFormControl(false),
    updatedEquityPlan: new UntypedFormControl(false),
    equityPlanEvaluation: new UntypedFormControl(false),
    reducedFee: new UntypedFormControl(false),
    documentPageCorrect: new UntypedFormControl(),
    documentPageCorrectReason: new UntypedFormControl(),
  }, {
    validators: [
      this.documentsCorrectValidator()
    ]
  });

  public licenseForm = new UntypedFormGroup({
    passedExam: new UntypedFormControl(""),
    attestation: new UntypedFormControl(false, [Validators.requiredTrue]),
    electronicSignature: new UntypedFormControl("", [Validators.required]),
    directoryConsent: new UntypedFormControl("", [Validators.required]),
    directoryConsentWebsite: new UntypedFormControl(false),
    directoryConsentEmail: new UntypedFormControl(false),
    directoryConsentPhone: new UntypedFormControl(false),
    directoryPhone: new UntypedFormControl(""),
    directoryEmail: new UntypedFormControl(""),
    directoryWebsite: new UntypedFormControl(''),
    directoryPreferredName: new UntypedFormControl(false)
  }, {
    validators: [
      this.directoryConsentWebsiteValidator(),
      this.directoryConsentEmailValidator(),
      this.directoryConsentPhoneValidator(),
    ]
  });

  constructor(private facilitatorService: FacilitatorService,
    public sharedService: SharedService,
    public examService: ExamService,
    private router: Router,
    public paymentService: PaymentService,
    public toastr: ToastrService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.facilitatorService.getApprovedTrainingPrograms().subscribe(
      response => this.trainingPrograms = response,
      error => console.log('error', error),
      () => {
        this.filteredOptions = this.documentsForm.get("trainingProgram").valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value || '')),
        );
      }
    );
    this.loadingFacilitator = true;
    this.getDocumentTypes();
    this.facilitatorService.getFacilitatorLicenses().subscribe(
      response => this.facilitatorLicenses = response,
      error => console.log('error', error),
      () => {

        if(this.facilitatorLicenses.length === 0 || this.canApplyAgain)
        {
          this.showStartButton = true;
        }
        if (this.sharedService.userProfile.userId === null) {
          this.sharedService.getUserInfo().subscribe(
            response => this.sharedService.userProfile = response,
            error => console.log('error', error),
            () => {
              this.loadingFacilitator = false;
              this.getFacilitatorLicense();
              this.sharedService.toggleTheme();
              this.updateUserState();
            }
          );
        }
        else {
          this.loadingFacilitator = false;
          this.getFacilitatorLicense();
          this.updateUserState();
        }
        this.GetRandomizedExamMap();
      }
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.trainingPrograms.filter(option => option.toLowerCase().includes(filterValue));
  }

  getDocumentTypes(): void {
    this.facilitatorService.getFacilitatorDocumentTypes().subscribe(
      response => this.documentTypes = response,
      error => console.log('error', error),
      () => {
        this.trainingCertificateType = this.documentTypes.find(dt => dt.type === this.trainingCertificate).id;
        this.socialEquityPlanType = this.documentTypes.find(dt => dt.type === this.socialEquityPlan).id;
        this.personalIdentificationType = this.documentTypes.find(dt => dt.type === this.personalIdentification).id;
        this.reducedFeeType = this.documentTypes.find(dt => dt.type === this.reducedFee).id;
        this.deniedLicenseType = this.documentTypes.find(dt => dt.type === this.deniedLicense).id;
        this.subjectToDisciplineType = this.documentTypes.find(dt => dt.type === this.subjectToDiscipline).id;
        this.suedForDamagesType = this.documentTypes.find(dt => dt.type === this.suedForDamages).id;
        this.settledAllegationsType = this.documentTypes.find(dt => dt.type === this.settledAllegations).id;
        this.allegedAbuseType = this.documentTypes.find(dt => dt.type === this.allegedAbuse).id;
        this.updatedEquityPlanType = this.documentTypes.find(dt => dt.type === this.updatedEquityPlan).id;
        this.equityPlanEvaluationType = this.documentTypes.find(dt => dt.type === this.equityPlanEvaluation).id;

        this.documentTypeReference[this.deniedLicenseType] = this.deniedLicense;
        this.documentTypeReference[this.subjectToDisciplineType] = this.subjectToDiscipline;
        this.documentTypeReference[this.suedForDamagesType] = this.suedForDamages;
        this.documentTypeReference[this.settledAllegationsType] = this.settledAllegations;
        this.documentTypeReference[this.allegedAbuseType] = this.allegedAbuse;
        this.documentTypeReference[this.trainingCertificateType] = this.trainingCertificate;
        this.documentTypeReference[this.socialEquityPlanType] = this.socialEquityPlan;
        this.documentTypeReference[this.personalIdentificationType] = this.personalIdentification;
        this.documentTypeReference[this.reducedFeeType] = this.reducedFee;
        this.documentTypeReference[this.updatedEquityPlanType] = this.updatedEquityPlan;
        this.documentTypeReference[this.equityPlanEvaluationType] = this.equityPlanEvaluation;

        this.missingTypes[this.trainingCertificateType] = false;
        this.missingTypes[this.socialEquityPlanType] = false;
        this.missingTypes[this.personalIdentificationType] = false;
      }
    );
  }

  setupRouteSubscription(): void {
    this.routeSubscription = this.router.events
    .pipe(filter((event) => event instanceof NavigationStart))
    .subscribe((e) => {
      this.updateApplicant();
      this.updateLicense();
    });
  }

  getFacilitatorLicense(): void {
    if (this.facilitatorLicenses.length === 0) {
      this.hasApplication = false;
      this.viewForm = true;
    }
    else {
      this.hasApplication = true;
      if(this.facilitatorLicenses.length === 1){
        let currentLicense = this.facilitatorLicenses.find(fl => fl.status === 'Pending');
        if (currentLicense) {
          this.viewApplication(currentLicense);
          this.viewForm = true;
        }
      }
    }
  }

  viewApplication(license: IFacilitatorLicense): void {
    this.showEditLoading = true;
    this.facilitatorLicense = license;
    this.licenseLocked = this.facilitatorLicense.status !== this.sharedService.pending;
    if (this.licenseLocked) {
      this.step = 0;
      this.applicantForm.disable();
      this.documentsForm.disable();
      this.licenseForm.disable();
    }
    else{
      this.applicantForm.enable();
      this.documentsForm.enable();
      this.licenseForm.enable();
      this.setupRouteSubscription();
    }
    this.updateApplicantForm();
    this.updateLicenseForm();
    if (this.step === 5) {
      this.licenseForm.markAsDirty();
      this.validateApplication();
    }
    this.viewForm = true;
    setTimeout(() => this.showEditLoading = false, 500);
  }

  licenseHasUnpaidFees(license: IFacilitatorLicense): boolean {
    if (license != null && license.payments != null && license.payments.length > 0) {
      if (license.payments.filter(p => !p.paid).length > 0) {
        return true;
      }
    }
    return false;
  }

  changeAttestation(agree: boolean): void {
    if (agree) {
      this.applicantForm.patchValue({
        ssnNoAttestation: false
      });
    }
    if (!agree) {
      this.applicantForm.patchValue({
        ssnAttestation: false
      });
    }

  }
  requestWithdraw(license: IFacilitatorLicense): void{
    this.sharedService.openConfirm("Are you sure you want to request to withdraw your application?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.facilitatorService.requestWithdraw(license.id).subscribe(
            response => {
              let index = this.facilitatorLicenses.findIndex(fl => fl.id == license.id);
              this.facilitatorLicenses[index] = response;
            },
            error => console.log('error', error),
          )
    }});
  }

  requestSurrender(license: IFacilitatorLicense): void{
    this.sharedService.openConfirm("Are you sure you want to request to surrender your application?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.facilitatorService.requestSurrender(license.id).subscribe(
            response => {
              let index = this.facilitatorLicenses.findIndex(fl => fl.id == license.id);
              this.facilitatorLicenses[index] = response;
            },
            error => console.log('error', error),
          )
    }});
  }
  closeApplication(): void {
    if(this.facilitatorLicense.status == 'Pending' || this.facilitatorLicense.status == this.sharedService.pendingRenewal){
    this.sharedService.openConfirm("Some of your changes may be lost. Continue?");
      this.sharedService.confirmed().subscribe(
        confirmed => {
          if(confirmed){
    this.facilitatorLicense = {
      id: 0,
      userId: '',
      licenseId: '',
      status: '',
      canRenew: false,
      canOperate: false,
      reducedRequest: false,
      electronicSignature: '',
      attestation: false,
      directoryConsent: null,
      directoryConsentWebsite: false,
      directoryConsentEmail: false,
      directoryConsentPhone: false,
      directoryPhone: '',
      directoryEmail: '',
      directoryWebsite: '',
      directoryPreferredName: false,
      trainingProgram: '',
      trainingDate: '',
      applicationDate: '',
      approvedDate: '',
      renewedDate: '',
      expirationDate: '',
      passedExam: false,
      examDate: undefined,
      applicants: [],
      licenseDocuments: [],
      examAttempts: [],
      payments: [],
      isRenewal: false,
      timely: false,
      documentPageCorrect: null,
      documentPageCorrectReason: ''
    }
    if(!this.licenseLocked){
      this.routeSubscription.unsubscribe();
    }
    this.viewForm = false;
    this.renewing = false;
  }});
  }
  else{
    this.facilitatorLicense = {
      id: 0,
      userId: '',
      licenseId: '',
      status: '',
      canRenew: false,
      canOperate: false,
      reducedRequest: false,
      electronicSignature: '',
      attestation: false,
      directoryConsent: null,
      directoryConsentWebsite: false,
      directoryConsentEmail: false,
      directoryConsentPhone: false,
      directoryPhone: '',
      directoryEmail: '',
      directoryWebsite: '',
      directoryPreferredName: false,
      trainingProgram: '',
      trainingDate: '',
      applicationDate: '',
      approvedDate: '',
      renewedDate: '',
      expirationDate: '',
      passedExam: false,
      examDate: undefined,
      applicants: [],
      licenseDocuments: [],
      examAttempts: [],
      payments: [],
      isRenewal: false,
      timely: false,
      documentPageCorrect: null,
      documentPageCorrectReason: ''
    }
    if(!this.licenseLocked){
      this.routeSubscription.unsubscribe();
    }
    this.viewForm = false;
    this.renewing = false;
  }
}

  updateUserState(): void {
    if (this.facilitatorSteps.includes(this.sharedService.userProfile.currentStep)) {
      this.step = this.stepsIndex[this.sharedService.userProfile.currentStep];
      if (this.step === 5 && this.facilitatorLicense.status === this.sharedService.pending) {
        this.validateApplication();
      }
    }
    if (this.sharedService.userProfile.currentPage !== "license/facilitator") {
      this.sharedService.userProfile.currentPage = "license/facilitator";
      this.sharedService.updateUserProfileState();
    }
  }

  startApplication(): void {
    this.showStartButton = false;
    this.sharedService.openConfirm("Would you like to open a facilitator application?");
    this.sharedService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.facilitatorService.createFacilitatorLicense().subscribe(
          response => this.facilitatorLicense = response,
          error => console.log('error', error),
          () => {
            this.setupRouteSubscription();
            this.hasApplication = true;
            this.facilitatorLicenses.push(this.facilitatorLicense);
            this.updateApplicantForm();
            this.updateLicenseForm();
          }
        );
      }
      else {
        this.showStartButton = true;
        this.hasApplication = false;
      }
    });
  }

  updateApplicantForm(): void {
    let applicant: IApplicant = this.facilitatorLicense.applicants[0];
    if (applicant.ssnAttestation === false) {
      this.applicantForm.patchValue({
        ssnNoAttestation: true
      });
    }
    this.applicantForm.patchValue({
      ssn: applicant.ssn,
      noSsn: applicant.noSSN,
      ssnAttestation: applicant.ssnAttestation,
      legalFirstName: applicant.legalFirstName,
      legalMiddleName: applicant.legalMiddleName,
      legalLastName: applicant.legalLastName,
      preferredFirstName: applicant.preferredFirstName,
      preferredMiddleName: applicant.preferredMiddleName,
      preferredLastName: applicant.preferredLastName,
      alternateNames: applicant.alternateNames,
      physicalStreet: applicant.physicalStreet,
      //physicalAptUnitSuite: applicant.physicalAptUnitSuite,
      physicalCity: applicant.physicalCity,
      physicalZip: applicant.physicalZip,
      physicalCounty: applicant.physicalCounty,
      oregonResidency: applicant.oregonResidency,
      highSchoolDiploma: applicant.highSchoolDiploma,
      mailingIsPhysical: applicant.mailingIsPhysical,
      mailingStreet: applicant.mailingStreet,
      // mailingAptUnitSuite: applicant.mailingAptUnitSuite,
      mailingCity: applicant.mailingCity,
      mailingState: applicant.mailingState,
      mailingZip: applicant.mailingZip,
      mailingCounty: applicant.mailingCounty,
      phone: applicant.phone,
      alternatePhone: applicant.alternatePhone,
      email: applicant.email,
      confirmEmail: applicant.email,
      language: applicant.language,
      previousLicense: applicant.previousLicense,
      affiliatedLicenses: applicant.affiliatedLicenses,
      licensesHeld: applicant.licensesHeld,
      heldLicense: applicant.heldLicense,
      deniedLicense: applicant.deniedLicense,
      subjectToDiscipline: applicant.subjectToDiscipline,
      suedForDamages: applicant.suedForDamages,
      settledAllegations: applicant.settledAllegations,
      allegedAbuse: applicant.allegedAbuse,
      substantiatedAllegation: applicant.substantiatedAllegation,
      identification: false,
      changeInConvictionHistory: applicant.changeInConvictionHistory,
      applicantPageCorrect: applicant.applicantPageCorrect,
      applicantPageCorrectReason: applicant.applicantPageCorrectReason
    });
    if (applicant.dob != null && applicant.dob.length > 0) {
      this.applicantForm.patchValue({
        dob: new Date(applicant.dob)
      });
    }
  }

  updateLicenseForm(): void {
    this.documentsForm.patchValue({
      trainingProgram: this.facilitatorLicense.trainingProgram,
      documentPageCorrect: this.facilitatorLicense.documentPageCorrect,
      documentPageCorrectReason: this.facilitatorLicense.documentPageCorrectReason
    });
    if (this.facilitatorLicense.trainingDate != null && this.facilitatorLicense.trainingDate.length > 0) {
      this.documentsForm.get('trainingDate').patchValue(new Date(this.facilitatorLicense.trainingDate));
    }
    this.licenseForm.patchValue({
      passedExam: this.facilitatorLicense.passedExam,
      electronicSignature: this.facilitatorLicense.electronicSignature,
      attestation: this.facilitatorLicense.attestation,
      directoryConsent: this.facilitatorLicense.directoryConsent,
      directoryConsentWebsite: this.facilitatorLicense.directoryConsentWebsite,
      directoryConsentEmail: this.facilitatorLicense.directoryConsentEmail,
      directoryConsentPhone: this.facilitatorLicense.directoryConsentPhone,
      directoryPhone: this.facilitatorLicense.directoryPhone,
      directoryEmail: this.facilitatorLicense.directoryEmail,
      directoryWebsite: this.facilitatorLicense.directoryWebsite,
      directoryPreferredName: this.facilitatorLicense.directoryPreferredName
    });
  }

  public get canApplyAgain(): boolean {
    let canApply: boolean = true;
    this.facilitatorLicenses.forEach(license => {
      if (!this.canApplyStatuses.includes(license.status)) {
        canApply = false;
      }
    });
    return canApply;
  }

  hasPaidFee(license: IFacilitatorLicense, paymentType: string): boolean {
    if (license == null || license.payments == null || license.payments.length == 0) {

      return false;
    }

    let appFee = license.payments.find(tp => tp.paid && tp.type == paymentType);

    if(paymentType == 'LicenseFee')
      {
         appFee = license.payments.find(tp => tp.paid && (tp.type == 'LicenseFee' || tp.type == 'ReducedLicenseFee'));
      }

    if (appFee == null || appFee == undefined) {
      return false;
    }

    return true;
  }

  getPaymentFeeStatus(license: IFacilitatorLicense, paymentType: string): string {
    if (license == null || license.payments == null || license.payments.length == 0) {
      return "";
    }

    let appFee = license.payments.find(tp => tp.type == paymentType);

    if (appFee == null || appFee == undefined) {
      return "";
    }

    return appFee.status;
  }

  getFeeCleared(license: IFacilitatorLicense, paymentType: string): string {
    if (license == null || license.payments == null || license.payments.length == 0) {
      return "";
    }

    let appFee = license.payments.find(tp => tp.type == paymentType);

    if (appFee == null || appFee == undefined) {
      return "";
    }

    return appFee.paymentCleared;
  }

  //Applicant Custom Validators
  documentsCorrectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const pageCorrect = control.value.documentPageCorrect;
      const reason = control.value.documentPageCorrectReason;
      if (!pageCorrect && this.facilitatorLicense.isRenewal) {
        return (reason !== null && reason !== '' && reason !== undefined) ? null : { documentPageCorrectReasonRequired: true };
      }
      return null;
    };
  }

  validateDocumentsCorrect(): boolean {
    if (this.documentsForm.hasError('documentPageCorrectReasonRequired') && this.documentsForm.get('documentPageCorrect').value === false) {
      this.documentsForm.get('documentPageCorrectReason').setErrors(['required']);
      return true;
    }
    this.documentsForm.get('documentPageCorrectReason').clearValidators();
    this.documentsForm.get('documentPageCorrectReason').updateValueAndValidity();
    return false;
  }

  applicantPageCorrectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const pageCorrect = control.value.applicantPageCorrect;
      const reason = control.value.applicantPageCorrectReason;
      if (!pageCorrect && this.facilitatorLicense.isRenewal) {
        return (reason !== null && reason !== '' && reason !== undefined) ? null : { applicantPageCorrectReasonRequired: true };
      }
      return null;
    };
  }

  validateApplicantPageCorrect(): boolean {
    if (this.applicantForm.hasError('applicantPageCorrectReasonRequired') && this.applicantForm.get('applicantPageCorrect').value === false) {
      this.applicantForm.get('applicantPageCorrectReason').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('applicantPageCorrectReason').clearValidators();
    this.applicantForm.get('applicantPageCorrectReason').updateValueAndValidity();
    return false;
  }

  ssnValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const ssn = control.value.ssn;
      const noSsn = control.value.noSsn;
      if (noSsn) {
        return null;
      }
      if(this.renewing)
        return null;
      return (ssn !== null && ssn !== '' && ssn !== undefined) ? null : { ssnRequired: true };
    }
  }

  ssnAttestationValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const ssn = control.value.ssnAttestation;
      const noSsn = control.value.ssnNoAttestation;
      return (ssn || noSsn) ? null : { ssnAttestationRequired: true };
    }
  }

  validateSsn(): boolean {
    if (this.applicantForm.hasError('ssnRequired') && this.applicantForm.get('ssn').touched) {
      this.applicantForm.get('ssn').setErrors(['ssnRequired']);
      return true;
    }
    this.applicantForm.get('ssn').clearValidators();
    this.applicantForm.get('ssn').updateValueAndValidity();
    return false;
  }

  validAttestation(): void {
    if (this.applicantForm.get('ssnAttestation').value === null) {
      this.applicantForm.get('ssnAttestation').setErrors(['ssnAttestationRequired']);
    }
  }

  validateSsnAttestation(): boolean {
    if (this.applicantForm.hasError('ssnAttestationRequired') && this.applicantForm.get('ssnAttestation').touched) {
      this.applicantForm.get('ssnAttestation').setErrors(['ssnAttestationRequired']);
      return true;
    }
    this.applicantForm.get('ssnAttestation').clearValidators();
    this.applicantForm.get('ssnAttestation').updateValueAndValidity();
    return false;
  }

  heldLicenseValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const heldLicense = control.value.heldLicense;

      if(heldLicense != null){
        return null;
      }
      return { heldLicenseRequired: true };
    }
  }

  validateHeldLicense(): boolean {
    if (this.applicantForm.hasError('heldLicenseRequired') && this.applicantForm.get('heldLicense').touched) {
      this.applicantForm.get('heldLicense').setErrors(['heldLicenseRequired']);
      return true;
    }
    this.applicantForm.get('heldLicense').clearValidators();
    this.applicantForm.get('heldLicense').updateValueAndValidity();
    return false;
  }

  deniedLicenseValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const deniedLicense = control.value.deniedLicense;

      if(deniedLicense != null){
        return null;
      }

      return { deniedLicenseRequired: true };
    }
  }

  validateDeniedLicense(): boolean {
    if (this.applicantForm.hasError('deniedLicenseRequired') && this.applicantForm.get('deniedLicense').touched) {
      this.applicantForm.get('deniedLicense').setErrors(['deniedLicenseRequired']);
      return true;
    }
    this.applicantForm.get('deniedLicense').clearValidators();
    this.applicantForm.get('deniedLicense').updateValueAndValidity();
    return false;
  }

  subjectToDisciplineValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const subjectToDiscipline = control.value.subjectToDiscipline;

      if(subjectToDiscipline != null){
        return null;
      }

      return { subjectToDisciplineRequired: true };
    }
  }

  validateSubjectToDiscipline(): boolean {
    if (this.applicantForm.hasError('subjectToDisciplineRequired') && this.applicantForm.get('subjectToDiscipline').touched) {
      this.applicantForm.get('subjectToDiscipline').setErrors(['subjectToDisciplineRequired']);
      return true;
    }
    this.applicantForm.get('subjectToDiscipline').clearValidators();
    this.applicantForm.get('subjectToDiscipline').updateValueAndValidity();
    return false;
  }

  suedForDamagesValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const suedForDamages = control.value.suedForDamages;

      if(suedForDamages != null){
        return null;
      }

      return { suedForDamagesRequired: true };
    }
  }

  validateSuedForDamages(): boolean {
    if (this.applicantForm.hasError('suedForDamagesRequired') && this.applicantForm.get('suedForDamages').touched) {
      this.applicantForm.get('suedForDamages').setErrors(['suedForDamagesRequired']);
      return true;
    }
    this.applicantForm.get('suedForDamages').clearValidators();
    this.applicantForm.get('suedForDamages').updateValueAndValidity();
    return false;
  }

  settledAllegationsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const settledAllegations = control.value.settledAllegations;

      if(settledAllegations != null){
        return null;
      }

      return { settledAllegationsRequired: true };
    }
  }

  validateSettledAllegations(): boolean {
    if (this.applicantForm.hasError('settledAllegationsRequired') && this.applicantForm.get('settledAllegations').touched) {
      this.applicantForm.get('settledAllegations').setErrors(['settledAllegationsRequired']);
      return true;
    }
    this.applicantForm.get('settledAllegations').clearValidators();
    this.applicantForm.get('settledAllegations').updateValueAndValidity();
    return false;
  }

  allegedAbuseValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const allegedAbuse = control.value.allegedAbuse;

      if(allegedAbuse != null){
        return null;
      }

      return { allegedAbuseRequired: true };
    }
  }

  validateAllegedAbuse(): boolean {
    if (this.applicantForm.hasError('allegedAbuseRequired') && this.applicantForm.get('allegedAbuse').touched) {
      this.applicantForm.get('allegedAbuse').setErrors(['allegedAbuseRequired']);
      return true;
    }
    this.applicantForm.get('allegedAbuse').clearValidators();
    this.applicantForm.get('allegedAbuse').updateValueAndValidity();
    return false;
  }

  heldLicensesValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const heldLicense = control.value.heldLicense;
      const licensesHeld = control.value. licensesHeld

      if(heldLicense == null || heldLicense == false){
        return null;
      }

      return (licensesHeld != null && licensesHeld != '') ? null : { licensesHeldRequired: true };
    }
  }

  validateHeldLicenses(): boolean {
    if (this.applicantForm.hasError('licensesHeldRequired') && this.applicantForm.get('licensesHeld').touched) {
      this.applicantForm.get('licensesHeld').setErrors(['licensesHeldRequired']);
      return true;
    }
    this.applicantForm.get('licensesHeld').clearValidators();
    this.applicantForm.get('licensesHeld').updateValueAndValidity();
    return false;
  }

  substantiatedAllegationValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const allegedAbuse = control.value.allegedAbuse;
      const substantiatedAllegation = control.value.substantiatedAllegation

      if(allegedAbuse == null || allegedAbuse == false){
        return null;
      }

      return (substantiatedAllegation == false || substantiatedAllegation == true) ? null : { substantiatedAllegationRequired: true };
    }
  }

  validateSubstantiatedAllegation(): boolean {
    if (this.applicantForm.hasError('substantiatedAllegationRequired') && this.applicantForm.get('substantiatedAllegation').touched) {
      this.applicantForm.get('substantiatedAllegation').setErrors(['substantiatedAllegationRequired']);
      return true;
    }
    this.applicantForm.get('substantiatedAllegation').clearValidators();
    this.applicantForm.get('substantiatedAllegation').updateValueAndValidity();
    return false;
  }

  previousLicenseValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasPreviousLicense = control.value.previousLicense;
      const affiliatedLicenses = control.value.affiliatedLicenses;
      if (!hasPreviousLicense) {
        return null;
      }
      return (affiliatedLicenses !== null && affiliatedLicenses !== '' && affiliatedLicenses !== undefined) ? null : { licensesRequired: true };
    }
  }

  validatePreviousLicense(): boolean {
    if (this.applicantForm.hasError('licensesRequired') && this.applicantForm.get('affiliatedLicenses').touched) {
      this.applicantForm.get('affiliatedLicenses').setErrors(['licensesRequired']);
      return true;
    }
    this.applicantForm.get('affiliatedLicenses').clearValidators();
    this.applicantForm.get('affiliatedLicenses').updateValueAndValidity();
    return false;
  }

  mailingIsSame(): void {
    this.applicantForm.get('mailingStreet').updateValueAndValidity();
    //this.applicantForm.get('mailingAptUnitSuite').updateValueAndValidity
    this.applicantForm.get('mailingCity').updateValueAndValidity();
    this.applicantForm.get('mailingState').updateValueAndValidity();
    this.applicantForm.get('mailingZip').updateValueAndValidity();
  }

  emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email = control.value.email;
      const confirmation = control.value.confirmEmail;
      return email === confirmation ? null : { emailConfirmed: true };
    }
  }

  validateEmail(): boolean {
    if (this.applicantForm.hasError('emailConfirmed') && this.applicantForm.get('confirmEmail').touched) {
      this.applicantForm.get('confirmEmail').setErrors(['emailConfirmed']);
      return true;
    }
    this.applicantForm.get('confirmEmail').clearValidators();
    this.applicantForm.get('confirmEmail').updateValueAndValidity();
    return false;
  }

  mailingStreetValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const street = control.value.mailingStreet;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (street !== null && street !== '' && street !== undefined) ? null : { mailingStreetRequired: true };
      }
      return null;
    };
  }

  validateMailingStreet(): boolean {
    if (this.applicantForm.hasError('mailingStreetRequired') && this.applicantForm.get('mailingStreet').touched) {
      this.applicantForm.get('mailingStreet').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingStreet').clearValidators();
    this.applicantForm.get('mailingStreet').updateValueAndValidity();
    return false;
  }

  mailingCityValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const city = control.value.mailingCity;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (city !== null && city !== '' && city !== undefined) ? null : { mailingCityRequired: true };
      }
      return null;
    };
  }

  validateMailingCity(): boolean {
    if (this.applicantForm.hasError('mailingCityRequired') && this.applicantForm.get('mailingCity').touched) {
      this.applicantForm.get('mailingCity').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingCity').clearValidators();
    this.applicantForm.get('mailingCity').updateValueAndValidity();
    return false;
  }

  mailingStateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const state = control.value.mailingState;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (state !== null && state !== '' && state !== undefined) ? null : { mailingStateRequired: true };
      }
      return null;
    };
  }

  validateMailingState(): boolean {
    if (this.applicantForm.hasError('mailingStateRequired') && this.applicantForm.get('mailingState').touched) {
      this.applicantForm.get('mailingState').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingState').clearValidators();
    this.applicantForm.get('mailingState').updateValueAndValidity();
    return false;
  }

  mailingZipValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const zip = control.value.mailingZip;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (zip !== null && zip !== '' && zip !== undefined) ? null : { mailingZipRequired: true };
      }
      return null;
    };
  }

  validateMailingZip(): boolean {
    if (this.applicantForm.hasError('mailingZipRequired') && this.applicantForm.get('mailingZip').touched) {
      this.applicantForm.get('mailingZip').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingZip').clearValidators();
    this.applicantForm.get('mailingZip').updateValueAndValidity();
    return false;
  }

  directoryConsentWebsiteValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const websiteConsent = control.value.directoryConsentWebsite;
      const website = control.value.directoryWebsite;
      const directoryConsent = control.value.directoryConsent;
      if (websiteConsent && directoryConsent) {
        return (website !== null && website !== '' && website !== undefined) ? null : { directoryWebsiteRequired: true };
      }
      return null;
    };
  }

  validateDirectoryConsentWebsite(): boolean {
    const websiteControl = this.licenseForm.get('directoryWebsite');
    const websiteConsent = this.licenseForm.get('directoryConsentWebsite').value;
    const directoryConsent = this.licenseForm.get('directoryConsent').value;

    if(websiteConsent && directoryConsent)
    {
      if (websiteControl.value === '' || websiteControl.value === null || websiteControl.value === undefined) {
        websiteControl.setErrors({ required: true });
        return true;
      }

      const pattern = /^(http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]/i;
      if (websiteControl.value !== '' && websiteControl.value !== null && websiteControl.value !== undefined)
        if (!pattern.test(websiteControl.value)) {
           websiteControl.setErrors({ pattern: true });
           return true;
        }
    }

    websiteControl.clearValidators();
    websiteControl.updateValueAndValidity();
    return false;
  }

  directoryConsentEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const emailConsent = control.value.directoryConsentEmail;
      const email = control.value.directoryEmail;
      const directoryConsent = control.value.directoryConsent;
      if (emailConsent && directoryConsent) {
        return (email !== null && email !== '' && email !== undefined) ? null : { directoryEmailRequired: true };
      }
      return null;
    };
  }

  validateDirectoryConsentEmail(): boolean {
    if (this.licenseForm.hasError('directoryEmailRequired') && this.licenseForm.get('directoryEmail').value === true) {
      this.licenseForm.get('directoryEmail').setErrors(['required']);
      return true;
    }
    this.licenseForm.get('directoryEmail').clearValidators();
    this.licenseForm.get('directoryEmail').updateValueAndValidity();
    return false;
  }


  directoryConsentPhoneValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const phoneConsent = control.value.directoryConsentPhone;
      const phone = control.value.directoryPhone;
      const directoryConsent = control.value.directoryConsent;
      if (phoneConsent && directoryConsent) {
        return (phone !== null && phone !== '' && phone !== undefined) ? null : { directoryPhoneRequired: true };
      }
      return null;
    };
  }

  validateDirectoryConsentPhone(): boolean {
    if (this.licenseForm.hasError('directoryPhoneRequired') && this.licenseForm.get('directoryPhone').value === true) {
      this.licenseForm.get('directoryPhone').setErrors(['required']);
      return true;
    }
    this.licenseForm.get('directoryPhone').clearValidators();
    this.licenseForm.get('directoryPhone').updateValueAndValidity();
    return false;
  }
  //End of Validators

  //Exam Methods
  submitExam(): void {
    this.examAttempt.facilitatorLicenseId = this.facilitatorLicense.id;
    this.examService.submitExamAttemptMap(this.examAttempt).subscribe(
      response => {
        this.facilitatorLicense.passedExam = response.passed
        this.facilitatorLicense.examAttempts.push(response);
        this.examAttempt = response;
      },
      error => console.log('error', error),
      () => {
        if (this.facilitatorLicense.passedExam) {
          this.facilitatorLicense.examDate = new Date(Date.now());
        }
        this.openExamResultDialog(this.examAttempt);
      }
    );
  }

  getAnswerIndexLetter(elementIndex: number): string {
    return this.letterArray[elementIndex];
  }

  openExamResultDialog(exam: IExamAttempt): void {
    const dialogRef = this.dialog.open(ExamModalComponent, {
      data: exam,
      role: 'dialog',
      ariaLabel: "Exam Results",
      minWidth: !this.sharedService.mobile ? '600px' : '400px',
      maxWidth: !this.sharedService.mobile ? '800px' : '600px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : "",
    });

    dialogRef.afterClosed().subscribe(() => {
      if (!exam.passed) {
        this.examAttempt.examQuestions.forEach(question => {
          question.answered = false;
          question.examAnswers.forEach(answer => {
            answer.selected = false;
          });
        });
        this.examAttempt.valid = false;

        let randomizedQuestions = this.examAttempt.examQuestions.sort(() => Math.random() - 0.5);
        this.examAttempt.examQuestions = randomizedQuestions;
      }
    });
  }

  selectAnswer(answer: IExamAnswer, question: IExamQuestion) {
    question.examAnswers.forEach(a => {
      answer.selected = false;
    });
    question.answered = true;
    answer.selected = true;
    this.checkExamAttempt();
  }

  checkExamAttempt(): void {
    this.examAttempt.valid = true;
    this.examAttempt.examQuestions.forEach(question => {
      if (!question.answered) {
        this.examAttempt.valid = false;
      }
    });
  }

  GetRandomizedExamMap(): void {
    this.examService.GetRandomizedExamMap().subscribe(
      response => this.examAttempt = response,
      error => this.sharedService.consoleLog(error),
      () => {
        this.examAttempt.examQuestions.forEach(question => {
          question.examAnswers.forEach(answer => {
            this.examPageKey[answer.referenceId] = '';
            this.referenceIds.push(answer.referenceId);
          });
        });
      }
    );
  }

  //End of Exam methods
  updateApplicant(): void {
    let applicantInfo = this.applicantForm.value;
    this.facilitatorLicense.applicants[0].ssn = applicantInfo.ssn;
      this.facilitatorLicense.applicants[0].noSSN = applicantInfo.noSsn;
      this.facilitatorLicense.applicants[0].ssnAttestation = applicantInfo.ssnAttestation;
      this.facilitatorLicense.applicants[0].legalFirstName = applicantInfo.legalFirstName;
      this.facilitatorLicense.applicants[0].legalMiddleName = applicantInfo.legalMiddleName;
      this.facilitatorLicense.applicants[0].legalLastName = applicantInfo.legalLastName;
      this.facilitatorLicense.applicants[0].preferredFirstName = applicantInfo.preferredFirstName;
      this.facilitatorLicense.applicants[0].preferredMiddleName = applicantInfo.preferredMiddleName;
      this.facilitatorLicense.applicants[0].preferredLastName = applicantInfo.preferredLastName;
      this.facilitatorLicense.applicants[0].alternateNames = applicantInfo.alternateNames;
      this.facilitatorLicense.applicants[0].physicalState = 'OR';
      this.facilitatorLicense.applicants[0].physicalCity = applicantInfo.physicalCity;
      this.facilitatorLicense.applicants[0].physicalStreet = applicantInfo.physicalStreet;
      //this.facilitatorLicense.applicants[0].physicalAptUnitSuite = applicantInfo.physicalAptUnitSuite,
      this.facilitatorLicense.applicants[0].physicalZip = applicantInfo.physicalZip;
      this.facilitatorLicense.applicants[0].physicalCounty = applicantInfo.physicalCounty;
      this.facilitatorLicense.applicants[0].oregonResidency = applicantInfo.oregonResidency;
      this.facilitatorLicense.applicants[0].highSchoolDiploma = applicantInfo.highSchoolDiploma;
      this.facilitatorLicense.applicants[0].mailingIsPhysical = applicantInfo.mailingIsPhysical;
      this.facilitatorLicense.applicants[0].mailingState = applicantInfo.mailingState;
      this.facilitatorLicense.applicants[0].mailingCity = applicantInfo.mailingCity;
      this.facilitatorLicense.applicants[0].mailingStreet = applicantInfo.mailingStreet;
      // this.facilitatorLicense.applicants[0].mailingAptUnitSuite = applicantInfo.mailingAptUnitSuite,
      this.facilitatorLicense.applicants[0].mailingZip = applicantInfo.mailingZip;
      this.facilitatorLicense.applicants[0].mailingCounty = applicantInfo.mailingCounty;
      this.facilitatorLicense.applicants[0].phone = applicantInfo.phone;
      this.facilitatorLicense.applicants[0].alternatePhone = applicantInfo.alternatePhone;
      this.facilitatorLicense.applicants[0].email = applicantInfo.email;
      this.facilitatorLicense.applicants[0].language = applicantInfo.language;
      this.facilitatorLicense.applicants[0].previousLicense = applicantInfo.previousLicense;
      this.facilitatorLicense.applicants[0].affiliatedLicenses = applicantInfo.affiliatedLicenses;
      this.facilitatorLicense.applicants[0].dob = applicantInfo.dob;

      this.facilitatorLicense.applicants[0].licensesHeld = applicantInfo.licensesHeld;
      this.facilitatorLicense.applicants[0].heldLicense = applicantInfo.heldLicense;
      this.facilitatorLicense.applicants[0].deniedLicense = applicantInfo.deniedLicense;
      this.facilitatorLicense.applicants[0].subjectToDiscipline = applicantInfo.subjectToDiscipline;
      this.facilitatorLicense.applicants[0].suedForDamages = applicantInfo.suedForDamages;
      this.facilitatorLicense.applicants[0].settledAllegations = applicantInfo.settledAllegations;
      this.facilitatorLicense.applicants[0].allegedAbuse  = applicantInfo.allegedAbuse;
      this.facilitatorLicense.applicants[0].substantiatedAllegation = applicantInfo.substantiatedAllegation;

    if (applicantInfo.mailingIsPhysical) {
      this.facilitatorLicense.applicants[0].mailingState = 'OR';
      this.facilitatorLicense.applicants[0].mailingCity = applicantInfo.physicalCity;
      this.facilitatorLicense.applicants[0].mailingStreet = applicantInfo.physicalStreet;
      this.facilitatorLicense.applicants[0].mailingZip = applicantInfo.physicalZip;
      this.facilitatorLicense.applicants[0].mailingCounty = applicantInfo.physicalCounty;

      this.applicantForm.patchValue({
        mailingState: 'OR',
        mailingCity: applicantInfo.physicalCity,
        mailingStreet: applicantInfo.physicalStreet,
        mailingZip: applicantInfo.physicalZip,
        mailingCounty: applicantInfo.physicalCounty,
        // mailingAptUnitSuite: applicantInfo.physicalAptUnitSuite
      });
    }
    this.facilitatorService.updateApplicant(this.facilitatorLicense.applicants[0]);
  }

  updateLicense(): void {
    let trainingProgramInfo = this.documentsForm.value;
    let trainingProgram = trainingProgramInfo.trainingProgram;
    let trainingDate = trainingProgramInfo.trainingDate;
    this.facilitatorLicense.documentPageCorrect = trainingProgramInfo.documentPageCorrect;
    this.facilitatorLicense.documentPageCorrectReason = trainingProgramInfo.documentPageCorrectReason;
    if (trainingProgram !== this.facilitatorLicense.trainingProgram || trainingDate !== this.facilitatorLicense.trainingDate) {
      if(!this.renewing)
      {
        this.facilitatorLicense.trainingProgram = trainingProgram;
        this.facilitatorLicense.trainingDate = trainingDate;
      }
      this.facilitatorService.updateLicense(this.facilitatorLicense);
    }
  }

  uploadLicenseDocument(event: Event): void {
    let documentForm = this.documentsForm.value;
    let types: number[] = [];

    if (documentForm.trainingCertificate) {
      types.push(this.trainingCertificateType);
    }
    if (documentForm.socialEquityPlan) {
      types.push(this.socialEquityPlanType);
    }
    if (documentForm.reducedFee) {
      types.push(this.reducedFeeType);
    }
    if (documentForm.updatedEquityPlan) {
      types.push(this.updatedEquityPlanType);
    }
    if (documentForm.equityPlanEvaluation) {
      types.push(this.equityPlanEvaluationType);
    }

    types.forEach(type => {
      this.missingTypes[type] = false;
    });

    if (types.length > 0) {
      let dirtyFile = (event.target as HTMLInputElement).files[0];
      let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, ''));
      if(this.sharedService.validateFile(file))
      {
        let upload: IDocument = {
          id: 0,
          name: file.name,
          comments: "",
          extenstion: "",
          dateCreated: "",
          dateLastUpdated: "",
          createdBy: "",
          lastUpdatedBy: "",
          parentId: this.facilitatorLicense.id,
          types: types,
          adminOnly: false,
          deprecated: false
        }

        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("document", JSON.stringify(upload));
        this.facilitatorService.uploadDocument(formData, false).subscribe(
          response => this.facilitatorLicense.licenseDocuments.push(response),
          error => {
            (event.target as HTMLInputElement).value = '';
            console.log('error', error);
          },
          () => {
            (event.target as HTMLInputElement).value = '';
            this.validateLicenseDocuments();
            this.documentsForm.patchValue({
              trainingCertificate: false,
              socialEquityPlan: false,
              reducedFee: false,
              updatedEquityPlan: false,
              equityPlanEvaluation: false,
            });
          });
      }
      else
        this.toastr.error("Unsupported File Type");
    }
    else {
      (event.target as HTMLInputElement).value = '';
      this.toastr.error("Please select at least one requirement met by the document");
    }
  }

  uploadAdditionalDocument(event: Event, type: number): void {
    let types: number[] = [type];
    let dirtyFile = (event.target as HTMLInputElement).files[0];
    let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, ''));
    let upload: IDocument = {
      id: 0,
      name: file.name,
      comments: "",
      extenstion: "",
      dateCreated: "",
      dateLastUpdated: "",
      createdBy: "",
      lastUpdatedBy: "",
      parentId: this.facilitatorLicense.applicants[0].id,
      types: types,
      adminOnly: true,
      deprecated: false
    }

    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("document", JSON.stringify(upload));
    this.facilitatorService.uploadDocument(formData, true).subscribe(
      response => this.facilitatorLicense.applicants[0].applicantDocuments.push(response),
      error => {
        (event.target as HTMLInputElement).value = '';
        console.log('error', error);
      },
      () => {
        (event.target as HTMLInputElement).value = '';
        this.validateAdditionalDocuments();
      });
  }

  uploadApplicantDocument(event: Event): void {
    let types: number[] = [this.personalIdentificationType];
    let dirtyFile = (event.target as HTMLInputElement).files[0];
    let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, ''));
    if(this.sharedService.validateFile(file))
    {
      let upload: IDocument = {
        id: 0,
        name: file.name,
        comments: "",
        extenstion: "",
        dateCreated: "",
        dateLastUpdated: "",
        createdBy: "",
        lastUpdatedBy: "",
        parentId: this.facilitatorLicense.applicants[0].id,
        types: types,
        adminOnly: false,
        deprecated: false
      }

      const formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("document", JSON.stringify(upload));
      this.facilitatorService.uploadDocument(formData, true).subscribe(
        response => this.facilitatorLicense.applicants[0].applicantDocuments.push(response),
        error => {
          (event.target as HTMLInputElement).value = '';
          console.log('error', error);
        },
        () => {
          (event.target as HTMLInputElement).value = '';
          this.validateApplicantDocuments();
        });
    }
    else
      this.toastr.error("Unsupported File Type");
  }

  downloadDocument(fileId: number, fileName: string, applicant: boolean): void {
    this.facilitatorService.downloadFile(fileId, applicant).subscribe(
      (response) => this.saveFile(fileName, response),
      (error) => console.log("error", error)
    );
  }

  saveFile(fileName: string, blob: Blob) {
    let file = URL.createObjectURL(blob);
    var fileDownload = document.createElement("a");
    fileDownload.href = file;
    fileDownload.download = fileName;
    fileDownload.click();
  }

  deleteFile(id: number, name: string, applicant: boolean) {
    this.sharedService.openConfirm("Delete " + name + "?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if (confirmed) {
          this.facilitatorService.deleteDocument(id, applicant).subscribe(
            () => {
              if (applicant) {
                this.facilitatorLicense.applicants[0].applicantDocuments = this.facilitatorLicense.applicants[0].applicantDocuments.filter(item => item.id !== id);
                this.validateApplicantDocuments();
                this.validateAdditionalDocuments();
              }
              else {
                this.facilitatorLicense.licenseDocuments = this.facilitatorLicense.licenseDocuments.filter(item => item.id !== id);
                this.validateLicenseDocuments();
              }
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  changeStep(step: StepperSelectionEvent): void {
    if (this.facilitatorLicense.id > 0 && !this.licenseLocked) {
      if (step.previouslySelectedIndex === 1) {
        this.updateApplicant();
        this.validateApplicantDocuments();
        this.validateAdditionalDocuments();
        this.applicantForm.markAllAsTouched();
        this.applicantForm.markAsDirty();
        this.validAttestation();
      }
      else if (step.previouslySelectedIndex === 2) {
        this.updateLicense();
        this.documentsForm.markAllAsTouched();
        this.documentsForm.markAsDirty();
        this.validateLicenseDocuments();
      }
      if (step.selectedIndex === 5) {
        this.applicantForm.markAsDirty();
        this.licenseForm.markAsDirty();
        this.documentsForm.markAsDirty();
        this.validateApplication();
      }
    }
    this.sharedService.userProfile.currentStep = this.facilitatorSteps[step.selectedIndex];
    this.sharedService.updateUserProfileState();
  }

  validateApplicantDocuments(): void {
    if (this.facilitatorLicense.isRenewal) {
      this.applicantDocumentsValid = true;
    } else {
      this.applicantDocumentsValid = this.facilitatorLicense.applicants[0].applicantDocuments.filter(d => !d.deprecated).find(d => d.types.includes(this.personalIdentificationType)) != null;
    }
  }

  validDeniedLicenseDocument(): boolean {
    let applicantInfo = this.applicantForm.value;
    if(applicantInfo.deniedLicense == true && this.facilitatorLicense.applicants[0].applicantDocuments.find(d => d.types.includes(this.deniedLicenseType)) == null)
      return false
    return true;
  }

  validSubjectToDisciplineDocument(): boolean {
    let applicantInfo = this.applicantForm.value;
    if(applicantInfo.subjectToDiscipline == true && this.facilitatorLicense.applicants[0].applicantDocuments.find(d => d.types.includes(this.subjectToDisciplineType)) == null)
      return false
    return true;
  }

  validSuedForDamagesDocument(): boolean {
    let applicantInfo = this.applicantForm.value;
    if(applicantInfo.suedForDamages == true && this.facilitatorLicense.applicants[0].applicantDocuments.find(d => d.types.includes(this.suedForDamagesType)) == null)
      return false
    return true;
  }

  validSettledAllegationsDocument(): boolean {
    let applicantInfo = this.applicantForm.value;
    if(applicantInfo.settledAllegations == true && this.facilitatorLicense.applicants[0].applicantDocuments.find(d => d.types.includes(this.settledAllegationsType)) == null)
      return false
    return true;
  }

  validAllegedAbuseDocument(): boolean {
    let applicantInfo = this.applicantForm.value;
    if(applicantInfo.allegedAbuse == true && this.facilitatorLicense.applicants[0].applicantDocuments.find(d => d.types.includes(this.allegedAbuseType)) == null)
      return false
    return true;
  }

  validateAdditionalDocuments(): void {
    this.additionalDocumentsValid = (this.validDeniedLicenseDocument() && this.validSubjectToDisciplineDocument() && this.validSuedForDamagesDocument() && this.validSettledAllegationsDocument() && this.validAllegedAbuseDocument());
  }

  validateLicenseDocuments(): void {
    let types: number[] = [];
    let requiredTypes: number[];
    if(!this.renewing)
      requiredTypes = [
        this.socialEquityPlanType,
        this.trainingCertificateType
      ];
    if(this.renewing)
    requiredTypes = [
      this.equityPlanEvaluationType,
    ];

    this.licenseDocumentsValid = true;
    this.facilitatorLicense.licenseDocuments.forEach(ld => ld.types.forEach(t => types.push(t)));
    requiredTypes.forEach(type => {
      if (!types.includes(type)) {
        this.missingTypes[type] = true;
        this.licenseDocumentsValid = false;
      }
    });
  }

  validateApplication(): void {
    this.validateLicenseDocuments();
    this.validateApplicantDocuments();
    this.validateAdditionalDocuments();
    this.validateDirectoryConsentWebsite();
    this.validateDocumentsCorrect();
    this.applicationValid = this.applicantForm.valid &&
      this.documentsForm.valid &&
      this.licenseDocumentsValid &&
      this.applicantDocumentsValid &&
      this.additionalDocumentsValid &&
      this.facilitatorLicense.passedExam;
  }

  submitApplication(): void {
    let licenseInfo = this.licenseForm.value;
    this.facilitatorLicense.attestation = licenseInfo.attestation;
    this.facilitatorLicense.electronicSignature = licenseInfo.electronicSignature;
    this.facilitatorLicense.directoryConsent = licenseInfo.directoryConsent;
    this.facilitatorLicense.directoryConsentEmail = licenseInfo.directoryConsentEmail;
    this.facilitatorLicense.directoryConsentPhone = licenseInfo.directoryConsentPhone;
    this.facilitatorLicense.directoryConsentWebsite = licenseInfo.directoryConsentWebsite;
    this.facilitatorLicense.directoryEmail = licenseInfo.directoryEmail;
    this.facilitatorLicense.directoryPhone = licenseInfo.directoryPhone;
    this.facilitatorLicense.directoryPreferredName = licenseInfo.directoryPreferredName;
    this.facilitatorLicense.directoryWebsite = licenseInfo.directoryWebsite;

    this.facilitatorService.submitApplication(this.facilitatorLicense, false).subscribe(
      response => this.facilitatorLicense = response,
      error => console.log('error', error),
      () => {
        this.routeSubscription.unsubscribe();
        let licenseIndex = this.facilitatorLicenses.findIndex(fl => fl.id === this.facilitatorLicense.id);
        this.facilitatorLicenses[licenseIndex] = this.facilitatorLicense;
        this.showSubmitted = true;
        this.viewForm = false;
      }
    );
  }

  continue(): void {
    this.showSubmitted = false;
    this.renewing = false;
    this.getFacilitatorLicense();
  }

  viewPrintScreen(license: IFacilitatorLicense): void {
    if (license[0] != null) {
      this.dialog.open(FacilitatorDetailsDialog, {
        data: license[0],
        maxWidth: !this.sharedService.mobile ? '900px' : '800px',
        maxHeight: this.sharedService.mobile ? '750px' : '900px',
        autoFocus: false,
        panelClass: this.sharedService.userProfile.theme === "dark" ? "theme-dark" : "",
      });
    }
    else {
      this.dialog.open(FacilitatorDetailsDialog, {
        data: license,
        maxWidth: !this.sharedService.mobile ? '900px' : '800px',
        maxHeight: this.sharedService.mobile ? '750px' : '900px',
        autoFocus: false,
        panelClass: this.sharedService.userProfile.theme === "dark" ? "theme-dark" : "",
      });
    }
  }

  changeDirectory(license: IFacilitatorLicense): void {
    if (license.id > 0) {
      const dialogRef = this.dialog.open(DialogChangeDirectoryComponent, {
        data: {license: license, type: 'Facilitator'},
        maxWidth: !this.sharedService.mobile ? '900px' : '800px',
        maxHeight: this.sharedService.mobile ? '750px' : '900px',
        autoFocus: false,
        panelClass: this.sharedService.userProfile.theme === "dark" ? "theme-dark" : "",
      });
      dialogRef.afterClosed().subscribe((response) => {
        if(response) {
        license.directoryConsent = response.directoryConsent;
        license.directoryConsentWebsite = response.directoryConsentWebsite;
        license.directoryConsentEmail = response.directoryConsentEmail;
        license.directoryConsentPhone = response.directoryConsentPhone;
        license.directoryPhone = response.directoryPhone;
        license.directoryEmail = response.directoryEmail;
        license.directoryWebsite= response.directoryWebsite;
        license.directoryPreferredName = response.directoryPreferredName;
        }

      });
    }
  }

  withdrawApplication(license: IFacilitatorLicense): void {
    this.sharedService.openConfirm("Withdraw this application?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if (confirmed) {
          this.facilitatorService.withdrawApplication(license).subscribe(
            response => license = response,
            error => console.log('error', error),
            () => {
              let licenseIndex = this.facilitatorLicenses.findIndex(fl => fl.id === license.id);
              this.facilitatorLicenses[licenseIndex] = license;
              this.getFacilitatorLicense();
            }
          );
        }
      }
    );
  }

  showRequiredApplicantDataAttestation(applicationDate: string): boolean {
    if (!applicationDate) {
      return true;
    }
    const parsedDate = new Date(applicationDate);
    const comparisonDate = new Date(2025, 0, 2);
    return parsedDate > comparisonDate;
  }

  hasRenewal(license: IFacilitatorLicense): boolean {
    if(this.facilitatorLicenses.find(l => (l.isRenewal && l.licenseId == license.licenseId && (l.status != this.sharedService.approved && l.status != this.sharedService.resubmitted && l.status != this.sharedService.incomplete && l.status != this.sharedService.insufficient
      && l.status != this.sharedService.denied && l.status != this.sharedService.revoked && l.status != this.sharedService.expired && l.status != this.sharedService.withdrawn
      && l.status != this.sharedService.surrendered && l.status != this.sharedService.closed))) != null )
      return true;
    return false;
  }

  createRenewal(license: IFacilitatorLicense): void {
    this.sharedService.openConfirm("Are you sure you want to start a renewal application?");

    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.startedRenewal = true;
          this.loadingFacilitator = true;
          this.facilitatorService.createRenewalLicense(license.id).subscribe(
          response => {
            if(response != null)
              {
                this.facilitatorLicenses.push(response);
                this.loadingFacilitator = false;
                this.viewRenewal(response);
              }
            else {
              this.toastr.error("Error creating renewal application");
              this.facilitatorService.getFacilitatorLicenses().subscribe(
                response => this.facilitatorLicenses = response,
                error => console.log('error', error),
                () => {

                  if(this.facilitatorLicenses.length === 0 || this.canApplyAgain)
                  {
                    this.showStartButton = true;
                  }
                  if (this.sharedService.userProfile.userId === null) {
                    this.sharedService.getUserInfo().subscribe(
                      response => this.sharedService.userProfile = response,
                      error => console.log('error', error),
                      () => {
                        this.loadingFacilitator = false;
                        this.getFacilitatorLicense();
                        this.sharedService.toggleTheme();
                        this.updateUserState();
                      }
                    );
                  }
                  else {
                    this.loadingFacilitator = false;
                    this.getFacilitatorLicense();
                    this.updateUserState();
                  }
                  this.GetRandomizedExamMap();
                }
              );
            }
          },
          error => {
            console.log('error', error)
          });
    }});
  }

  updateApplicantRenewal(): void {
    let applicantInfo = this.applicantForm.value;
      this.facilitatorLicense.applicants[0].ssnAttestation = applicantInfo.ssnAttestation;
      this.facilitatorLicense.applicants[0].preferredFirstName = applicantInfo.preferredFirstName;
      this.facilitatorLicense.applicants[0].preferredMiddleName = applicantInfo.preferredMiddleName;
      this.facilitatorLicense.applicants[0].preferredLastName = applicantInfo.preferredLastName;
      this.facilitatorLicense.applicants[0].physicalState = 'OR';
      this.facilitatorLicense.applicants[0].physicalCity = applicantInfo.physicalCity;
      this.facilitatorLicense.applicants[0].physicalStreet = applicantInfo.physicalStreet;
      this.facilitatorLicense.applicants[0].physicalZip = applicantInfo.physicalZip;
      this.facilitatorLicense.applicants[0].physicalCounty = applicantInfo.physicalCounty;
      this.facilitatorLicense.applicants[0].oregonResidency = applicantInfo.oregonResidency;
      this.facilitatorLicense.applicants[0].highSchoolDiploma = applicantInfo.highSchoolDiploma;
      this.facilitatorLicense.applicants[0].mailingIsPhysical = applicantInfo.mailingIsPhysical;
      this.facilitatorLicense.applicants[0].mailingState = applicantInfo.mailingState;
      this.facilitatorLicense.applicants[0].mailingCity = applicantInfo.mailingCity;
      this.facilitatorLicense.applicants[0].mailingStreet = applicantInfo.mailingStreet;
      this.facilitatorLicense.applicants[0].mailingZip = applicantInfo.mailingZip;
      this.facilitatorLicense.applicants[0].mailingCounty = applicantInfo.mailingCounty;
      this.facilitatorLicense.applicants[0].phone = applicantInfo.phone;
      this.facilitatorLicense.applicants[0].alternatePhone = applicantInfo.alternatePhone;
      this.facilitatorLicense.applicants[0].email = applicantInfo.email;
      this.facilitatorLicense.applicants[0].previousLicense = applicantInfo.previousLicense;
      this.facilitatorLicense.applicants[0].affiliatedLicenses = applicantInfo.affiliatedLicenses;
      this.facilitatorLicense.applicants[0].changeInConvictionHistory = applicantInfo.changeInConvictionHistory;
      this.facilitatorLicense.applicants[0].applicantPageCorrect = applicantInfo.applicantPageCorrect;
      this.facilitatorLicense.applicants[0].applicantPageCorrectReason = applicantInfo.applicantPageCorrectReason;
      this.facilitatorLicense.applicants[0].licensesHeld = applicantInfo.licensesHeld;
      this.facilitatorLicense.applicants[0].heldLicense = applicantInfo.heldLicense;
      this.facilitatorLicense.applicants[0].deniedLicense = applicantInfo.deniedLicense;
      this.facilitatorLicense.applicants[0].subjectToDiscipline = applicantInfo.subjectToDiscipline;
      this.facilitatorLicense.applicants[0].suedForDamages = applicantInfo.suedForDamages;
      this.facilitatorLicense.applicants[0].settledAllegations = applicantInfo.settledAllegations;
      this.facilitatorLicense.applicants[0].allegedAbuse = applicantInfo.allegedAbuse;
      this.facilitatorLicense.applicants[0].substantiatedAllegation = applicantInfo.substantiatedAllegation;


    if (applicantInfo.mailingIsPhysical) {
      this.facilitatorLicense.applicants[0].mailingState = 'OR';
      this.facilitatorLicense.applicants[0].mailingCity = applicantInfo.physicalCity;
      this.facilitatorLicense.applicants[0].mailingStreet = applicantInfo.physicalStreet;
      this.facilitatorLicense.applicants[0].mailingZip = applicantInfo.physicalZip;
      this.facilitatorLicense.applicants[0].mailingCounty = applicantInfo.physicalCounty;

      this.applicantForm.patchValue({
        mailingState: 'OR',
        mailingCity: applicantInfo.physicalCity,
        mailingStreet: applicantInfo.physicalStreet,
        mailingZip: applicantInfo.physicalZip,
        mailingCounty: applicantInfo.physicalCounty,
      });
    }
    this.facilitatorService.updateApplicant(this.facilitatorLicense.applicants[0]);
  }


  viewRenewal(license: IFacilitatorLicense): void
  {
    let expirationDate = new Date(license.applicants[0].backgroundCheckDate);
    expirationDate.setFullYear(expirationDate.getFullYear() + 5);

    let currentDatePlusOneYear = new Date();
    currentDatePlusOneYear.setFullYear(currentDatePlusOneYear.getFullYear() + 1);

    this.backgroundExpiring = expirationDate < currentDatePlusOneYear;

    this.showEditLoading = true;
    this.facilitatorLicense = license;
    this.licenseLocked = this.facilitatorLicense.status !== this.sharedService.pendingRenewal;
    if (this.licenseLocked) {
      this.step = 0;
      this.applicantForm.disable();
      this.documentsForm.disable();
      this.licenseForm.disable();
    }
    else{
      this.applicantForm.enable();
      this.documentsForm.enable();
      this.licenseForm.enable();
      this.applicantForm.markAllAsTouched();
      this.documentsForm.markAllAsTouched();
      this.licenseForm.markAllAsTouched();
      this.setupRouteSubscription();
    }
    this.updateApplicantForm();
    this.updateLicenseForm();
    this.applicantForm.controls['changeInConvictionHistory'].setValidators([Validators.required]);
    this.applicantForm.controls['changeInConvictionHistory'].updateValueAndValidity();
    this.applicantForm.controls['applicantPageCorrect'].setValidators([Validators.required]);
    this.applicantForm.controls['applicantPageCorrect'].updateValueAndValidity();
    this.documentsForm.controls['documentPageCorrect'].setValidators([Validators.required]);
    this.documentsForm.controls['documentPageCorrect'].updateValueAndValidity();

    this.applicantForm.controls['legalFirstName'].disable();
    this.applicantForm.controls['legalLastName'].disable();
    this.applicantForm.controls['legalMiddleName'].disable();
    this.applicantForm.controls['alternateNames'].disable();
    this.applicantForm.controls['ssn'].disable();
    this.applicantForm.controls['dob'].disable();
    this.documentsForm.controls['trainingProgram'].disable();
    this.documentsForm.controls['trainingDate'].disable();
    this.applicantForm.controls['language'].disable();

    this.renewing = true;
    this.renewalStep = 0
    setTimeout(() => this.showEditLoading = false, 500);
  }

  changeRenewalStep(step: StepperSelectionEvent): void {
    if (this.facilitatorLicense.id > 0 && !this.licenseLocked) {
      if (step.previouslySelectedIndex === 1) {
        this.updateApplicantRenewal();
        this.validateApplicantDocuments();
        this.applicantForm.markAllAsTouched();
        this.applicantForm.markAsDirty();
        this.validAttestation();
        this.validateApplicantPageCorrect();
      }
      else if (step.previouslySelectedIndex === 2) {
        this.updateLicense();
        this.documentsForm.markAllAsTouched();
        this.documentsForm.markAsDirty();
        this.validateLicenseDocuments();
        this.validateDocumentsCorrect();
      }
      if (step.selectedIndex === 4) {
        this.applicantForm.markAsDirty();
        this.licenseForm.markAsDirty();
        this.documentsForm.markAsDirty();
        this.validateApplication();
        this.validateApplicantPageCorrect();
      }
    }
    this.sharedService.userProfile.currentStep = this.facilitatorSteps[step.selectedIndex];
    this.sharedService.updateUserProfileState();
  }

  submitRenewal(): void {
    let licenseInfo = this.licenseForm.value;
    this.facilitatorLicense.attestation = licenseInfo.attestation;
    this.facilitatorLicense.electronicSignature = licenseInfo.electronicSignature;
    this.facilitatorLicense.directoryConsent = licenseInfo.directoryConsent;
    this.facilitatorLicense.directoryConsentEmail = licenseInfo.directoryConsentEmail;
    this.facilitatorLicense.directoryConsentPhone = licenseInfo.directoryConsentPhone;
    this.facilitatorLicense.directoryConsentWebsite = licenseInfo.directoryConsentWebsite;
    this.facilitatorLicense.directoryEmail = licenseInfo.directoryEmail;
    this.facilitatorLicense.directoryPhone = licenseInfo.directoryPhone;
    this.facilitatorLicense.directoryPreferredName = licenseInfo.directoryPreferredName;
    this.facilitatorLicense.directoryWebsite = licenseInfo.directoryWebsite;

    this.facilitatorService.submitApplication(this.facilitatorLicense,true).subscribe(
      response => this.facilitatorLicense = response,
      error => console.log('error', error),
      () => {
        this.routeSubscription.unsubscribe();
        let licenseIndex = this.facilitatorLicenses.findIndex(fl => fl.id === this.facilitatorLicense.id);
        this.facilitatorLicenses[licenseIndex] = this.facilitatorLicense;
        this.showSubmitted = true;
        this.viewForm = false;
      }
    );
  }

  ngOnDestroy(): void {
    setTimeout(() => {this.routeSubscription.unsubscribe()}, 250);
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: './dialog-exam-results.html'
})
export class ExamModalComponent {
  constructor(public dialogRef: MatDialogRef<ExamModalComponent>,
    @Inject(MAT_DIALOG_DATA) public exam: IExamAttempt) { }

  close(): void {
    this.dialogRef.close();
  }

}
@Component({
  selector: "facilitator-details-dialog",
  templateUrl: "./facilitator-details.html",
  styleUrls: ["../../license-dashboard/license-dashboard.component.scss"]
})

export class FacilitatorDetailsDialog {
  public url = environment.baseUrl;

  constructor(public dialogRef: MatDialogRef<FacilitatorDetailsDialog>,
    public sharedService: SharedService,
    public paymentService: PaymentService,
    @Inject(MAT_DIALOG_DATA) public license: IFacilitatorLicense) { }

    print() {
      window.print();
    }

  cancel(): void {
    this.dialogRef.close('cancel');
  }
}
